import { logToCloudWatch } from "../../../../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../../../../constants/awsLogs.constants";
import { BASE_URL } from "./../../../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "./../../../../../../constants/keyConstants";

const getInitAction = async({state, setState})=>{
    try{

        const {headerProps} = state;

        const {date=""} = headerProps;

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const response = await fetch(`${BASE_URL}/dashboard/glucoseLogs/range`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                start_date: date,
                end_date : date
            })
        });

        const mealLogsResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/${pathname?.[2]}/${date}/${date}`, {
            method: "GET",
            headers: {
              "Authorization": "Bearer " + window.localStorage.getItem("Authorization")
            }
        });

        const mealLogs= await mealLogsResponse.json();

        const data = await response.json();

        setState((prev)=>{
            return {
                ...prev,
                glucoseData : data?.['glucose_logs'],
                mealLogs,
            }
        })

    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export default {
    INIT_ACTION : getInitAction
}