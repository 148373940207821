import { logToCloudWatch } from "../../configs/awsLogs";
import { API_STATUS_CODES, BASE_URL } from "../../constants/api";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";
import { LOCAL_STORAGE_KEYS } from "../../constants/keyConstants";
import { LOGIN_ENUMS } from "./login.constants";

const getInitAction = async({state, setState, payload}) =>{
    try{
        const urlParam = new URLSearchParams(window.location.search);

        if(urlParam?.has("token"))
        {
            const response = await fetch(`${BASE_URL}/api/dashboard/users/validateEmailVerificationToken?token=${urlParam.get("token")}`,{
                method : "POST",
            });
    
            if(response?.status === API_STATUS_CODES.SUCCES)
            {
                window?.localStorage?.setItem("Authentication",response.headers.get("authentication"));
                setState((prev)=>{
                    return {
                        ...prev,
                        verification : "success"
                    }
                   });
            }
            else{
                setState((prev)=>{
                    return {
                        ...prev,
                        verification : "failed"
                    }
                   });
            }
        }
    }catch(e)
    {
        setState((prevState)=>{
            return {...prevState,error:e}
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getFormSubmitAction = async({state, setState,payload})=>{
    try{
        console.log(payload);
        setState((prev)=>({...prev, isLoading : true, displayToast : false }));
        const {initialPageState  = 1} = state;
        const request = await fetch(`${BASE_URL}/api/dashboard/users/login`,{
            method: "POST",
            headers : {
                "content-type" : "application/json",
            },
            body : JSON.stringify({
                email : payload?.Email?.toLowerCase(),
                password : payload?.Password,
                trust_device : payload?.trustDevice || false
            })
        });

        switch(request.status)
        {
            case API_STATUS_CODES.ANAUTHORIZED:
                setState((prev)=>{
                    return {
                        ...prev,
                        displayToast : true,
                        isLoading:false,
                        toastProps : {
                            message : LOGIN_ENUMS.TOAST.BODY_TEXT.ANAUTHORIZED,
                            type : "failed",                                
                        },
                }
                })
                break;
            default:
                    const data = await request.json();
                    window?.localStorage.setItem(LOCAL_STORAGE_KEYS.AUTHORIZATION, request.headers.get("authorization"));
                    window?.localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ROLE, data?.['userRole']);

                    if(data && data?.id)
                    {
                    await fetch(`${BASE_URL}/api/dashboard/users/sendLoginCode`,{
                            method: "POST",
                            headers : {
                                "content-type" : "application/json",
                                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
                            },
                            body : JSON.stringify({
                                id : data?.id
                            })
                        })
                    }            
                    setState((prev)=>{
                        return {...prev, 
                            ...data,
                            id:data?.id,
                            email : payload?.Email,
                            initialPageState: initialPageState+1,
                            isLoading : false,
                            displayToast: true,
                            toastProps : {
                                message : LOGIN_ENUMS.TOAST.BODY_TEXT.EMAIL_SENT,
                                type : "success",                                
                            },
                        }
                    })
                    break;
        }
    }
    catch(e)
    {
        console.log("error is ",e);
        setState((prev)=>{
            return {...prev, error: e, isLoading: false}
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getSecondarySubmitAction = async({state, setState, payload, navigate}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                displayToast : false,
                verification: undefined,
                isLoading : true
            }
        })
        const {stage} = state;
        const { id } = state;
        if(stage == "EMAIL_VERIFICATION_PENDING")
        {
            await fetch(`${BASE_URL}/api/dashboard/users/resendEmailVerificationToken?userId=${id}`,{
                method : "POST",
                headers : {
                    "content-type" : "application/json",
                },
            });
            setState((prev)=>{
                return {...prev,
                isLoading:false}
            })
        }
        else{
            const request = await fetch(`${BASE_URL}/api/dashboard/users/checkLoginCode`,{
                method : "POST",
                headers : {
                    "content-type" : "application/json",
                    'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
                },
                body : JSON.stringify({ id , code : payload})
            });

            switch(request.status)
            {
                case API_STATUS_CODES.SUCCES : 
                        const body = await request.json();

                        const dataResponse = await fetch(`${BASE_URL}/api/dashboard/users/getCareProAccount`,{
                            method: "GET",
                            headers:{
                                "content-type" : "application/json",
                                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
                            },
                        });
                
                        const dataResp = await dataResponse.json();

                        Object.keys(dataResp).forEach((key)=>{
                            window?.localStorage?.setItem(key,dataResp[key]);
                        })
                
                        setState((prev)=>{
                            return {...prev, ...body, isLoading : false}
                        });

                        if(window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.USER_ROLE)=="SUPER_ADMIN"){
                            navigate("/superUser");
                        }
                        
                        if(stage == 'PHONE_NUMBER_VERIFIED'){
                            if(window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.USER_ROLE)=="SUPER_ADMIN"){
                                navigate("/superUser");
                            }
                            else{
                                navigate("/");
                            }
                        }
                        else{
                            navigate("/createAccount");
                        }
                        break;
                case API_STATUS_CODES.ANAUTHORIZED:
                    setState((prev)=>{
                        return {
                            ...prev,
                            displayToast : true,
                            toastProps : {
                                message : LOGIN_ENUMS.TOAST.BODY_TEXT.EXPIRED_CODE,
                                type : "failed",
                            },
                            isLoading : false,
                        }
                    });
                    break;
                case API_STATUS_CODES.BAD_REQUEST:
                    setState((prev)=>{
                        return {
                            ...prev,
                            displayToast : true,
                            toastProps : {
                                message : LOGIN_ENUMS.TOAST.BODY_TEXT.WRONG_CODE,
                                type : "failed",                                
                            },
                            isLoading : false,
                        }
                    });
                    break;
                default: 
                break;
            }

        }
    }
    catch(e)
    {
        setState((prev)=>{
            return {...prev, error: e, isLoading : false}
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getResendInviteAction = async({setState, state})=>{
    try{
        setState((prev)=>({...prev, isLoading : true}));
        const {id} = state;
        await fetch(`${BASE_URL}/api/dashboard/users/sendLoginCode`,{
            method: "POST",
            headers : {
                "content-type" : "application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
            },
            body : JSON.stringify({
                id 
            })
        })
           
        setState((prev)=>{
            return {...prev, 
                isLoading : false,
            }
        })
    }
    catch(e)
    {
        setState((prev)=>({
            ...prev,
            error:e,
            isLoading : false
        }));
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export default {
    PRIMARY_FORM_SUBMIT : getFormSubmitAction,
    SECONDARY_FORM_SUBMIT : getSecondarySubmitAction,
    INIT_ACTION : getInitAction,
    RESEND_INVITE : getResendInviteAction
}