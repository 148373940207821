import moment from "moment";

import { BASE_URL } from "../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants";
import { GLUCOSE_METRICS_TYPES } from "../../../../constants/metricTypes";
import { getWeeklyFormattedWeeklyDates } from "../../../../helpers/dateFormatter";
import { getDateInWeeklyFormatFromStartDate } from "../progressMonitoring/progressMonitoring.helper";
import { getTimeInRangeFormatted } from "./overviewTab.helpers";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";
import { logToCloudWatch } from "../../../../configs/awsLogs";

const getUpdatedFlowBillingAction = async({
        state,
        setState
    })=>{
    const {handleToggleLoaderAction = ()=>{}, handleBaseStateUpdate=()=>{}} = state || {};
    try{
        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");

        const billingRequest = await fetch(`${BASE_URL}/api/dashboard/careProUserNotes/bill/${pathname?.[2]}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const codeBasedBilling = await billingRequest?.json();
        handleBaseStateUpdate({notesBasedBilling : codeBasedBilling});

        setState((prev)=>{
            return {
                ...prev,
                codeBasedBilling
            }
        })
        handleToggleLoaderAction({isLoading :false});
    }
    catch(e){
        handleToggleLoaderAction({isLoading :false});
        console.log('ERROR : ',e);
    }
}

const getBillingAction = async({state, setState})=>{
    try{
        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");

        await getUpdatedFlowBillingAction({state, setState})

        const billingRequest = await fetch(`${BASE_URL}/api/dashboard/users/getBills`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body:JSON.stringify({
                userId : pathname?.[2]
            })
        });

        const billingList = await billingRequest?.json();

        setState((prev)=>{
            return {
                ...prev,
                billingList : billingList?.billData,
            }
        })
    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            api: '/api/dashboard/users/getBills',
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        console.log(e);
    }
}

const getInitAction = async({state, setState}) =>{
    const {handleToggleLoaderAction = ()=>{}, handleInitAction} = state || {};

    try{

        handleToggleLoaderAction({isLoading :true});
        await handleInitAction();
        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");

        const {startDate, endDate} = getWeeklyFormattedWeeklyDates({prev:0,format:"YYYY-MM-DD"});

        getBillingAction({state, setState});

        const balancePlateResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${pathname?.[2]}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const microNutritionalValues = await balancePlateResponse?.json();

        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                metricRequests: [
                        {
                            metricType:"FASTING_TIME_IN_RANGE",
                            numberOfDays: 7,
                        },
                        {
                            metricType: GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                            numberOfDays : 7,
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType:GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                            numberOfDays: 7,
                            mealType :"LUNCH"
                        },
                        {
                            metricType:GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                            numberOfDays: 7,
                            mealType :"DINNER"
                        },
                        {
                            metricType:"AVG_FASTING_GLUCOSE",
                            numberOfDays: 7,
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            numberOfDays : 7,
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            numberOfDays: 7,
                            mealType :"LUNCH"
                        },
                        {
                            metricType:"AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            numberOfDays: 7,
                            mealType :"DINNER"
                        }
                    ],
                compareMetricRequests : [
                    ...getDateInWeeklyFormatFromStartDate({startDate : moment().subtract(13,'d').format('MM-DD-YYYY'),metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "DINNER"
                    }]}),
                ]
            })
        });

        const data = await response.json();

        const balencePlateResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${pathname?.[2]}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const balancePlateValues = await balencePlateResponse?.json();

        setState((prev)=>{
            return {
                ...prev,
                ...microNutritionalValues,
                balancePlateValues,
                bloodGlucoseMetrics: getTimeInRangeFormatted(data.metricsResponses.slice(0,4),data.metricsResponses.slice(4,8)),
                bloodGlucoseComparison : data?.timeSegmentMap || {},
            }
        })
        handleToggleLoaderAction({isLoading :false});
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e,
            }
        });
        handleToggleLoaderAction({isLoading : false});
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getDrawerDisplayAction = ({
        setState,
        payload
    })=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                displayDrawer : payload ?? !prev?.displayDrawer
            }
        })
    }
    catch(e){
        console.log('ERROR IS : ',e)
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }            
        })
    }
}

export default {
    INIT_ACTION : getInitAction,
    GET_BILLING : getBillingAction,
    HANDLE_DRAWER_DISPLAY_ACTION : getDrawerDisplayAction,
}