import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import drillDown from "highcharts/modules/drilldown";
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import { CHART_NO_DATA } from "../../constants/noData";

import "./activityAnalysis.module.css";
import { hasChartData } from "../../helpers/chart";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
noDataToDisplay(Highcharts);
drillDown(Highcharts);

// Disable exporting module
Highcharts.setOptions({
  exporting: {
    enabled: false
  }
});

const ActivityAnalysis = ({columns, minAndMaxRange,activityData, timeData}) =>{
    
    drillDown(Highcharts);

    const normalPlotBands =[{
      from: 63,
      to: 140,
      color: '#84EBC514',
      label: {
          text: '',
          style: {
              color: '#84EBC514'
          }
      }
    }];

    const normalPlotLines = [{
        value: 63,
        color: '#9EDBC5',
        dashStyle: 'longdash',
        width: 1,
      },
      {
        value: 140,
        color: '#9EDBC5',
        dashStyle: 'longdash',
        width: 1,
      }];

    const plotBands = minAndMaxRange.length>0 && hasChartData(minAndMaxRange) ? normalPlotBands : [];
    const plotLines = minAndMaxRange.length>0 && hasChartData(minAndMaxRange) ? normalPlotLines : [];

    const options = {
        chart: {
          type: 'areasplinerange',
          plotBackgroundColor: null,
          plotBorderWidth: 1,
          plotBorderColor: "#EAE7EC",
          plotShadow: false,
          events: {
            load: function() {
              if (!hasChartData(this.series[0].data) && this.showNoData) {
                  this.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
              }
            },
            update : function(){
              if (!hasChartData(this.series[0].data) && this.showNoData) {
                this.showNoData("<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>");
              }
            }
          }
        },
        title: {
          text: null,
        },
        yAxis:{
          lineColor: "#EAE7EC",
          title: {
            text: null 
          },
          labels: {
            useHTML:true,
            formatter: function () {
                if(this.value==63 || this.value==140){
                  return '<span style="color: #7ACCAE; font-weight: bold;">' + this.value + '</span>';
                }
                return '<span style="color: black">' + this.value + '</span>';                ;
            }
          },
          tickPositions: [0, 63, 100, 140, 200, 300],
          min: 0,
          max: 300,
          gridLineWidth: 0,
          alternateGridColor: null,
          plotBands, 
          plotLines
        },
        xAxis:{
          categories: columns,
          title: {
            text: null
          },
          labels: {
            step:1,
            rotation:0
          },
          lineColor: "#EAE7EC",
        },
        plotOptions: {
            areasplinerange: {
                marker: {
                    enabled: false
                },
            },
        },
        tooltip: {
          backgroundColor: '#2A292D',
          width:"243px",
          style: {
              width: 400,
              display: "flex",
              minWidth:"243",
              padding: "12px",
              flexDirection: "column",
              gap: "12px",
              color: 'white',
              width: "243px"
          },
          formatter: function(data) {
              if (this.point === null) {
                return 'No data available';
              }
              return '<div style="min-width:243px; padding:12px; border-radius:8px; text-align:center;">'+
                "<div style='min-width: 205px' > Avg. Glucose Level : "+ (activityData?.[this.point.index]?.[0]+" mg/dL" || "No Data")  +"</div><br />" +
                "<div style='min-width: 205px'> Range : "+ (minAndMaxRange?.[this.point.index]?.[1]+" - "+minAndMaxRange?.[this.point.index]?.[0]+" mg/dL" || "No Data") +"</div><br />" +
                ' </div>';
            },
        },
        series: [{
          name : "Glucose",
          data: hasChartData(minAndMaxRange) ? minAndMaxRange.map(point => ({
            low: point[1],
            high: point[0]
          })) : [],
          color :'#F1EFF3',
          showInLegend: false,
          states: {
            hover: {
                enabled: false
            }
          }
        },
        {
          name: 'Glucose',
          data: hasChartData(activityData) ? [...activityData] :[],
          zones: [{
            value: 63,
            color: '#EE9887'
        }, {
            value: 140,
            color: '#7ACCAE'
        },{
            value: 300,
            color: '#E4A944'
        }],
        lineWidth:3,
        showInLegend: false,
        states: {
          hover: {
              enabled: false
          }
        }
      }],
      lang: {
        noData:"<p style='text-align: center; font-weight:500; font-family: Founders Grotesk;'><b>"+ CHART_NO_DATA.PRIMARY +"<b><br><p style='font-weight:50; font-size:12px;font-family: Founders Grotesk;'>"+ CHART_NO_DATA.SECONDARY +"</p></p>",
      },
      noData: {
        useHTML: true,
        style: {
          color: '#66636D',
          minWidth:"250px",
          display:"flex",
          justifyContent:"center"
        },
      },  
      credits:{
        enabled : false,
      },
      exporting: {
        enabled: false
      },
};

    return (
        <div className={"container"} style={{minWidth : "100%"}}>
            <HighchartsReact Highcharts={Highcharts} options={options}/>
        </div>
    )

}

export default ActivityAnalysis