const BODY_ENUMS = {
    SECONDARY_HEADER : {
        SCROLL_CONTENT : "GA Week 8",
    }
};

const ROW_DATA_STREAMS = [
    {
        PRIMARY : "Blood Glucose",
        SECONDARY : "Overall Time-in-Range",
        Tertiary : "2 trends",
    },
    {
        PRIMARY : "Balanced Plate",
        SECONDARY : "Coming soon",
        Tertiary : "2 trends",
    },
    {
        PRIMARY : "Medication Adherence",
        SECONDARY : "Coming soon",
        Tertiary : "2 trends",
    }
]

export {
    BODY_ENUMS,
    ROW_DATA_STREAMS
}