import styles from "./summaryTable.module.css";

const TABLE_COLUMNS = [{
        label : "Encounter ID",
        name : "encounterId",
        className : styles.columnContainerClass,
        innerClassName : styles.innerClassName
    },
    {
        name : "cptCode",
        label : "CPT Code",
        className : styles.columnContainerClass,
        innerClassName : styles.innerClassName
    },
    {
        name : "utilization",
        label : "Utilization",
        className : styles.columnContainerClass,
        innerClassName : styles.innerClassName
    },
    {
        name : "criteriaMetOn",
        label : "Criteria Met On",
        className : styles.columnContainerClass,
        innerClassName : styles.innerClassName
    }
];

export {
    TABLE_COLUMNS
}