const TABLE_COLUMNS = {
    CPT_CODE : "CPT CODE",
    STATUS : "STATUS",
    CRITERIA_MET_ON : "CRITERIA MET ON",
    ENCOUNTER_ID : "ENCOUNTER ID",
    APPROVED : "APPROVED"
}

export {
    TABLE_COLUMNS
}