import React, { useState, useRef } from "react";

import styles from "./loginPinCardComponent.module.css";
import { PIN_CODE_ARRAY } from "../loginPinCard.constants";
import ErrorComponent from "../../../atoms/errorComponent/ErrorComponent";
import useFocus from "../../../hooks/useFocus";

const CardBody = ({setEnableButton, hasError = false, handleValueChange = ()=>{}}) =>{

    const [currentValue,setCurrentValue] = useState(new Array(6));

    const [currentCheckBox,setCurrentCheckBox] = useState(false);

    const [currentPointer,setCurrentPointer] = useState(0);

    const [inputFocus,setInputFocus] = useState([useFocus(),useFocus(),useFocus(),useFocus(),useFocus(),useFocus()])

    const handleFieldChange =(val) => (event) =>{
        event.preventDefault();

        if(event?.target?.value == "" || event?.target?.value == undefined)
        {
            setCurrentValue((prev)=>{
                prev[val] = '';
                let min = prev.filter((val)=>{
                    if(val>=0 && val<10)
                    {
                        return true;
                    }
                })
                if(min.length <= 5)
                {
                    setEnableButton(false);
                }
                handleValueChange(prev)
                return prev;
            });
            if(val>0)
            {
                inputFocus[val-1][1]();
            }    
            setCurrentPointer((prevPointer)=>prevPointer-1);    
        }
        else{
             if(event?.target?.value?.length >1)
             {
                if(event?.target?.value?.length==6){
                    setCurrentValue((prev)=>{
                        let min = (new Array(6).fill(1)).map((val,index)=>{
                            return event.target.value[index];
                        })
                        if(min.length > 5)
                        {
                            setEnableButton(true);
                        }
                        handleValueChange(min)
                        return min;
                    });    
                }
                else{
                    setCurrentValue((prev)=>{
                        prev[val]=event.target.value[event.target.value.length-1];
                        let min = prev.filter((val)=>{
                            if(val>=0 && val<10)
                            {
                                return true;
                            }
                        })
                        if(min.length > 5)
                        {
                            setEnableButton(true);
                        }
                        handleValueChange(prev)
                        return prev;
                    });    
                }
                setCurrentPointer(currentPointer+1);
                if(val<5)
                {
                    inputFocus[val+1][1]();
                }
             }
             else{
                setCurrentValue((prev)=>{
                    if(event?.target?.value /10 <1)
                      prev[val] = event?.target?.value;
                    let min = prev.filter((val)=>{
                        if(val>=0 && val<10)
                        {
                            return true;
                        }
                    })
                    if(min.length > 5)
                    {
                        setEnableButton(true);
                    }
                    handleValueChange(prev)
                    return prev
                });
                setCurrentPointer(currentPointer+1);
                if(val<5)
                {
                    inputFocus[val+1][1]();
                }
             }
        }
    }

    const handleKeyPress = () =>{}

    const handleCheckBoxChange = ()=>{
        setCurrentCheckBox(!currentCheckBox)
    }

    return (
        <div className={styles.bodyContainer}>
            <div className={styles.pinContainer}>
                <div className={styles.pinCodeMiniContainer}>
                    {PIN_CODE_ARRAY.slice(0,3).map((val)=>{
                        return (
                            <input type="number" ref={inputFocus[val][0]} value={currentValue[val]} className={styles.inputField} id={val} onChange={handleFieldChange(val)} onKeyDown={handleKeyPress} />
                        )
                    })}
                </div>
                <div className={styles.pinCodeMiniContainer}>
                    {PIN_CODE_ARRAY.slice(3,6).map((val)=>{
                        return (
                            <input type="number"  ref={inputFocus[val][0]} className={styles.inputField} value={currentValue[val]} id={val} onChange={handleFieldChange(val)} onKeyDown={handleKeyPress} />
                        )
                    })}
                </div>
            </div>
            {hasError && <ErrorComponent fieldError={{message : "Verification code in incorrect. Please try again"}}/>}
        </div>
    )
}

export default CardBody;