import {map} from "lodash";

import ListComponent from "../../../BillingDrawer/components/Body/components/ListComponent";
import { BILLING_TAB_CONTENT, BILLING_TAB_LABELS, BILLING_TAB_NAMES, BILLING_TIME_MANAGEMENT } from "./billingItem.constants";
import { getDropdownContent, getDropdownLabel, getMarkUpIconContent } from "../../billingList.component";

import styles from "./billingItem.module.css"

const getKeyValueMapper = ({label="", name=""})=>{
    return {
        label : getDropdownLabel(label),
        content : getDropdownContent(name)
    }
}

const getComplianceCheckContent = ({
        index,
        utilized,
        pendingMins,
        date
    })=>{
    const label = BILLING_TIME_MANAGEMENT[Math.min(index,1)];
    const content = utilized ? "utilized" : (!date ? "Not Utilized" : `${pendingMins} Min Pending`)
    return {
        label : getDropdownLabel(label,utilized),
        content : getMarkUpIconContent(content,utilized && styles.utilizedContent),
        contentClass : styles.checkComplianceListContent,
    }
}

const getBillingBodyContent = (key,data) =>{
    switch(key){
        case "OVERVIEW":
            return {
                BodyComponent : ListComponent,
                listItems : map(BILLING_TAB_CONTENT.OVERVIEW,(listItem)=> getKeyValueMapper({...listItem,name : data[listItem?.name] || ""}))
            }
        case "COMPLIANCE_CHECHLIST":
            return {
                BodyComponent : ListComponent,
                listItems : map(data?.complianceCheck,(listItem,index)=> getComplianceCheckContent({...listItem,index})),
            }
    }
}

const getDrawerTabs = (data)=>{
    let tabs = Object.keys(BILLING_TAB_LABELS).map((key)=>{
        return {
            label : BILLING_TAB_LABELS[key],
            name : BILLING_TAB_NAMES[key],
            type : key,
            ...getBillingBodyContent(key,data)
        };
    })

    return tabs
}

export {
    getDrawerTabs
}