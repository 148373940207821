
import medications from "./../../../../../../../../../assets/images/purple_colored_medication.svg";
import glucose from "./../../../../../../../../../assets/images/glucose.svg";
import foodLog from "./../../../../../../../../../assets/images/food_log.svg";

import { ROW_DATA_STREAMS } from "./body.constants";
import DataStreams from "./components/dataStreams/DataStreams"

const getFormattedRowData = ({primaryRow = [],secondaryRow = [], tertiaryRow = []}) =>{
    return [
            {
                "dataStreams" : <DataStreams icon={glucose} primaryText={ROW_DATA_STREAMS[0].PRIMARY} secondaryText={ROW_DATA_STREAMS[0].SECONDARY} tertiaryText={ROW_DATA_STREAMS[0].Tertiary} />,
                ...primaryRow
            },
            // {
            //     "dataStreams" : <DataStreams icon={foodLog} primaryText={ROW_DATA_STREAMS[1].PRIMARY} secondaryText={ROW_DATA_STREAMS[1].SECONDARY} tertiaryText={ROW_DATA_STREAMS[1].Tertiary} />,
            //     ...secondaryRow
            // },
            // {
            //     "dataStreams" : <DataStreams icon={medications} primaryText={ROW_DATA_STREAMS[2].PRIMARY} secondaryText={ROW_DATA_STREAMS[2].SECONDARY} tertiaryText={ROW_DATA_STREAMS[2].Tertiary} />,
            //     ...tertiaryRow
            // }
    ]
}

export {
    getFormattedRowData,
}