import React from "react";

import styles from "./fallback.module.css"

const Fallback = () =>{
    return (
        <div className={styles.container}>
            <div className={styles.primary}>No Data</div>
            <div className={styles.secondary}>User hasn’t connected or logged to any device</div>
        </div>
    )
}

export default Fallback;