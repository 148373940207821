import React from "react";

import { getTotalTime } from "./contentLabel.helpers";
import styles from "./contentLabel.module.css";

const ContentLabel = ({
        label,
        data
    })=>{

    return (
        <div className={styles.container}>
            <span className={styles.content}>{label}</span>
            <span className={styles.content}>{`Total Time : ${getTotalTime(data)} Min`}</span>
        </div>
    )
}

export default ContentLabel