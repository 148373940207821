import {map} from "lodash";

import ListComponent from "../../../BillingDrawer/components/Body/components/ListComponent";
import { BILLING_TAB_CONTENT, BILLING_TAB_LABELS, BILLING_TAB_NAMES, BILLING_TAB_NAME_CONTENT, BILLING_TIME_MANAGEMENT } from "./codeBasedBilling.constants";
import { getBilledOnStatus, getDropdownContent, getDropdownLabel, getMarkUpIconContent } from "../../billingList.component";

import AutoGeneratedBill from "../AutoGeneratedBill/AutoGeneratedBill";
import ComplianceDetailsTable from "../ComplianceDetailsTable/ComplianceDetailsTable";

import styles from "./codeBasedBilling.module.css"
import OuterBorderedButton from "../../../../../../../../../../atoms/buttons/OuterBorderedButton";

const getKeyValueMapper = ({label="", name="",labelSuffix="", contentSuffix="", icon=""})=>{
    let billingLabelFunction = getDropdownLabel, billingContentFunction=getDropdownContent;
    switch(label){
        case "Billed":
            billingContentFunction=getBilledOnStatus;
            break;
    }
    return {
        label : billingLabelFunction(label, _ , labelSuffix,icon),
        content : billingContentFunction(name,contentSuffix),
        
    }
}

const getComplianceCheckContent = ({
        index,
        utilized,
        pendingMins,
        date=""
    })=>{
    const label = BILLING_TIME_MANAGEMENT[Math.min(index,1)];
    const content = utilized ? "utilized" : (!date ? "Not Utilized" : `${pendingMins} Min Pending`)
    return {
        label : getDropdownLabel(label,utilized, date),
        content : getMarkUpIconContent(content,`${styles.markUpContainer} ${utilized && styles.utilizedContent}`),
        contentClass : `${styles.billingDrawerListContent} ${styles.checkComplianceListContent} `,
        labelClass : styles.billingDrawerListContent,
    }
}

const getComplianceCheckListItems = ({
        complianceCheck
    })=>{
    
    let listItems = map(complianceCheck,(listItem,index)=> getComplianceCheckContent({...listItem,index}));
    listItems.push({
        label : getDropdownLabel("Approved & Added to EHR by Provider", true),
        content : getMarkUpIconContent("Approved", `${styles.markUpContainer} ${styles.utilizedContent}`),
        contentClass : `${styles.billingDrawerListContent} ${styles.checkComplianceListContent} `,
        labelClass : styles.billingDrawerListContent,
    })

    return listItems;
}



const getBillingBodyContent = ( key, data, toPdf, handleUpdateTargetRef) =>{
    switch(key){
        case "OVERVIEW":
            return {
                BodyComponent : ListComponent,
                listItems : map(BILLING_TAB_CONTENT.OVERVIEW,(listItem)=> getKeyValueMapper({...listItem,name : data[listItem?.name] || "", contentSuffix : BILLING_TAB_NAME_CONTENT[listItem?.name]?.[data?.billingType]})),
                isOpen : true
            }
        case "COMPLIANCE_CHECHLIST":
            return {
                TabComponent : ComplianceDetailsTable, 
                BodyComponent : ListComponent,
                data
            }
        case "AUTO_GENERATED_BILL":
            return {
                isOpen : true,
                BodyComponent : AutoGeneratedBill,
                userName : "Mary Jane",
                headerSuffix : <OuterBorderedButton content={"Download As Pdf"} onClick={toPdf}/>,
                handleUpdateTargetRef
            }
    }
}

const getDrawerTabs = (data, drawerTabs = BILLING_TAB_LABELS, toPdf, handleUpdateTargetRef )=>{
    let tabs = Object.keys(drawerTabs).map((key)=>{
        return {
            label : drawerTabs[key],
            name : BILLING_TAB_NAMES[key],
            type : key,
            ...getBillingBodyContent(key,data, toPdf, handleUpdateTargetRef)
        };
    })

    return tabs
}

export {
    getDrawerTabs
}