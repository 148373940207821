import { logToCloudWatch } from "../../../../configs/awsLogs";
import { BASE_URL } from "../../../../constants/api";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";


const getArchiveActiveAction = async({
        userId
    })=>{
    try{
        const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/moveMemberToActive/${userId}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });

        return request.status;
    }
    catch(e){
        console.log("ERROR : ",e);
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export {
    getArchiveActiveAction
}