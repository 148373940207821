import React from "react";
import {map} from "lodash";

import { TIME_FIELDS } from "./billingTimeRange.fields";
import TimeSelector from "./components/TimeSelector";

import styles from "./billingTimeRange.module.css";

const BillingTimeRange = ({
        ...rest
    }) =>{
    return (
        <div className={styles.container}>
            {map(TIME_FIELDS,(timeFields)=>{
                return <TimeSelector {...rest} {...timeFields}/>
            })}
        </div>
    )
}

export default BillingTimeRange