import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import cx from "classnames";

import DateCalender from "./component/DateCalender/DateCalender";
import { DATE_FORMATS } from "../../constants/dateFormats";

import activeLeftArrow from "./../../../assets/images/arrow/active/basicLeftArrow.svg";
import activeRightArrow from "./../../../assets/images/arrow/active/basicRightArrow.svg";
import nonActiveRightArrow from "./../../../assets/images/arrow/nonActive/nonActiveRight.svg"

import hoveredLeftArrow from "./../../../assets/images/arrow/hovered/hoveredLeftArrow.svg";
import hoveredRightArrow from "./../../../assets/images/arrow/hovered/hoveredRightArrow.svg";

import styles from "./dateFilter.module.css";

const DateFilter = ({
        startDate = moment().subtract(6).format(DATE_FORMATS.MONTH_DAY_YEAR), 
        endDate = moment().format(DATE_FORMATS.MONTH_DAY_YEAR),
        format = DATE_FORMATS.MONTH_DAY_YEAR,
        handleDateUpdateAction = () => {},
        numberOfMonths=2,
    }) =>{

    const [displayCalender, setDisplayCalender] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(()=>{
        document.addEventListener("mousedown",handleCloseDisplayCalender);
    
        return () => {
          document.removeEventListener("mousedown", handleCloseDisplayCalender);
        };
    },[]);

    const handleCloseDisplayCalender = ()=>{
        if (containerDiv.current && !containerDiv.current.contains(event.target)) {
            setDisplayCalender(false);
        }
    }

    const containerDiv = useRef();

    const handleToggleCalenderDisplay = useCallback(()=>{
        setDisplayCalender(!displayCalender);
    },[setDisplayCalender, displayCalender]);

    const formattedStartDate = useMemo(()=>{
        return moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).format(format);
    },[startDate, format])

    const nextDateCheck = useMemo(()=>{
        const nextStartDate = moment(endDate,DATE_FORMATS.MONTH_DAY_YEAR).add(7,'days').format(DATE_FORMATS.MONTH_DAY_YEAR);
        return !moment().isAfter(nextStartDate)
    },[endDate])

    const formattedEndDate = useMemo(()=>{
        return moment(endDate,DATE_FORMATS.MONTH_DAY_YEAR).format(format);
    },[endDate,format]);

    const handleMoveToPreviousWeek = useCallback(()=>{
        handleDateUpdateAction(moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).subtract(7,'days').format(DATE_FORMATS.MONTH_DAY_YEAR));
    },[handleDateUpdateAction, startDate]);

    const handleMoveToNextWeek = useCallback(()=>{
        const nextStartDate = moment(endDate,DATE_FORMATS.MONTH_DAY_YEAR).add(7,'days').format(DATE_FORMATS.MONTH_DAY_YEAR);
        if(!moment().isAfter(nextStartDate)){
            return;
        }
        handleDateUpdateAction(moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).add(7,'days').format(DATE_FORMATS.MONTH_DAY_YEAR));
    },[handleDateUpdateAction, startDate]);

    const handleOnHoveredOpen = ()=>{
        setIsHovered(true);
    }

    const handleOnMovedAway = () => {
        setIsHovered(false);
    }

    return (
        <div ref={containerDiv} className={styles.container}>
            <div className={styles.dateLabel} onMouseEnter={handleOnHoveredOpen} onMouseLeave={handleOnMovedAway}>
                <div className={styles.iconContainer} onClick={handleMoveToPreviousWeek}>
                    <img src={isHovered? hoveredLeftArrow : activeLeftArrow} className={styles.arrowIcon}/>
                </div>
                <div className={styles.dateContentContainer} onClick={handleToggleCalenderDisplay}>
                    <span>{formattedStartDate}</span>
                    <span>-</span>
                    <span>{formattedEndDate}</span>
                </div>
                <div className={styles.iconContainer} onClick={handleMoveToNextWeek}>
                    <img src={ nextDateCheck ? nonActiveRightArrow :(isHovered ? hoveredRightArrow : activeRightArrow)} className={cx(styles.arrowIcon,{[styles.nonActiveArrow]: nextDateCheck})}/>
                </div>
            </div>
            {displayCalender && 
                <DateCalender 
                    handleDateUpdateAction={handleDateUpdateAction} 
                    startDate={startDate}
                    handleToggleCalenderDisplay={handleToggleCalenderDisplay}
                    numberOfMonths={numberOfMonths}
                />}
        </div>
    )
}

DateFilter.defaultProps={
    
}

export default DateFilter;