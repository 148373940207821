import moment from "moment"
import {filter, map} from "lodash";
import { PATIENT_DETAILS, PATIENT_DETAILS_COMPONENT_MAPPING, PATIENT_DETAILS_MAPPING } from "./patientProfile.mappings";

import styles from "./patientProfile.module.css";
import OverViewTab from "./components/OverViewTab";
import PatientInfo from "./components/PatientInfo/PatientInfo";
import ProgressMonitoring from "./components/progressMonitoring/ProgressMonitoring";
import { PATIENT_DETAILS_ENUMS } from "../../constants/UserDetails";
import { getUpperCaseFormatter } from "../../helpers/TextManipulations.helpers";
import { BILLING_CODES, BILLING_TYPES, CARE_PLAN_DETAILS, DURATION_CHANGE_TYPES, FOLLOW_UP_SECTION, NOTES_TYPES } from "./patientProfile.constants";
import { METRICS_NAME_RECENT_TRENDS_MAPPING } from "./patientProfile.constants";

import { getWeeklyFormattedWeeklyDates } from "../../helpers/dateFormatter";
import BalancedPlate from "./components/progressMonitoring/components/Body/components/BalancedPlate/BalancedPlate";
import { CLINICAL_NOTES_SECTIONS, CLINICAL_NOTES_SECTIONS_NAMES } from "./components/NotesFormPopup/component/ClinicalNotes/clinicalNotes.constants";

const getRequestBody = ({type,numberOfDays, prev =0,mealType})=>{
    let metrics =[]
    for(let x =2 ; x<=numberOfDays;x++)
    {
        metrics.push({
            "metricType":type,
            "date": moment().subtract(8-(x%9)+(7*prev),'d').format("YYYY-MM-DD"),
            "mealType": mealType
        })
    }
    return metrics;
}

const getTabComponent = (activeTab) =>{
    switch(activeTab)
    {
        case "Patient Info":
            return PatientInfo;
            break;
        case "Glucose":
            return ProgressMonitoring;
            break;
        case "Balanced Plate":
            return BalancedPlate
            break;
        default : 
            return OverViewTab;
            break;
    }
}

const getReversedData = (data=[])=>{
    const result=[];
    for(let index = data.length-1;index>=0;index--){
        result.push(data[index]);
    }
    return result;
}

const getRecentTrendsFormatter = (recentTrends)=>{
    return map(
    filter(recentTrends?.bloodGlucoseTrendDataList,({
        trendAgeDays
    })=>{
        return trendAgeDays>0
    })
    ,({
        metricRequest,
        message,
        range,
        trendAgeDays
    })=>{
        const { metricType="" , mealType="" } = metricRequest || {};
        return {
            label : message || "Recent Trend",
            range,
            value : range+" mg/dL "+METRICS_NAME_RECENT_TRENDS_MAPPING[metricType]+" "+(metricType=="FASTING_GLUCOSE" ? "" :mealType.toLowerCase()),
            trendAgeDays,
        }
    })
}

const getTabProps=({overviewDuration, balancePlateValues, overViewDurationTypes, handleTabChange, activeTab,handleOverDurationChange, handleNotesDisplayAction,currentProgressMonitoringType})=>{
    switch(activeTab)
    {
        case "Patient Info":
            return {

            };
            break;
        case "Glucose":
            return {
                handleTabChange,
                progressMonitoringType:currentProgressMonitoringType
            };
            break;
        case "Balanced Plate":
            return {
                handleTabChange,
                progressMonitoringType:currentProgressMonitoringType
            }
            break;
        default : 
            return {
                overviewDuration,
                handleOverDurationChange,
                handleTabChange,
                overViewDurationTypes,
                balancePlate : balancePlateValues,
                handleNotesDisplayAction
            };
            break;
    }
}

const getPatientDetailsFormattedData = (data={})=>{
    return map(PATIENT_DETAILS,(detail)=>{
        return {
            label : detail.primary,
            className : styles.profileTabOption,
            textClassName : styles.profileTabOptionText,
            innerContainerClass : styles.innerContainerClass,
            options : map(detail.options,(option)=>{
                return {
                    label : PATIENT_DETAILS_ENUMS[option],
                    className : styles.infoTextContainer,
                    value : data?.[option] || "No Data"
                }
            })   
        }
    })
}

const getCorrespndingUserDetails = (type,value)=>{
    if(value==null || value == undefined || value==""){
        return value;
    }
    switch(type){
        case "insulinStatus" : 
            return value==true ? "True" : "False";
        case "fullname" :
            return getUpperCaseFormatter(value);
        case "edd":
            return moment(value,"YYYY-MM-DD").format("MMM DD, YYYY")
    }
    return value
}

const getCorrespndingLabelProps = (name)=>{
    switch(name){
        case "fullName":
            return {containerClass : styles.primaryLabelContainerClass}; 
            break
        default:
            break
    }
}

const getFormatterHedearOptions = ({options, composedOptions, handleInsulinStatusUpdate})=>{
    return composedOptions.map((keys)=>{
            return {
                label: PATIENT_DETAILS_MAPPING[keys],
                customComponentProps : {
                    ...PATIENT_DETAILS_COMPONENT_MAPPING[keys],
                    handleInsulinStatusUpdate
                },
                value : getCorrespndingUserDetails(keys,options?.[keys]),
                ...getCorrespndingLabelProps(keys)
            }
    })
}

const getDurationChangeRequestBody =({prev,data, userId, name, payload}) =>{
    switch(name){
        case DURATION_CHANGE_TYPES.BASIC_LOGS:
            const {numberOfDays} = payload;
            return [{
                url : "dashboard/glucoseMetrics",
                requestBody : [
                    {
                        metricType: "AVG_FASTING_GLUCOSE",
                        numberOfDays : numberOfDays,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType:"FASTING_TIME_IN_RANGE",
                        numberOfDays: numberOfDays,
                        mealType :"BREAKFAST"
                    },
                ...getRequestBody({type:"FASTING_GLUCOSE",numberOfDays: 8,mealType:"BREAKFAST"})
                ]
            }];
        case DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS:
            const {prev} = payload;
            const {startDate, endDate} = getWeeklyFormattedWeeklyDates({prev, format:"YYYY-MM-DD"})
            return [{
                url : "dashboard/glucoseMetrics",
                requestBody : [
                    {
                        metricType: "AVG_FASTING_GLUCOSE",
                        numberOfDays : 7,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType:"FASTING_TIME_IN_RANGE",
                        numberOfDays: 7,
                        mealType :"BREAKFAST"
                    },
                ...getRequestBody({type:"FASTING_GLUCOSE",numberOfDays: 8,mealType:"BREAKFAST"}),
                ...getRequestBody({type: "OVERALL_TIME_IN_RANGE",numberOfDays: 8,mealType : "BREAKFAST",prev})]
            },
            {
                url : `nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,
                method : "GET"
            }]
        default:
            return {};
    }
}

const getDetailedNotesFormattedContent = (payload, userId, careProUserId)=>{
    const billingDetails = {};
    let billingObjectCounter = 0, totalTime =0;
    Object.keys(CLINICAL_NOTES_SECTIONS_NAMES).forEach((key)=>{
        if(!payload?.[key]){
            return;
        }
        const { timeSpent=0, isActive, ...rest } = payload[key];
        if(!isActive){
            return;
        }
        totalTime+=timeSpent;
        billingDetails[CLINICAL_NOTES_SECTIONS[key]+""]={
            timeSpent : timeSpent,
            billingNotes : {
                ...rest,
                type : key
            }
        }
    });

    const followUpNotes = {};
    Object.keys(FOLLOW_UP_SECTION).forEach((key)=>{
        if(!payload?.[key]){
            return;
        }
        followUpNotes[key]=payload?.[key];
    })

    const carePlanDetails = {};
    Object.keys(CARE_PLAN_DETAILS).forEach((key)=>{
        if(!payload?.[key]){
            return;
        }
        carePlanDetails[key]=payload?.[key];
    })
    
    const finalRequestPlayload = {};
    Object.keys(payload).forEach((key)=>{
        if(CLINICAL_NOTES_SECTIONS_NAMES[key] || FOLLOW_UP_SECTION[key] || CARE_PLAN_DETAILS[key]){
            return;
        }
        finalRequestPlayload[key]=payload[key];
    });

    const formattedbillingStartTime = moment(finalRequestPlayload?.billingStartTime?.toString() || new Date()).format("HH:mm");
    const formattedbillingEndTime = moment(finalRequestPlayload?.billingEndTime?.toString() || new Date()).format("HH:mm");

    const role = window.localStorage.getItem("UserRole");

    return {
        ...finalRequestPlayload,
        billingCode : BILLING_CODES[payload?.billingCode?.[0]],
        billingType : BILLING_TYPES[payload?.billingCode?.[0]],
        reviewDate : moment(payload?.dateOfReview || new Date()).format('YYYY-MM-DD'),
        patientId : userId,
        noteType : payload?.noteType?.toUpperCase(),
        careProUserId,
        billingDetails,
        totalTimeSpent : totalTime,
        followUpNotes,
        carePlanDetails,
        modeOfService : finalRequestPlayload?.modeOfService?.[0] || "",
        billingStartTime : formattedbillingStartTime,
        billingEndTime : formattedbillingEndTime,
        followUpDateByRenewRxUser : !role.includes("NON") ? moment(finalRequestPlayload?.followUp).format("YYYY-MM-DD") : null,
        followUpDateByNonRenewRxUser : role.includes("NON") ? moment(finalRequestPlayload?.followUp).format("YYYY-MM-DD") : null,
    }
}

const getNotesFormDataFormatter = (payload, userId, careProUserId) =>{
    switch(payload?.noteType){
        case NOTES_TYPES.DETAILED:
            return getDetailedNotesFormattedContent(payload, userId, careProUserId)
    }
    const { dateOfReview , carePlanSummary, ...rest } = payload;
    return {
        ...rest,
        reviewDate : moment(dateOfReview || new Date()).format('YYYY-MM-DD'),
        patientId : userId,
        noteType : payload?.noteType?.toUpperCase(),
        careProUserId
    }
}

export {
    getFormatterHedearOptions,
    getRequestBody,
    getPatientDetailsFormattedData,
    getTabComponent,
    getTabProps,
    getRecentTrendsFormatter,
    getDurationChangeRequestBody,
    getNotesFormDataFormatter,
    getReversedData
}