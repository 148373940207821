import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment';

import { progressMonitoringType } from '../../../../../../../store/progressMonitoringAtoms/progressMonitoringType';
import WeeklyFilter from '../WeeklyFilter';
import { getWeeklyFormattedWeeklyDates } from '../../../../../../helpers/dateFormatter';
import DateFilter from '../../../../../../molecules/DateFilter';

import DeviceCard from '../deviceCard';

import styles from './header.module.css';
import { getSummaryCopyAction, getSummaryCopyFormat } from '../Body/body.helpers';
import { getCopyFormattedData, getTrendAnalysisTableFormattedData } from '../Body/components/TrendAnalysisTable/trendAnalysisTable.helpers';

export default function Header({
  progressMonitoringType: incomingProgressMonitoringType,
  handleProgressMonitoringDurationChange,
  filterDuration,
  deviceDetails,
  handleDeviceListDrawerToggle,
  summary,
  recentTrends,
  handleToastUpdateAction,
  trendAnalysisTableData,
}) {

  const [monitoringType, setMonitoringType] = useRecoilState(progressMonitoringType);

  useEffect(() => {
    setMonitoringType(incomingProgressMonitoringType);
  }, [])

  const handleFilterDurationChange = useCallback((updatedStartDate) => {
    handleProgressMonitoringDurationChange({ updatedStartDate })
  }, [handleProgressMonitoringDurationChange]);

  const handleTableDataCopyAction = () => {
    try {
      let copySummaryData = getSummaryCopyFormat(summary, filterDuration || 0, recentTrends, deviceDetails) + "\n";
      const tableData = getTrendAnalysisTableFormattedData(trendAnalysisTableData, filterDuration?.current || 0);
      let copyTrendAnalysisData = getCopyFormattedData(trendAnalysisTableData, tableData);
      let copyData = copySummaryData + copyTrendAnalysisData;
      navigator.clipboard.writeText(copyData)
        .then(() => {
          handleToastUpdateAction({
            enableToast: true,
            toastProps: {
              message: "Glucose Data Copied to Clipboard",
              type: "success"
            }
          })
        });
    }
    catch (e) {
      console.log('COPY FAILED : ', e)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.headingMain}>
        <div className={styles.leftContainer}>
          <div className={styles.headingMainText}>
            Glucose
          </div>
          <DeviceCard
            deviceDetails={deviceDetails}
            handleDeviceListDrawerToggle={handleDeviceListDrawerToggle}
          />
        </div>

        <div className={styles.copyDataAndDateFilterContainer}>
          <div className={styles.copyDataOuterContainer} onClick={handleTableDataCopyAction}>
            <div className={styles.copyDataInnerContainer}>Copy Data</div>
          </div>
          <DateFilter
            handleDateUpdateAction={handleFilterDurationChange}
            startDate={filterDuration}
            format={"ddd, MMM DD"}
            endDate={moment(filterDuration, "MM-DD-YYYY").add(6, 'd').format("MM-DD-YYYY")}
          />
        </div>
      </div>
    </div>
  );
}
