import React, { useState } from "react";
import moment from "moment";
import { convert } from "html-to-text";

import SideDrawer from "../../../../../../../../../../molecules/sideDrawer";
import notesIcon from "./../../../../../../../../../../../assets/images/notes/notes.svg";

import Header from "./component/NotesSidebar/Header/Header.js";
import Body from "./component/NotesSidebar/Body/Body.js";

import NotesCardPopup from "../NotesCardPopup/index.js";
import { getFomattedPrivateNotesSection, getNotesToPopupContentFormat, getFormattedSmartNotesData } from "./notesCard.helper";
import { NOTES_TYPES } from "../../../../Notes.constants";
import { NOTES_FIELDS_NAMES } from "./component/NotesSidebar/Body/body.constants";

import styles from "./notesCard.module.css";
import { getApprovalLabelTag } from "./component/NotesSidebar/Body/body.components";

const NotesCard =(props) =>{

    const [notesPopupDisplay, setNotesPopupDisplay] = useState( false);

    const { reviewDate="", expressNotes="", careProUserFullName="No Data", noteType="", noteApprovalStatus="", followUpNotes , handleToggleLoaderAction=()=>{} } = props;
    
    const { handleGetNotesListAction = ()=>{}, billingType="", billingDetails={}} = props ||  {};

    const { toProvider = "" } = followUpNotes || {};

    const handleNotesDisplayOpen = () =>{
        setNotesPopupDisplay(!notesPopupDisplay);
    }

    const formattedExpressNotes = convert(expressNotes || "");

    const privateNotesData = getFomattedPrivateNotesSection(props);
    const smartNotesData = getFormattedSmartNotesData(props);

    return (
        <>
            {notesPopupDisplay && 
                <SideDrawer
                    HeaderComponent={Header}
                    BodyComponent={Body}
                    toggleDrawer={handleNotesDisplayOpen}
                    headerProps={props}
                    containerClassName={styles.containerClassName}
                    bodyProps={{
                        data : {
                            ...props,
                            [NOTES_FIELDS_NAMES.PRIVATE_NOTE] : privateNotesData,
                            [NOTES_FIELDS_NAMES.SMART_NOTES] : smartNotesData
                        },
                        handleToggleLoaderAction,
                        handleGetNotesListAction
                    }}
                    sliderWindowClassName={styles.sliderWindowClassName}
                />
            }
            <div className={styles.container} onClick={handleNotesDisplayOpen} >
                <img src={notesIcon} className={styles.notesIcon} />
                <div className={styles.mainOuterContainer}>
                    <div className={styles.headerTopContainer}>
                        <div className={styles.headingText}>
                            {moment(reviewDate).format('ddd, MMM DD,YYYY')}
                        </div>
                        <div className={styles.notesTypeContainer}>
                            {NOTES_TYPES[noteType]}
                        </div>
                        {noteApprovalStatus && noteApprovalStatus?.length>0 && getApprovalLabelTag(noteApprovalStatus)}                        
                    </div>
                    <div className={styles.secondaryText}>
                        { billingType?.length>0 && billingType=="PCM" ? convert(billingDetails?.["Care Plan Implementation and Monitoring"]?.billingNotes?.details || "-") : convert(billingDetails?.["Monitoring & Reviewing Patient Data"]?.billingNotes?.referenceData || "-")}
                        { (!billingType || billingType?.length==0) &&formattedExpressNotes?.length>0 ? formattedExpressNotes : convert(toProvider)}
                    </div>
                    <div className={styles.tertiaryText}>
                        <div className={styles.submitttedByLabel}>
                            Submitted By
                        </div>
                        <div className={styles.submittedByContent}>
                            {careProUserFullName}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotesCard