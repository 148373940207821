const NOTES_FIELDS = {
    SMART_NOTES : "SMART NOTES",
    PRIVATE_NOTE : "PRIVATE NOTE",
    ACTIVITY_LOGS : "ACTIVITY LOGS"
}

const NOTES_FIELDS_NAMES = {
    SMART_NOTES : "smartNotes",
    PRIVATE_NOTE : "privateNote",
    ACTIVITY_LOGS : "activityLogs"
}

const NOTES_APPROVAL_STATS = {
    WAITING_FOR_APPROVAL : "Waiting For Approval",
    APPROVED : "Approved" 
}

export {
    NOTES_FIELDS,
    NOTES_FIELDS_NAMES,
    NOTES_APPROVAL_STATS
}