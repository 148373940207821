import { map, forEach } from "lodash";

import { convert } from "html-to-text";

import { CLINICAL_NOTES_SECTIONS, CLINICAL_NOTES_SECTIONS_NAMES } from "../../../../../../../../../NotesFormPopup/component/ClinicalNotes/clinicalNotes.constants";
import { DETAIL_SECTION_FIELDS, DETAIL_SECTION_FIELDS_LABELS } from "./detailSection.constants";
import { BASIC_DETAILS } from "./detailSection.fields";
import ItemBlock from "./components/ItemBlock/ItemBlock";

import styles from "./detailSection.module.css"
import BodyContent from "./components/BodyContent/BodyContent";

const getBillingDetails = (billingNoteListItem)=>{
    const detailsSection = {}
    detailsSection['labelProps']={
        label : billingNoteListItem["modeOfService"],
        labelTypo : styles.contentTableTypo
    }
    detailsSection['listItems']=map(BASIC_DETAILS.fields,(fieldName)=>{
        return {
            label : DETAIL_SECTION_FIELDS_LABELS[fieldName],
            value : billingNoteListItem?.[DETAIL_SECTION_FIELDS[fieldName]] || "-",
            valueClass : styles.contentTableTypo,
        }
    })
    detailsSection["BodyComponent"]=BASIC_DETAILS.BodyComponent;

    return detailsSection
}

const getTimeRelatedDetails = ({
        billingDetails={},
        totalTimeSpent
    })=>{
    const timeDetails = map(Object.keys(billingDetails),(key)=>{
        const { timeSpent=0, billingNotes={} } = billingDetails[key];
        const { type = "" } = billingNotes;
        return {
            label : CLINICAL_NOTES_SECTIONS[type],
            value : timeSpent+"min",
            valueClass : styles.contentTableTypo,
        }
    });
    timeDetails.unshift({
        label : "Total Time",
        value : totalTimeSpent+"min",
        valueClass : styles.contentTableTypo,
        labelClass : styles.contentTableTypo,
    });

    return {
        listItems : timeDetails,
        BodyComponent : ItemBlock
    }
}

const getBillingRelatedData = ({
        billingDetails={}
    }) =>{
    
    const listItems = [];

    forEach(Object.keys(billingDetails),(key)=>{
        const { billingNotes={} } = billingDetails?.[key] || {};
        const { type } = billingNotes || {};
        const subSectionItem = [];
        if(type == CLINICAL_NOTES_SECTIONS_NAMES.CARE_PLAN_IMPLEMENTATION){
            subSectionItem.push({
                label : "Reference Data",
                labelTypo : styles.contentTableTypo,
                content : billingNotes?.refernceData || "No Data",
                BodyComponent : BodyContent
            })

            subSectionItem.push({
                label : "Client Update",
                labelTypo : styles.contentTableTypo,
                content : billingNotes?.clientUpdate || "No Data",
                BodyComponent : BodyContent
            })

            subSectionItem.push({
                label : "Action Plans",
                labelTypo : styles.contentTableTypo,
                content : billingNotes?.actionPlans || "No Data",
                BodyComponent : BodyContent
            })
        }
        listItems.push(...subSectionItem)
    })
    
    return listItems;
}

const getRespectiveFieldSections = ({
        billingWorkSheet={}
    })=>{
    
    const { billingNoteList = []} = billingWorkSheet;

    const details = map(billingNoteList,(billingNoteListItem)=>{
        const { pregnancyWeekNumber } = billingNoteListItem;
        const workSheetBillingDetails = {};
        workSheetBillingDetails['week']= `Week ${pregnancyWeekNumber}`;
        const subSections = [];

        subSections.push(getBillingDetails(billingNoteListItem));

        subSections.push(getTimeRelatedDetails(billingNoteListItem));

        subSections.push(...getBillingRelatedData(billingNoteListItem));

        workSheetBillingDetails["subSections"] = subSections;
        return workSheetBillingDetails;
    })

    return details;
}

export {
    getRespectiveFieldSections
}