const DURATION_CHANGE_TYPES = {
    DAILY_MONITORING_LOGS : "DAILY_MONITORING_LOGS",
    BASIC_LOGS : "BASIC_LOGS",
}

const BILLING_CODES = {
    "CPT 99426, 99427 - Principal Care Management" : "CPT 99426, 99427",
    "CPT 99457, 99458 - Remote Patient Monitoring" : "CPT 99457, 99458"
}

const BILLING_TYPES = {
    "CPT 99426, 99427 - Principal Care Management" : "PCM",
    "CPT 99457, 99458 - Remote Patient Monitoring" : "RPM"
}

const NOTES_STATIC_START_DATE = "2023-01-01"

const NOTES_TYPES = {
    EXPRESS : "Express",
    DETAILED : "Detailed"
}

const FOLLOW_UP_SECTION = {
    toProvider : "toProvider",
    nonClinicalNote : "nonClinicalNote"
}

const CARE_PLAN_DETAILS = {
    carePlanSummary : "carePlanSummary"
}

const METRICS_NAME_RECENT_TRENDS_MAPPING = {
    AVERAGE_TWO_HOUR_POST_PRANDIAL : "2 hour post prandial",
    ONE_HOUR_POST_PRANDIAL : "1 hour post prandial",
    FASTING_GLUCOSE : "before breakfast"
}

const NOTES_DISPLAY_STATUS = {
    DISPLAY : "display",
    HIDDEN : "hidden",
    PARTIAL_DISPLAY : 'partialDisplay'
}

export {
    DURATION_CHANGE_TYPES,
    NOTES_DISPLAY_STATUS,
    NOTES_TYPES,
    NOTES_STATIC_START_DATE,
    FOLLOW_UP_SECTION,
    CARE_PLAN_DETAILS,
    BILLING_CODES,
    BILLING_TYPES,
    METRICS_NAME_RECENT_TRENDS_MAPPING
}