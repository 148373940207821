import React from "react";
import {map, isNil} from "lodash";
import cx from "classnames";

import TirSingleLineChart from "../../../../../../charts/TirSingleLineChart";
import { getFormattedTableData } from "./bloodGlucose.helper";
import CardNoDataFallback from "../../../../../../molecules/cardNoDataFallback/CardNoDataFallback";

import styles from "./bloodGlucose.module.css";
import { getRespectiveAlert } from "./bloodGlucose.mapping";
import ErrorBoundary from "../../../../../../helpers/ErrorBoundary";

const COLOR_MAPPINGS ={
    red : styles.red,
    green : styles.green,
    yellow : styles.yellow,
}

const BloodGlucose = ({
        bloodGlucoseMetrics=[],
        bloodGlucoseComparison = {}
    }) =>{

    if(bloodGlucoseMetrics?.length == 0){
        return <CardNoDataFallback  textClass={styles.secondaryTextClass} containerClass={styles.noDataContainerClass}/>
    }

    return (
        <div className={styles.container}>
            {map(bloodGlucoseMetrics,(option)=>{
                const {allData=[], label="", mainContent = []}= option || {};
                const {color = "", value="" } = bloodGlucoseComparison?.[label?.toUpperCase()] || {};

                const {icon , content, labelClass="", contentClass=""} = getRespectiveAlert(color);

                return (
                    <div className={styles.chartContainer}>
                        <div className={styles.labelContainer}>
                            <div className={styles.label}>
                                {label}
                            </div>
                            <div className={styles.alertContainer}>
                                {
                                    icon &&
                                    <>
                                        <img src={icon} className={styles.alertMetricIcon}/>
                                        {value!=undefined && value!=0 && value!="" && <span className={cx(styles.alertContent, labelClass)}>{value+"%"}</span>}
                                        <span className={cx(styles.alertContent, contentClass)}>{content}</span>
                                    </>
                                }
                                {
                                    !icon && <span className={styles.noData}>NA</span>
                                }
                            </div>
                        </div>
                        <div className={styles.mainTableBody}>
                            <ErrorBoundary>
                                <TirSingleLineChart tableData={getFormattedTableData(allData)} />
                            </ErrorBoundary>
                            <div className={styles.lowerTextContainer}>
                                {map(mainContent,({contentLabel = "", contentSuffix="", contentValue ="", suffix="", color="green"})=>{
                                    return (
                                        <div className={cx(styles.mainContentContainer,COLOR_MAPPINGS[color])}>
                                            <div className={styles.contentLabel}>
                                                <span>{contentLabel}</span>
                                                <span className={styles.contentSuffix}>
                                                    {contentSuffix}
                                                </span>
                                            </div>
                                            {
                                                contentValue ? <div className={styles.contentValueContainer}>
                                                    <span className={styles.contentValue}>{contentValue}</span>
                                                    <span>{suffix}</span>
                                                </div> :
                                                <div className={cx(styles.contentValueContainer, styles.noDataTypo)}>
                                                    NO DATA
                                                </div>
                                            }
                                            
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default BloodGlucose;