import moment from "moment"

import { BASE_URL } from "../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants";
import { getDataInOrderedMealFormat, getDataInWeeklyFormat, getMappedRequestBody, getRequestBody, getMappedResponseBody, getConcretGlucoseValues, getTrendAnalysisFilteredData, getRequestBodyOnStartDate, getDateInWeeklyFormatFromStartDate } from "./progressMonitoring.helper";
import { GLUCOSE_METRICS_SOURCE_TYPES, GLUCOSE_METRICS_TYPES } from "../../../../constants/metricTypes";
import { getWeeklyFormattedWeeklyDates, getWeeklyFormattedWeeklyDatesOnStartDate } from "../../../../helpers/dateFormatter";
import { DEFAULT_START_DATE } from "../../../../constants/date";
import { logToCloudWatch } from "../../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";

const getPatientsDeviceDetails = async({state, setState, payload})=>{
    try{
        const {endDate} = payload;

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const request = await fetch(`${BASE_URL}/api/dashboard/users/getDeviceData`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify({
                userId : pathname?.[2],
                endDate,
                startDate : DEFAULT_START_DATE
            })
        });

        const response = await request.json();

        setState((prev)=>{
            return {
                ...prev,
                deviceDetails : response
            }
        })
    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getInitAction = async({state,setState}) =>{
    const { handleToggleLoaderAction = ()=>{}} = state;
    try{

        handleToggleLoaderAction({isLoading : true});
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");
        
        setState((prev)=>{
            return {
                ...prev,
                activeDurations:{
                    "ComparisonAnalysis":GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                },
                filterDuration: {
                    current : moment().subtract(6,'d').format('MM-DD-YYYY'),
                    comparisonAnalysis : moment().subtract(13,'d').format('MM-DD-YYYY')
                },
                userId : pathname?.[2] 
            }
        });

        getPatientsDeviceDetails({
            state,
            setState,
            payload:{
                startDate : moment().subtract(13,'d').format('YYYY-MM-DD'),
                endDate : moment().format('YYYY-MM-DD')
            }
        })

        const {glucoseLogMetricsSourceTab ="DEFAULT_VALUE"}= state;

        const fastingGlucoseBody = getRequestBodyOnStartDate({type:"FASTING_GLUCOSE",numberOfDays: 7,mealType:"BREAKFAST", sourceType:glucoseLogMetricsSourceTab});
        const breakfastFastingBody = getRequestBodyOnStartDate({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST", sourceType:glucoseLogMetricsSourceTab});
        const lunchFastingBody = getRequestBodyOnStartDate({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH", sourceType:glucoseLogMetricsSourceTab});
        const dinnerFastingBody = getRequestBodyOnStartDate({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER", sourceType:glucoseLogMetricsSourceTab});
        
        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify(({
                userId: pathname?.[2],
                metricRequests: [
                        ...getDateInWeeklyFormatFromStartDate({startDate : moment().subtract(13,'d').format('MM-DD-YYYY'),metricsType:[{
                            metricType:"FASTING_TIME_IN_RANGE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "DINNER"
                        }]}),
                        ...getDateInWeeklyFormatFromStartDate({metricsType:[{
                            metricType:"FASTING_TIME_IN_RANGE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "DINNER"
                        }]}),
                        ...getDateInWeeklyFormatFromStartDate({startDate : moment().subtract(13,'d').format('MM-DD-YYYY'),metricsType:[{
                            metricType:"AVG_FASTING_GLUCOSE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "DINNER"
                        }]}),
                        ...getDateInWeeklyFormatFromStartDate({metricsType:[{
                            metricType:"AVG_FASTING_GLUCOSE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "DINNER"
                        }]}),
                        {
                            metricType: "OVERALL_TIME_IN_RANGE",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        ...getDateInWeeklyFormatFromStartDate({metricsType:[{
                            metricType: "AVERAGE_GLUCOSE",
                            mealType :"BREAKFAST"
                        }]}),
                        {
                            metricType: "GMI",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "STANDARD_DEVIATION",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "GLUCOSE_VERIABILITY",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        ...fastingGlucoseBody,
                        ...breakfastFastingBody,
                        ...lunchFastingBody,
                        ...dinnerFastingBody
                    ],
                compareMetricRequests : [
                    ...getDateInWeeklyFormatFromStartDate({startDate : moment().subtract(13,'d').format('MM-DD-YYYY'),metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "DINNER"
                    }]}),
                ]
            }))
        });

        const data = await response.json();

        const {startDate, endDate} = getWeeklyFormattedWeeklyDates({format:"YYYY-MM-DD"})

        const responseData = await fetch(`${BASE_URL}/dashboard/glucose/metrics/range`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });


        const responseTrendData = await fetch(`${BASE_URL}/dashboard/glucose/logs/range/intervals`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });

        const activityAnalysisData = await responseData.json();

        const trendAnalysisData = await responseTrendData.json();

        setState((prev)=>({
            ...prev,
            tableChartData:[data?.metricsResponses?.slice(0,4),data?.metricsResponses?.slice(4,8),data?.metricsResponses?.slice(8,12),data?.metricsResponses?.slice(12,16)],
            summary:{
                overallTimeInRange : data?.metricsResponses[16],
                primaryCard: getConcretGlucoseValues(data?.metricsResponses?.slice(17,21)),
                primaryCardFullData : data?.metricsResponses?.slice(17,21)
            },
            trendAnalysisTableData : data?.metricsResponses?.slice(21,data?.metricsResponses?.length),
            trendAnalysisMainData: trendAnalysisData?.["glucose_logs"],
            activityAnalysisData: activityAnalysisData?.["blood-glucose-metrics"],
            activeDurations:{
                "ComparisonAnalysis":'ONE_HOUR',
            },
            comparisonMetricSegment : data?.timeSegmentMap,
            filterDuration: {
                current : moment().subtract(6,'d').format("MM-DD-YYYY"),
                comparisonAnalysis : moment().subtract(13,'d').format("MM-DD-YYYY"),
            },
            glucoseLogMetricsSourceTab : GLUCOSE_METRICS_SOURCE_TYPES.DEFAULT_VALUE
        }));
        handleToggleLoaderAction({isLoading : false});
    }
    catch(e)
    {
        setState((prev)=>({
            ...prev,
            error:e
        }));
        handleToggleLoaderAction({isLoading : false});
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getCurrentPageFilterUpdate = async({state, setState, payload})=>{
    try{
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const {glucoseLogMetricsSourceTab ="DEFAULT_VALUE"}= state;

        const {updatedStartDate = 0} = payload || {}

        const fastingGlucoseBody = getRequestBodyOnStartDate({ startDate : updatedStartDate, type:"FASTING_GLUCOSE",numberOfDays: 7,mealType:"BREAKFAST", sourceType:glucoseLogMetricsSourceTab});
        const breakfastFastingBody = getRequestBodyOnStartDate({ startDate : updatedStartDate, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST", sourceType:glucoseLogMetricsSourceTab});
        const lunchFastingBody = getRequestBodyOnStartDate({ startDate : updatedStartDate, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH", sourceType:glucoseLogMetricsSourceTab});
        const dinnerFastingBody = getRequestBodyOnStartDate({ startDate : updatedStartDate, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER",sourceType:glucoseLogMetricsSourceTab});

        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify(({
                userId: pathname?.[2],
                metricRequests: [
                    ...getDateInWeeklyFormatFromStartDate({metricsType:[{
                            metricType: "OVERALL_TIME_IN_RANGE",
                            mealType: "DINNER"
                        },
                        {
                            metricType: "AVERAGE_GLUCOSE",
                            numberOfDays: 7,
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "GMI",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "STANDARD_DEVIATION",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "GLUCOSE_VERIABILITY",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        }], startDate :updatedStartDate}),

                        ...fastingGlucoseBody,
                        ...breakfastFastingBody,
                        ...lunchFastingBody,
                        ...dinnerFastingBody
                    ]
            }))
        });

        const {startDate, endDate} = getWeeklyFormattedWeeklyDatesOnStartDate({format:"YYYY-MM-DD", startDate : updatedStartDate})

        const responseData = await fetch(`${BASE_URL}/dashboard/glucose/metrics/range`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });


        const responseTrendData = await fetch(`${BASE_URL}/dashboard/glucose/logs/range/intervals`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });

        const activityAnalysisData = await responseData.json();

        const trendAnalysisData = await responseTrendData.json();

        const data = await response.json();

        setState((prev)=>({
            ...prev,
            summary:{
                overallTimeInRange : data?.metricsResponses[0],
                primaryCard: getConcretGlucoseValues(data?.metricsResponses?.slice(1,5)),
                primaryCardFullData : data?.metricsResponses?.slice(1,5)
            },
            trendAnalysisTableData : data?.metricsResponses?.slice(5,data?.metricsResponses?.length),
            trendAnalysisMainData: trendAnalysisData?.["glucose_logs"],
            activityAnalysisData: activityAnalysisData?.["blood-glucose-metrics"],
            filterDuration: {
                ...prev?.filterDuration,
                current : updatedStartDate,
            },
        }));
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
};

const getComparisonAnalysisFilterUpdate = async({setState, payload})=>{
    try{

        const { updatedStartDate =""} = payload || {};

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const responseData = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method : "POST",
            headers : {
                'content-type' : "application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify({
                userId : pathname?.[2],
                metricRequests: [
                    ...getDateInWeeklyFormatFromStartDate({startDate:updatedStartDate,metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "DINNER"
                    }]}),
                    ...getDateInWeeklyFormatFromStartDate({ metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "DINNER"
                    }]}),
                    ...getDateInWeeklyFormatFromStartDate({startDate:updatedStartDate,metricsType:[{
                        metricType:"AVG_FASTING_GLUCOSE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "DINNER"
                    }]}),
                    ...getDateInWeeklyFormatFromStartDate({ metricsType:[{
                        metricType:"AVG_FASTING_GLUCOSE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "DINNER"
                    }]}),
                ],
                compareMetricRequests:[
                    ...getDateInWeeklyFormatFromStartDate({startDate:updatedStartDate,metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "DINNER"
                    }]})
                ]
            })
        });

        const comparisonData = await responseData.json();

        setState((prev)=>{
            return {
                ...prev,
                filterDuration: {
                    ...prev?.filterDuration,
                    comparisonAnalysis : updatedStartDate,
                },
                tableChartData:[comparisonData?.metricsResponses?.slice(0,4),comparisonData?.metricsResponses?.slice(4,8),comparisonData?.metricsResponses?.slice(8,12),comparisonData?.metricsResponses?.slice(12,16)],
                comparisonMetricSegment : comparisonData?.timeSegmentMap,
            }
        });
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getProgressMonitoringDurationUpdate = async({state, setState, payload}) =>{
    const { handleToggleLoaderAction = ()=>{}} = state;
    try{
        handleToggleLoaderAction({isLoading : true});
        switch(payload?.name){
            case "comparisonAnalysis":
                getComparisonAnalysisFilterUpdate({setState, state, payload})
                .then(()=>{
                    handleToggleLoaderAction({isLoading : false});
                })
                break;
            default:
                getCurrentPageFilterUpdate({state, setState, payload})
                .then(()=>{
                    handleToggleLoaderAction({isLoading : false});
                })
                break;
        }
    }
    catch(e)
    {
        setState((prev)=>({
            ...prev,
            error :e,
        }));
        handleToggleLoaderAction({isLoading : false});
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getInternalTabUpdateAction = ({setState,payload,state}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                currentInternalTabState : payload
            }
        })
    }
    catch(e){
        setState((prev)=>({
            ...prev,
            error :e,
        }));
    }
}

const getUpdateGlucoseLogsTabAction = async({setState, payload, state,...rest})=>{
    const {filterDuration={}, handleToggleLoaderAction=()=>{} } = state;
    try{
        const {updatedStartDate, tab } = payload

        handleToggleLoaderAction({isLoading : true});
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const fastingGlucoseBody = getRequestBodyOnStartDate({ startDate: updatedStartDate, type:"FASTING_GLUCOSE",numberOfDays: 7,mealType:"BREAKFAST", sourceType : tab});
        const breakfastFastingBody = getRequestBodyOnStartDate({ startDate: updatedStartDate, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST", sourceType : tab});
        const lunchFastingBody = getRequestBodyOnStartDate({ startDate: updatedStartDate, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH", sourceType : tab});
        const dinnerFastingBody = getRequestBodyOnStartDate({ startDate: updatedStartDate, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER", sourceType : tab});

        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify(({
                userId: pathname?.[2],
                metricRequests:[
                    ...fastingGlucoseBody,
                    ...breakfastFastingBody,
                    ...lunchFastingBody,
                    ...dinnerFastingBody
                ]
            }))
        });

        const responseMetricsData = await response.json();

        setState((prev)=>{
            return {
                ...prev,
                trendAnalysisTableData : responseMetricsData?.metricsResponses,
                glucoseLogMetricsSourceTab : tab
            }
        });

        handleToggleLoaderAction({isLoading : false});
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
    catch(e){
        console.log('ERROR IS : ',e)
        handleToggleLoaderAction({isLoading : false});
    }
}

export default {
    INIT_ACTION : getInitAction,
    UPDATE_PROGRESS_MONITORING_DURATION : getProgressMonitoringDurationUpdate,
    UPDATE_INTERNAL_TAB : getInternalTabUpdateAction,
    UPDATE_COMPARISON_ANALYSIS_TYPE : ()=>{},
    UPDATE_GLUCOSE_LOGS_TAB : getUpdateGlucoseLogsTabAction,
    GET_PATIENTS_DEVICE_DETAILS : getPatientsDeviceDetails
}