import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants/api";
import { RRX_PRACTICE_ID } from "../rrxCoach.constants";
import { logToCloudWatch } from "../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../constants/awsLogs.constants";

const useCoachManagement = ({tab="", setIsLoading})=>{
    const [activeTab, setActiveTab] = useState(tab);
    const [coachList, setCoachList] = useState([]);

    useEffect(()=>{
        updateCoachList(activeTab);
    },[activeTab]);

    async function updateCoachList (){
        try{
            setIsLoading(true);
            const responseObject = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getMembers/${RRX_PRACTICE_ID}/${activeTab}`,{
                method : "GET",
                headers:{
                    'content-type':'application/json',
                    "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
                }
            });

            const coachList = await responseObject.json();
            
            setCoachList(coachList?.dashboardUserDataList);

            setIsLoading(false);
        }
        catch(e){
            console.log('ERROR IS : ',e);
            setIsLoading(false);
            logToCloudWatch({
                userId: window.localStorage.getItem("id"),
                componentStack: e.stack,
              }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        }
    }

    const resendInviteAction = async(id)=>{
        try{
            setIsLoading(true);
            const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/resendAddNewMemberInviteEmail/${id}`,{
                method : "GET",
                headers:{
                    'content-type':'application/json',
                    "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
                },
            })
            setIsLoading(false);    
            return response.status;    
        }
        catch(e){
            setIsLoading(false);    
            console.log('ERROR IS : ',e);
            logToCloudWatch({
                userId: window.localStorage.getItem("id"),
                componentStack: e.stack,
              }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        }

    }

    const updateActiveTab = (tab)=>{
        setActiveTab(tab);
    }

    return {
        activeTab,
        coachList,
        updateActiveTab,
        updateCoachList,
        resendInviteAction
    }
}

export default useCoachManagement