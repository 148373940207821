import moment from "moment";

const getWeeklyFormattedWeeklyDates = ({prev=0,format})=>{
    return {
        endDate : moment().subtract((7*prev),'d').format(format),
        startDate : moment().subtract(6+(7*prev),'d').format(format)
    }
}

const getWeeklyFormattedWeeklyDatesOnStartDate = ({startDate=moment().subtract(6,'d').format("MM-DD-YYYY"), format}) =>{
    return {
        startDate : moment(startDate,'MM-DD-YYYY').format(format),
        endDate : moment(startDate,'MM-DD-YYYY').add(6,'d').format(format),
    }
}

export {
    getWeeklyFormattedWeeklyDates,
    getWeeklyFormattedWeeklyDatesOnStartDate
}