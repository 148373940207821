import React, {useEffect, useState} from "react";
import cx from "classnames";
import {Input} from "antd";

import styles from "./textArea.module.css";

const TextArea = (props) =>{

    const {placeHolder = "", handleFieldChange = ()=>{},  name = "", checkErrorState = ()=>{}, errorTypes = [],value="",...rest} = props;

    const [inputText, setInputText] = useState(value);

    useEffect(()=>{
        if(inputText!=value)
            setInputText(value)
    },[value])

    const handleTextAreaChange = (event)=>{
        event?.preventDefault();
        setInputText(event?.target?.value);
        handleFieldChange({label:name})(event?.target?.value);
        checkErrorState(event?.target?.value, name, errorTypes);
    }

    return (
        <div className={styles.container}>
            <Input.TextArea
                minRows={18}
                onChange={handleTextAreaChange}
                placeHolder={placeHolder}
                value={inputText}
                style={{minHeight : "144px"}} 
                rootClassName={cx(styles.textArea,{[styles.emptyPlaceHolderTypography] : inputText?.length == 0})}
                {...rest} 
            />
        </div>
    )
}

export default TextArea;