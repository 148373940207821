import React from "react";

import upwardIcon from "./../../../../../../../../../../../assets/images/arrow/active/basicUpwardArrow.svg";
import downwardIcon from "./../../../../../../../../../../../assets/images/arrow/active/basicDownArrow.svg"

import styles from "./header.module.css";

const Header = ({
        content="",
        toggleDisplay,
        displayContent,
        children
    })=>{
    return (
        <div className={styles.container}>
            <div className={styles.mainContent}>
                <img src={displayContent ? upwardIcon : downwardIcon} className={styles.arrowIcon} onClick={toggleDisplay}/>
                <span className={styles.mainContentTypo}>
                    {content}
                </span>
            </div>
            {children}
        </div>
    )
}

export default Header;