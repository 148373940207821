import { logToCloudWatch } from "../../configs/awsLogs";
import { BASE_URL, API_STATUS_CODES } from "../../constants/api";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";


const verifyToken = async (setIsTokenExpired) => {

    try{

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const request = await fetch(`${BASE_URL}/api/dashboard/users/validateInviteEmailVerificationToken/${token}`, {
            method: "GET",
        });
        if (request.status !== API_STATUS_CODES.SUCCES) {
            console.log("Token Expired");
            setIsTokenExpired(true);
        }    
    }
    catch(e){
        console.log('error is : ',e);
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export default verifyToken;