import React from "react";
import {map, rest} from "lodash";

import { BODY_SECTION_COMPONENT, BODY_SECTION_TYPES } from "./body.fields";
import ApprovalNotification from "./components/ApprovalNotification";
import ToggableTab from "./components/ToggableTab";
import { getCorrespondingLabel } from "./body.helpers";
import { handleApprovalOfNotes } from "./body.async";

import styles from "./body.module.css";

const Body = ({
        data,
        handleToggleLoaderAction,
        handleGetNotesListAction
    }) =>{
    
    const { noteType="", noteApprovalStatus="", id=""} = data;

    const handleApprovalFlow = ()=>{
        handleToggleLoaderAction({isLoading : true});
        handleApprovalOfNotes({id})
        .then((status)=>{
            console.log('NOTE APPROVED : ', status);
        })
        .catch((e)=>{
            console.log("ERROR : ",e);
        })
        .finally(()=>{
            handleToggleLoaderAction({ isLoading : false});
            handleGetNotesListAction();
        })
    }

    return (
        <div className={styles.container}>
            <ApprovalNotification
                notesData={data}
                handleApprovalFlow={handleApprovalFlow}
            />
            {map(BODY_SECTION_TYPES[noteType],({
                    name, label, ...rest
                })=>(
                <ToggableTab 
                    BodyComponent={BODY_SECTION_COMPONENT[name] || (()=><></>)} 
                    content={data[name] || ""}
                    name={name}
                    data={data}
                    label={getCorrespondingLabel(label, noteApprovalStatus)}
                    contentClassName={styles.innerContentClass}
                    {...rest}
                />
            ))}
        </div>
    )
}

export default Body;