import { logToCloudWatch } from "../../../../configs/awsLogs";
import { BASE_URL } from "../../../../constants/api";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";
import { RRX_PRACTICE_ID } from "../../rrxCoach.constants";

const getDivisionList = async()=>{
    try{
        const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getDivisionData/${RRX_PRACTICE_ID}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });

        const divisionList = await request.json();
        return divisionList;
    }
    catch(e){
        console.log('ERROR IS : ',e);
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export {
    getDivisionList
}