const getRespectiveCoachRoleState = (role, needsApproval, anyNotesInCurrentMonth)=>{
    if(role.includes("BILLER") && anyNotesInCurrentMonth){
        return {
            displayTag : true,
            isBiller: true,
        }
    }
    if(role.includes("NON_RENEWRX_CARE") && needsApproval){
        return {
            displayApprovalTag : true,
            isCoach: true,
        }
    }
    return {}
}

export {
    getRespectiveCoachRoleState
}