import React, { useCallback, useEffect, useState, useRef } from "react";

import topArrow from "./../../../../../../../../../assets/images/arrow/active/basicUpArrow.svg"
import bottomArrow from "./../../../../../../../../../assets/images/arrow/active/basicDownArrow.svg"

import styles from "./rangeSelector.module.css";

const RangeSelector = ({
        handleFieldChange=()=>{},
        name="",
        maxRangeLimit=30
    }) =>{

    const [timeSpent, setTimeSpent] = useState(1);
    const inputRef = useRef(null);

    useEffect(()=>{
        handleFieldChange({
            label : name
        })(1)
    },[]);

    const handleMoveUp = useCallback(()=>{
        if(timeSpent==1)
            return;
        setTimeSpent(timeSpent-1);
        handleFieldChange({
            label : name
        })(timeSpent-1);
    },[setTimeSpent,timeSpent,handleFieldChange]);

    const handleMoveDown = useCallback(()=>{
        if(timeSpent==maxRangeLimit)
            return;
        setTimeSpent(timeSpent+1);
        handleFieldChange({
            label : name
        })(timeSpent+1);
    },[setTimeSpent,timeSpent,handleFieldChange]);

    const handleValueChange = (event)=>{
        if(!event.target || event.target.value>maxRangeLimit){
            return;
        }
        const resultNumber = Number(event.target.value || 0).toString();
        if(resultNumber==0){
            setTimeSpent("");
            handleFieldChange({
                label : name
            })(parseInt(resultNumber || 0));
            return;    
        }
        setTimeSpent(event.target.value || "");
        handleFieldChange({
            label : name
        })(parseInt(resultNumber || 0));
    }

    const handleMouseDown = (event)=>{
        event.stopPropagation();
        inputRef.current.focus();
    }

    return (
        <div className={styles.container}>
            <span className={styles.fullWidth} onClick={handleMouseDown}>
                <input
                    ref={inputRef}
                    type="number"
                    value={timeSpent}
                    className={`${styles.inputBox} ${(timeSpent ? timeSpent+"" : "").length>1 && styles.increasedInput}`}
                    onChange={handleValueChange}
                /> Min
            </span>
            <div className={styles.arrowContainer}>
                <img src={topArrow} className={styles.arrowIcon} onClick={handleMoveDown}/>
                <img src={bottomArrow} className={styles.arrowIcon} onClick={handleMoveUp}/>
            </div>
        </div>
    )
}

export default RangeSelector;