import React from "react";
import moment from "moment";

import styles from "./header.module.css";

const Header = ({
        CancelComponent = ()=><></>,
        reviewDate="",
        careProUserFullName="",
        totalTimeSpent="",
        noteType
    }) =>{
    return (
        <div className={styles.container}>
            <div className={styles.headingContainer}>
                <div className={styles.primaryContent}>
                    Smart Note - {moment(reviewDate).format("ddd, MMM DD, YYYY")}
                </div>
                <div className={`${styles.descriptionContainer} ${styles.secondaryContent}`}>
                    <span>{careProUserFullName}</span>
                    {
                        noteType=="DETAILED" &&
                        <>
                            <span>|</span>
                            <span>Total Time: {totalTimeSpent} Min</span>
                        </>
                    }
                </div>
            </div>
            <CancelComponent />
        </div>
    )
}

export default Header;