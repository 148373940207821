import momentTimezone from "moment-timezone";
import { API_STATUS_CODES, BASE_URL } from "../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../constants/keyConstants";
import { getSanitizedValues } from "./practiceCreateAccountCard.helper";
import { logToCloudWatch } from "../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";

const getInitAction = ({state,setState})=>{
    try{
        const currentPage = 1;
        setState({...state,currentPage});    
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e,
            }
        })
    }
}

const setPointerToNextPage = ({state, setState})=>{
    try{
        const {currentPage = 1} = state;
        setState({...state,currentPage: currentPage+1});    
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e,
            }
        })
    }
}


const setUpdatedValues = ({state,setState,payload})=>{
    try{
        const {formValues = {}} = state;
        setState((prev)=>({...prev, formValues:{...formValues,...payload}}));    
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e,
            }
        })
    }
}

const setPageSubmit = ({state,setState, payload})=>{
    try{
        const {formValues = {},currentPage = 1} = state;
        setState({...state, formValues:{...formValues,...payload},currentPage: currentPage+1})    
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e,
            }
        })
    }
}

const getSendPhoneOtpAction = async({state})=>{
    try{
        const { formValues } = state;
        
        const {phoneNumber="", countryCode=""} = formValues || {};

        const request = await fetch(`${BASE_URL}/api/dashboard/users/verifyPhoneNumber`,{
            method:"POST",
            headers : {
                "Authorization": "Bearer " + window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
                'content-type':"application/json"
            },
            body: JSON.stringify({
                userId : window.localStorage.getItem('id'),
                countryCode,
                phoneNumber,
            })
        })

        return request.status;
    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            api: '/api/dashboard/users/verifyPhoneNumberCode',
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    console.log('ERROR IS :',e);
    }
}

const getFormSubmitAction = async({state,setState,payload,navigate}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                displayToast : false,
                isLoading:true
            }
        })
        const {formValues = {}} = state;
        const {profilePhoto, ...requestPayload} = {...formValues,...payload};

        const timezoneOffsetHours = parseFloat(momentTimezone().tz(momentTimezone.tz.guess()).utcOffset()/60).toFixed(2);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),);

        const formdata = new FormData();
        const blob = new Blob([JSON.stringify( getSanitizedValues({...requestPayload, timezoneOffsetHours }))],{ type: 'application/json' });
        formdata.append("careProUserUpdateRequest", blob,"data.json");
        formdata.append("profilePhoto", profilePhoto);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(`${BASE_URL}/api/dashboard/users/updateCareProAccount`, requestOptions)
        .then((result) => {
            setPointerToNextPage({state, setState})
        })
        .catch((error) => console.error(error));

    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e,
                isLoading:true
            }
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            api: '/api/dashboard/users/updateCareProAccount',
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const setPageStateToPrevious = ({state,setState})=>{
    try{
        setState((prev)=>{
            return {...prev,currentPage: (prev?.currentPage || 2) -1}
        })    
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e,
            }
        })        
    }
}

const setPhoneOtpPopup = ({ setState})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                phoneOtpModalDisplay : !prev?.phoneOtpModalDisplay
            }
        })
    }
    catch(e){

    }
}

const getSetupCompletedAction = ({setState})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                finishComplete : true
            }
        })
    }
    catch(e){

    }
}

const getLoaderToggleAction = ({setState, payload})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : payload ?  payload.status : !prev.isLoading
            }
        })
    }
    catch(e){
        console.log('ERROR IS : ',e);
    }
}

export default {
    INIT_ACTION : getInitAction,
    MOVE_TO_NEXT_PAGE: setPointerToNextPage,
    PAGE_SUBMIT_ACTION: setPageSubmit,
    FORM_SUBMIT_ACTION: getFormSubmitAction,
    FORM_VALUES_UPDATE: setUpdatedValues,
    GO_TO_PREVIOUS_PAGE: setPageStateToPrevious,
    DISPLAY_PHONE_OTP_POPUP : setPhoneOtpPopup,
    SEND_PHONE_OTP : getSendPhoneOtpAction,
    FINISH_COMPLETE : getSetupCompletedAction,
    TOGGLE_LOADER : getLoaderToggleAction
}