import { API_METHOD_TYPES, BASE_URL } from "../../../../../../../../../../../../../constants/api"
import { LOCAL_STORAGE_KEYS } from "../../../../../../../../../../../../../constants/keyConstants";

const handleApprovalOfNotes = async({
        id
    }) => {

    try{
        const userId = window.localStorage.getItem("id");

        const request = await fetch(`${BASE_URL}/api/dashboard/careProUserNotes/${id}/approve/${userId}`,{
            method : API_METHOD_TYPES.PATCH,
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        return request.status;
    }
    catch(e){
        console.log('ERROR : ',e);
    }
}

export {
    handleApprovalOfNotes
}