import React from "react";
import { map } from "lodash";
import { Tooltip } from "antd";

import { BIILLING_PROGRESS_BARS } from "./billingCode.constants";
import ProgressBar from "../../../../../../atoms/progressBar";
import MultiSelect from "../../../../../../molecules/Multiselect/MultiSelect";
import { getToolTipContent } from "./billingCode.component";
import { getBillingCodeProgess } from "./billingCode.helpers";

import styles from "./billingCode.module.css"

const BillingCode = ({
        notesBasedBilling,
        ...rest
    })=>{

    const complianceData = getBillingCodeProgess(notesBasedBilling,(rest?.formState?.billingCode?.[0] || ""),(rest?.formState?.dateOfReview || ""));

    return (
        <div className={styles.container}>
            <MultiSelect {...rest} />
            <div className={styles.billingUtilizationContainer}>
                <span className={styles.billingUtilizationLabel}>Current Billing Cycle Utilization</span>
                <span className={styles.progressContainer}>
                    {map(BIILLING_PROGRESS_BARS,(_,index)=>{
                        const { rangeStart=0, rangeEnd=0, pendingMins=0, utilized }= complianceData?.[index] || {}
                        const color = (utilized ? "#7ACCAE" : "#E4A944") 
                        return (
                            <Tooltip title={getToolTipContent({max:(rangeEnd-rangeStart+1), utilized:(rangeEnd-rangeStart-pendingMins+1) })}>
                                <ProgressBar 
                                    color={color}
                                    now={Math.max(0,rangeEnd-rangeStart-pendingMins)}
                                    min={0}
                                    max={rangeEnd-rangeStart+1}
                                    className={styles.progressItem}
                                />
                            </Tooltip>
                        )
                    })}
                </span>
            </div>
        </div>
    )
}

export default BillingCode