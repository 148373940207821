import { filter, forEach, map} from "lodash";

const getCurrentMonthConsolidatedItems = (billingList=[]) => {
    return filter(billingList,({ billingStatus })=>{
        return billingStatus=="READY_TO_BILL";
    })
}

const getFormattedConsolidatedBillingData = (billingList=[])=>{
    if(billingList?.length==0){
        return [];
    }
    const currentMonthList = getCurrentMonthConsolidatedItems(billingList);
    const consolidatedComplianceCheck = [];
    const consolidatedBillingNoteList = [];
    let check=true;

    forEach(currentMonthList,({
        complianceCheck = [],
        billingWorkSheet = {},
        billingType
    })=>{
        const { billingNoteList=[] } = billingWorkSheet;
        let updatedComplianceList = map(complianceCheck,(complianceItems)=>{
            return{
                ...complianceItems,
                billType : billingType
            }
        })
        consolidatedComplianceCheck.push(...updatedComplianceList);
        consolidatedBillingNoteList.push(...billingNoteList);
    })

    return {
        billingCycleStartDate : billingList?.[0]?.billingCycleStartDate,
        billingCycleEndDate : billingList?.[0]?.billingCycleEndDate,
        billingType : ["PCM", "RPM"],
        billingStatus : "READY_TO_BILL",
        complianceCheck : consolidatedComplianceCheck,
        billingWorkSheet : {
            ...(currentMonthList?.[0]?.billingWorkSheet || {}),
            billingNoteList : consolidatedBillingNoteList
        }
    }
}

export {
    getFormattedConsolidatedBillingData
}