import moment from "moment";

const getBillingActiveStatus = (billingDate="")=>{
    if(!billingDate || billingDate.length==0){
        return false;
    }

    let date = new Date();
    billingDate = new Date(billingDate);

    let difference = date.getTime()-billingDate.getTime();

    return (difference/(1000*3600*24))<0 
}

const getConsolidatedDrawerDates = ()=>{
    return {
        startDate : `1 ${moment().format("MMM")}`,
        endDate : `${moment().daysInMonth()} ${moment().format("MMM YYYY")}`
    }
}

export {
    getBillingActiveStatus,
    getConsolidatedDrawerDates
}