import React, { useState } from "react";
import Header from "./component/Header";

import styles from "./toggableTab.module.css";
import Body from "./component/Body/Body";

const ToggableTab = (props={}) =>{
    
    const [displayContent, setDisplayContent ] = useState(props.isOpen || false);

    const handleToggleDisplay = ()=>{
        setDisplayContent(!displayContent);
    }

    return (
        <div className={styles.container}>
            <Header
                toggleDisplay={handleToggleDisplay}
                displayContent={displayContent}
                content={props.label}
            >
                {props?.headerSuffix || ""}
            </Header>
            {displayContent && <Body {...props}/>}
        </div>
    )
}

export default ToggableTab;