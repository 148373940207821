import React from "react";

import dropdownIcon from "./../../../../../../../assets/images/dropdown_normal.svg"
import glucoseIcon from "./../../../../../../../assets/images/glucose.svg";

import styles from "./alertCard.module.css";

const AlertCard = ({label = "", trendAgeDays="",value=""}) =>{
    return (
        <div className={styles.container}>
            <img src={glucoseIcon} className={styles.cardIcon} />
            <div className={styles.textContainer}>
                <div className={styles.mainText}>
                    {label}
                </div>
                <div className={styles.contentText}>
                    {value}
                </div>
                <div className={styles.contentText}>
                    {`Trend Age : ${trendAgeDays} days`}
                </div>
            </div>
        </div>
    )
}

export default AlertCard;