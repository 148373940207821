import { useState } from "react"
import { BASE_URL } from "../../../../../constants/api";
import { logToCloudWatch } from "../../../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../../../constants/awsLogs.constants";

const useWorksiteManagement = ({setIsLoading})=>{
    const [formValues, setFormValues] = useState({
        country : "United States of America",
        city : "Littleton",
        state : "New York",
        typeOfPractice:""
    });

    const formValuesFormatter = ()=>{
        const {addressTwo, addressOne ,...rest} = formValues;
        return {
            ...rest,
            address : addressOne,
            addressLineTwo : addressTwo
        }        
    }

    const submitFormValues = async()=>{
        try{
            setIsLoading(true);
            const requestFormValues = formValuesFormatter();
            const url = new URL(window.location.href)
            const pathname = url.pathname.split("/");
    
            const practiceId = pathname?.[2] || "";
    
            const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/addNewDivision`,{
                method : "POST",
                headers: {
                    'content-type': 'application/json',
                    "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
                },
                body : JSON.stringify({
                    ...requestFormValues,
                    practiceId
                }),
            })

            setIsLoading(true);
            return request.status=="200" || request.status=="201" || request.status=="204" ;
        }
        catch(e){
            setIsLoading(true);
            console.log('ERROR IS : ',e);
            logToCloudWatch({
                userId: window.localStorage.getItem("id"),
                componentStack: e.stack,
              }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        }
    }

    const updateFormValues = (type,value)=>{
        setFormValues((prev)=>{
            return {
                ...prev,
                [type] : value
            }
        })
    }

    return {
        formValues,
        updateFormValues,
        submitFormValues
    }
}

export {
    useWorksiteManagement
}