import React from "react";

import styles from "./itemBlockContainer.module.css"

const ItemBlockContainer = ({
        label,
        labelTypo="",
        BodyComponent=()=><></>,
        ...rest
    }) =>{
    return (
        <div className={styles.container}>
            {label && <div className={`${styles.labelTypo} ${labelTypo}`}>{label}</div>}
            <BodyComponent {...rest}/>
        </div>
    )
}

export default ItemBlockContainer