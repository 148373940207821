import React from "react";

import basicUpArrow from "./../../../../../../../../../../../../../../../../../../assets/images/arrow/active/basicUpArrow.svg";
import basicDownArrow from "./../../../../../../../../../../../../../../../../../../assets/images/arrow/active/basicDownArrow.svg";

import styles from "./baseHeader.module.css"

const BaseHeader = ({
        label="",
        handleToggleActiveContent= ()=>{},
        children=<></>,
        activeContent
    }) =>{
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <img
                    src={ activeContent ? basicUpArrow : basicDownArrow}
                    className={styles.arrowIcon}
                    onClick={handleToggleActiveContent}
                />
                <span className={styles.primaryContent}>
                    {label}
                </span>
            </div>
            {children}
        </div>
    )
}

export default BaseHeader