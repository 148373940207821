import React from "react";

import styles from "./body.module.css";

const Body = ({
        BodyComponent = ()=><></>,
        className,
        ...rest
    }) =>{
    return (
        <div className={`${styles.container} ${className}`}>
            <BodyComponent {...rest}/>
        </div>
    )
}

export default Body;