import { NOTES_FIELDS, NOTES_FIELDS_NAMES } from "./body.constants";
import ActivityLogsBody from "./components/ActivityLogsBody";

const BODY_SECTION_TYPES = {
    'DETAILED' :[{
            name : NOTES_FIELDS_NAMES.SMART_NOTES,
            label : NOTES_FIELDS.SMART_NOTES,
            isOpen : true
        },
        {
            name : NOTES_FIELDS_NAMES.PRIVATE_NOTE,
            label : NOTES_FIELDS.PRIVATE_NOTE
        },
        {
            name : NOTES_FIELDS_NAMES.ACTIVITY_LOGS,
            label : NOTES_FIELDS.ACTIVITY_LOGS
        }
    ],
    'PRIVATE' :[
        {
            name : NOTES_FIELDS_NAMES.SMART_NOTES,
            label : NOTES_FIELDS.SMART_NOTES,
            isOpen : true
        } 
    ]
};

const BODY_SECTION_COMPONENT = {
    [NOTES_FIELDS_NAMES.ACTIVITY_LOGS]: ActivityLogsBody
}

export {
    BODY_SECTION_TYPES,
    BODY_SECTION_COMPONENT
}