import moment from "moment";
import { BASE_URL } from "../../constants/api";
import { logToCloudWatch } from "../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";

const getPrePrandial = async({state,setState, payload})=>{

    try{
    const {day, calenderUpdate = 0} = payload || {};

    const currentDate = moment().subtract(8-((day+1)%8)-calenderUpdate,"d").format("YYYY-MM-DD");

    setState({isLoading : true})

    const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            'authorization' : "Bearer "+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
          },
          body: JSON.stringify({
            "userId": "47ba148a-ed0e-436e-8a45-f357a54667fc",
            "metricRequests": [
                {
                    "metricType": "FASTING_GLUCOSE",
                    "date": currentDate,
                    "mealType": "BREAKFAST"
                },
                {
                    "metricType": "ONE_HOUR_POST_PRANDIAL",
                    "date": currentDate,
                    "mealType": "BREAKFAST"
                },
                {
                    "metricType": "ONE_HOUR_POST_PRANDIAL",
                    "date": currentDate,
                    "mealType": "LUNCH"
                },
                {
                    "metricType": "ONE_HOUR_POST_PRANDIAL",
                    "date": currentDate,
                    "mealType": "DINNER"
                }
            ]
        })
    })

    // if(response.status == API_STATUS_CODES.ANAUTHORIZED || response.status == API_STATUS_CODES.FORBIDDEN)
    // {
    //     navigate("/login")
    // }
    const data = await response.json();

    setState({postPrandial : data, isLoading: false});
    
    }
    catch(e)
    {
        setState({...state});
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            api: '/dashboard/glucoseMetrics',
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export default {
    GET_PRE_PRANDIAL : getPrePrandial,
}