const REGION = "us-west-1"; // Change this to your AWS region
const LOG_GROUP_NAME = "renewrx-dashboard-logs";
const EXCEPTION_LOG_STREAM_NAME = "ExceptionLogStream";
const API_LOG_STREAM_NAME = "ApiLogStream";
const EXCEPTION_LOG_STREAM_NAME_SANDBOX = "ExceptionLogStreamSandbox";
const API_LOG_STREAM_NAME_SANDBOX = "ApiLogStreamSandbox";

const LOG_STREAM_TYPE = {
    API_LOG_STREAM: "API_LOG_STREAM",
    EXCEPTION_LOG_STREAM: "EXCEPTION_LOG_STREAM"
};

export {
    REGION,
    LOG_GROUP_NAME,
    EXCEPTION_LOG_STREAM_NAME,
    API_LOG_STREAM_NAME,
    EXCEPTION_LOG_STREAM_NAME_SANDBOX,
    API_LOG_STREAM_NAME_SANDBOX,
    LOG_STREAM_TYPE
}