import { isNaN } from "lodash";
import { BASE_URL } from "../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../constants/keyConstants"
import { PLACE_TO_TIME_ZONE_MAPPING } from "../../fieldMapping/date";
import { getFormattedUserDetails, getRespectivePhoneNumber, getUpdateToLocalStorage } from "./userSettings.helper";
import { logToCloudWatch } from "../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";

const getInitialAction = async({state, setState, payload}) =>{
    try{
        setState((prev)=>({...prev, isLoading : true}));
        const response = await fetch(`${BASE_URL}/api/dashboard/users/getCareProAccount`,{
            method: "GET",
            headers:{
                "content-type" : "application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
            },
        });

        const data = await response.json();

        getUpdateToLocalStorage(data);
        
        window.dispatchEvent(new Event("storageUpdate"))

        setState((prev)=>{
            return {
                ...prev, userDetails : getFormattedUserDetails(data), isLoading : false,
            }
        })
    }
    catch(e){        
        setState((prev)=>{
            return {
                ...prev,
                error :e , isLoading : false,
            }
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getPasswordChangeAction = async({state, setState, payload})=>{
    try{
        setState((prev)=>({...prev, isLoading : true}));
        const response = await fetch(`${BASE_URL}/api/dashboard/users/changeUserPassword`,{
            method : "POST",
            headers :{
                "content-type" : "application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
            },
            body : JSON.stringify(payload)
        });

        const data = await response.json();

        setState((prev)=>{
            return {
                ...prev,
                displayModal: false, isLoading : false,
            }
        })
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error :e ,
                displayModal: false, isLoading : false,
            }
        });
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

const getOptionChangeAction = async({state, setState, payload}) =>{
    try{
        const {currentTabOption = ""} = state;
        
        setState((prev)=>{
            return {
                ...prev,
                currentTabOption : payload
            }
        })
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error :e ,
            }
        })
    }
}

const getModalDisplayAction = ({state, setState, payload}) =>{
    try{
            setState((prev)=>{
                const {displayModal = false}= prev;
                return {
                    ...prev,
                    modalContentType : payload,
                    displayModal: !displayModal
                }
            })
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error :e ,
            }
        })
    }
}

const getChangeInfoAction = async({state, setState, payload})=>{
    try{

        const {userDetails={}} = state;

        const { timezoneOffsetHours , ...restUserDetails } = userDetails || {};
        const { timezoneOffsetHours : payloadTimeZone , ...payloadUserDetails} = payload;

        const timeZone = isNaN(+PLACE_TO_TIME_ZONE_MAPPING[payloadTimeZone?.[0]])==true ? +timezoneOffsetHours : +parseFloat((+PLACE_TO_TIME_ZONE_MAPPING[payloadTimeZone?.[0]])/60).toFixed(2);

        const phoneNumber = getRespectivePhoneNumber(payload)

        const requestBody ={
            ...restUserDetails,
            ...payloadUserDetails,
            phone : phoneNumber,
            workEmail : payload?.workEmail?.toLowerCase() || userDetails?.workEmail?.toLowerCase(),
            timezoneOffsetHours : timeZone,
        }

        setState((prev)=>({...prev, isLoading : true}));

        await fetch(`${BASE_URL}/api/dashboard/users/partialUpdateCareProAccount`,{
            method : "PUT",
            headers :{
                "content-type" : "application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION)
            },
            body : JSON.stringify(requestBody),
        })

        getInitialAction({state, setState,payload})

        setState((prev)=>{
            return {
                ...prev,
                displayModal: false, isLoading : false,
            }
        })

    }
    catch(e)
    {

        setState((prev)=>({
            ...prev,
            error : e,
            displayModal: false, isLoading : false,
        }));
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export default {
    INIT_ACTION : getInitialAction,
    CHANGE_PASSWORD : getPasswordChangeAction,
    HANDLE_OPTION_CHANGE : getOptionChangeAction,
    DISPLAY_MODAL : getModalDisplayAction,
    CHANGE_INFORMATION : getChangeInfoAction
}