import React from "react";

import exclamationIcon from "./../../../../../../../../../../../assets/images/exclamation/purpleExclamation.svg"

import styles from "./activeBillingBanner.module.css"

const ActiveBillingBanner = () =>{
    return (
        <div className={styles.container}>
            <img src={exclamationIcon} className={styles.icon} />
            <span className={styles.contentTypo}>Billing Cycle in Progress - We recommend to bill this after the billing cycle is closed</span>
        </div>
    )
}

export default ActiveBillingBanner