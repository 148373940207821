import React, { useMemo, useState } from "react";
import cx from "classnames";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import filledCalender from "./../../../../../../../assets/images/calender/filledCalender.svg";
import emptyIcon from "./../../../../../../../assets/images/empty_notification.svg";
import SideDrawer from "../../../../../../molecules/sideDrawer";

import Header from "./components/consolidatedBillDrawer/Header";
import Body from "./components/consolidatedBillDrawer/Body";
import BillingPopup from "./components/BillingPopup";
import BillingList from "./components/BillingList/BillingList";

import { handleAddBillingOption, handleGetBillToBilled } from "./billing.async";
import Tooltip from "./components/Tooltip";
import { getBillingActiveStatus, getConsolidatedDrawerDates } from "./billing.helpers";

import styles from "./billing.module.css";

const Billing = ({
        billingList=[],
        handleGetBillingListAction,
        handleToggleLoaderAction,
        codeBasedBilling
    })=>{

    const [displayBillingPopup, setDisplayBillingPopup] = useState(false);
    const [openTooltip, setTooltipDisplay] = useState(false);
    const [displayConsolidatedBill, setDisplayConsolidatedBill] = useState(false);

    const nextBillingDate = useMemo(()=>{
        if(billingList.length==0){
            return 0;
        }
        console.log(billingList[billingList.length-1],"   ",billingList[0]?.["nextBillingDate"])
        return billingList[0]?.["nextBillingDate"]
    },[billingList]);

    const handleToggleConsolidatedBillDrawer = () =>{
        setDisplayConsolidatedBill(!displayConsolidatedBill);
    }

    const billingActiveStatus = useMemo(()=>{
        if(billingList.length==0){
            return false;
        }
        return getBillingActiveStatus(billingList[0]?.["nextBillingDate"]);
    },[billingList, getBillingActiveStatus])

    const handleToggleBillingPopup = ()=>{
        if(!billingActiveStatus){
            setDisplayBillingPopup(!displayBillingPopup);
        }
    }

    const handleOpenTooltip = ()=>{
        if(billingActiveStatus){
            setTooltipDisplay(true);
        }
    }

    const handleCloseTooltip = ()=>{
        if(billingActiveStatus){
            setTooltipDisplay(false);
        }
    }

    const handleMoveBillToBilled = (details)=>{
        handleToggleLoaderAction({isLoading :true});
        handleGetBillToBilled(details)
        .then((status)=>{
            console.log('STATUS : ', status)
            handleGetBillingListAction();
        })
        .catch(()=>{
            console.log('ERROR IS : ',e);
        })
        .finally(()=>{
            handleToggleLoaderAction({isLoading :false});
        })
    }

    const handleSubmitBillingPopup = (items)=>{
        handleToggleLoaderAction({isLoading :true});
        handleAddBillingOption(items)
        .then(()=>{
            setDisplayBillingPopup(false);
            handleGetBillingListAction();
        })
        .catch(()=>{
            console.log('ERROR IS : ',e);
        })
        .finally(()=>{
            handleToggleLoaderAction({isLoading :false});
        })
    }

    return (
        <div className={styles.container}>
            {displayBillingPopup &&  <BillingPopup handleSubmitBillingPopup={handleSubmitBillingPopup} handleModalToggle={handleToggleBillingPopup}/>}
            {displayConsolidatedBill && 
                <SideDrawer
                    HeaderComponent={Header}
                    BodyComponent={Body}
                    toggleDrawer={handleToggleConsolidatedBillDrawer}
                    bodyProps={{
                        billingList : codeBasedBilling,
                        handleMoveReadyToBill : handleMoveBillToBilled
                    }}
                    sliderWindowClassName={styles.sliderWindowClassName}
                    headerProps={getConsolidatedDrawerDates()}
                />
            }
            <div className={styles.billingContainer}>
                <div className={styles.billingHeader}>
                    <div className={styles.leftHeaderContainer}>
                        <div className={styles.headerPrimaryTextTypo}>
                            <span className={styles.headerPrimaryLabel}> Recent Billing</span>
                            <span className={styles.betaTagContainer}> Beta </span>
                        </div>
                        <div className={styles.headerSecondaryTextTypo}>Recent bills that are pushed to EHRs</div>
                    </div>
                    {
                        <div className={styles.rightContainer}>
                            <div className={cx(styles.rightTextButton, {[styles.nonActive]: billingActiveStatus})} onClick={handleToggleBillingPopup} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
                                Add New Bill
                            </div>
                            {openTooltip && <Tooltip text={'Billing Cycle already utilized Fully'} />}
                        </div>
                    }
                </div>
                <div className={styles.billingBody}>
                    { billingList?.length ==0 &&  codeBasedBilling?.length==0 &&
                        <div className={styles.noDataContainer}>
                            <img src={emptyIcon} className={styles.emptyIcon}/>
                            <div className={styles.noContent}>
                                No Bills Added Yet!
                            </div>
                        </div>
                    }
                    {
                        codeBasedBilling?.length>0 && moment().format("DD")>="01" && moment().format("DD")<="20" &&
                        <div className={styles.nextBillingContainer}>
                            <div className={styles.consolidatedBillingLeftContainer}>
                                <img src={filledCalender} className={styles.calenderIcon}/>
                                <div className={styles.nextBillingTextContainer}>
                                    <span>Consolidated Bill for {moment().subtract(1,'M').format("MMM")} 01 - {moment().subtract(1,'M').format("MMM")} {moment().daysInMonth()}, {moment().format("YYYY")}</span>
                                </div>
                            </div>
                            <div className={styles.consolidatedBillButton} onClick={handleToggleConsolidatedBillDrawer}>
                                View
                            </div>
                        </div>
                    }
                    <BillingList
                        listItems={billingList}
                        codeBasedBilling={codeBasedBilling}
                        handleMoveReadyToBill={handleMoveBillToBilled}
                        handleToggleLoaderAction={handleToggleLoaderAction}
                    />
                </div>
            </div>
        </div>
    )
}

export default Billing;