import { Resolution, Margin } from "react-to-pdf";

const BILLING_PDF_OPTIONS = {
    resolution : Resolution.MEDIUM,
    page :{
        margin : Margin.SMALL,
        orientation: 'portiat',
    },
    filename : "bill.pdf"
}

export {
    BILLING_PDF_OPTIONS
}