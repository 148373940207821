import React from "react"

import { NOTES_APPROVAL_STATS } from "./body.constants";

import styles from "./body.module.css";

const getNotesApprovalTabStyles = (type)=>{
    switch(type){
        case "WAITING_FOR_APPROVAL" :
            return styles.yellowTab;
        default:
            return styles.greenTab;
    }
}

const getApprovalLabelTag = (noteApprovalStatus)=>{
    return (
        <span className={`${styles.approvalContainer} ${getNotesApprovalTabStyles(noteApprovalStatus)}`}>{NOTES_APPROVAL_STATS[noteApprovalStatus]}</span>        
    )
}


const getSmartNotesApprovalLabel = (label,noteApprovalStatus) => {
    return (
        <div className={styles.smartNoteApprovalContainer}>
            <span className={styles.smartNoteLabel}>{label}</span>
            {getApprovalLabelTag(noteApprovalStatus)}
        </div>
    )
}

export {
    getSmartNotesApprovalLabel,
    getApprovalLabelTag
}