import React,{ forwardRef, useEffect, useState } from 'react';

import dropdownIcon from "./../../../../../assets/images/dropdown_normal.svg";

import styles from "./userProfile.module.css"

const UserProfile = (props, ref) =>{   // fire the event manually to handle to local storage based updates

    const [userName , setUserName ] = useState((window?.localStorage?.getItem("firstName") || "")+" "+ ( (window?.localStorage?.getItem("middleName") && window?.localStorage?.getItem("middleName").length>0) ? window?.localStorage?.getItem("middleName")+" " : "") + (window?.localStorage?.getItem("lastName") || ""));

    useEffect(()=>{
        const updateStorage = () => {
            setUserName((window?.localStorage?.getItem("firstName") || "")+" "+ ( (window?.localStorage?.getItem("middleName") && window?.localStorage?.getItem("middleName").length>0) ? window?.localStorage?.getItem("middleName")+" " : "") + (window?.localStorage?.getItem("lastName") || ""));
        };
      
        window.addEventListener("storageUpdate", updateStorage);
        return () => window.removeEventListener("storageUpdate", updateStorage);
    },[])

    const {toggleDropdown=()=>{}} = props;

    return (
        <div className={styles.container} ref={ref}  onClick={toggleDropdown}>
            <img src={require("./../../../../../assets/images/male_icon.svg").default} className={styles.profileAvatar}/>
            <div className={styles.textContainer}>
                <div className={styles.userHeader}>
                    {userName}
                </div>
            </div>
            <img src={dropdownIcon} className={styles.dropdownIcon}/>
        </div>
    )
}

export default forwardRef(UserProfile);