import React, { useState } from "react";
import cx from "classnames";

import styles from "./checkbox.module.css";

const CheckBox = ({label,content,type, handleItemSelectOption})=>{
    
    const [isChecked, setIsChecked]= useState(true);

    const handleCheckUpdate = ()=>{
        if(isChecked){
            handleItemSelectOption(-1);
        }
        else{
            handleItemSelectOption(1);
        }
        setIsChecked(!isChecked);
    }

    return (
        <div className={styles.container}>
            <input type={type} onClick={handleCheckUpdate} checked={isChecked} className={cx(styles.checkBox)} />
            <div className={styles.textContainer}>
                <div className={styles.primaryText}>{label}</div>
                <div className={styles.secondaryText}>{content}</div>
            </div>
        </div>
    )
}

export default CheckBox;