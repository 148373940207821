import React from "react";

import { map } from "lodash";

import styles from "./listComponent.module.css";

const ListComponent = ({
        listItems = []
    }) =>{
    return (
        <div className={styles.container}>
            {map(listItems,(listItem)=>{
                const { label="" , content="", className="", labelClass="", contentClass=""} = listItem || {};
                return(
                    <div className={`${styles.listItem} ${className}`}>
                        <span className={`${styles.content} ${labelClass}`}>{label}</span>
                        <span className={`${styles.content} ${contentClass}`}>{content}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default ListComponent