import React from "react";
import DailyGlucoseChart from "../../../../../../charts/patientProfile/overView/DailyGlucoseChart";

import styles from "./glucoseDataChart.module.css";
import ErrorBoundary from "../../../../../../helpers/ErrorBoundary";

const GlucoseDataChart = ({glucoseChartData, mealLogs, formattedMealLogs, glucoseData, offset=0})=>{
    return (
        <>
            <div className={styles.horizontalDivider} />
            <ErrorBoundary>
                <DailyGlucoseChart
                    comparisonAnalysisData={glucoseChartData}
                    date={Object?.keys(glucoseData)[0]}
                    mealLogs={formattedMealLogs}
                    offset={offset}
                    originalMealLogs={mealLogs}
                />
            </ErrorBoundary>
        </>
    )
}

export default GlucoseDataChart