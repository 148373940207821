import React from "react";
import { map , forEach} from "lodash";

import ToggableTab from "../../../ToggableTab";
import OuterBorderedButton from "../../../../../../../../../../atoms/buttons/OuterBorderedButton";
import ActiveBillingBanner from "../activeBillingBanner";

import styles from "./body.module.css";

const Body = ({
        drawerTabs,
        billingData={},
        handleMoveReadyToBill
    }) =>{

    const { billingCycleStartDate, billingCycleEndDate, billingType, billingStatus, currentMonth } = billingData;

    const handleMoveReadyToBillToBilled = ()=>{
        if(Array.isArray(billingType)){
            forEach(billingType,(item)=> handleMoveReadyToBill({
                    startDate : billingCycleStartDate,
                    endDate : billingCycleEndDate,
                    billingType : item
                })
            );
            return;
        }
        handleMoveReadyToBill({
            startDate : billingCycleStartDate,
            endDate : billingCycleEndDate,
            billingType
        })
    }

    return (
        <>
            {currentMonth && <ActiveBillingBanner />}
            <div className={styles.container}>
                {map(drawerTabs,({TabComponent=ToggableTab, ...tabProps})=>{
                    return <TabComponent {...tabProps} billingData={billingData} />
                })}
            </div>
            <div className={styles.footerContainer}>
                <span className={styles.billingLabelText}>Billing Status</span>
                { billingStatus!="READY_TO_BILL" && billingStatus?.length>0 && <span className={styles.billingContentText}>{billingStatus[0]?.toUpperCase()+billingStatus?.substring(1, billingStatus?.length)?.toLowerCase()}</span>}
                { billingStatus=="READY_TO_BILL" && <OuterBorderedButton onClick={handleMoveReadyToBillToBilled} content={"Ready To Bill"}/>}
            </div>
        </>
    )
}

export default Body;