import moment from "moment";

import { BASE_URL } from "../../../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/keyConstants";
import { LOG_STREAM_TYPE } from "../../../../../../constants/awsLogs.constants";
import { logToCloudWatch } from "../../../../../../configs/awsLogs";

const handleAddBillingOption = async({items,date})=>{
    try{

        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");

        const concatenatedItem = items?.[0]?.split(" ");

        const itemType = [concatenatedItem?.[0],concatenatedItem?.[2],concatenatedItem?.[3]].join("_").toUpperCase();

        const request = await fetch(`${BASE_URL}/api/dashboard/users/addNewBill`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify({
                billingDate : moment(date).format("YYYY-MM-DD"),
                careProUserId : window?.localStorage?.getItem("id"),
                userId : pathname?.[2],
                type : itemType,
            })
        });

        return request.status;
    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            api: '/api/dashboard/users/addNewBill',
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        console.log(e);
    }
}

const handleGetBillToBilled = async({
        startDate,
        endDate,
        billingType
    }) => {
    try{

        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");

        const request = await fetch(`${BASE_URL}/api/dashboard/careProUserNotes/billed/${pathname?.[2]}/${startDate}/${endDate}/${billingType}`,{
            method:"PATCH",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
        });

        return request.status;
    }
    catch(e){
        console.log(e);
    }

}

export {
    handleAddBillingOption,
    handleGetBillToBilled
}