import React, { useRef, useState } from "react";
import moment from "moment";
import {map} from "lodash";

import generatePDF from "react-to-pdf";

import billingIcon from "./../../../../../../../../../../../assets/images/tick/circledTick.svg";
import greenTick from "./../../../../../../../../../../../assets/images/tick/greenBackgroundBased.svg";
import redCancel from "./../../../../../../../../../../../assets/images/cancel/redCancel.svg";

import { getDrawerTabs } from "./codeBasedBilling.tabs";
import { BILLING_STATUS_TYPES, BILLING_TAB_LABELS, BILLING_TYPES_ITEMS } from "./codeBasedBilling.constants";
import BillingDrawer from "../../../BillingDrawer/BillingDrawer";
import ProgressBar from "../../../../../../../../../../atoms/progressBar";
import { getDrawerHeaderContent } from "./codeBasedBilling.helper";
import { BILLING_PDF_OPTIONS } from "../../../../../../../../../../configs/pdfConfigs";

import styles from "./codeBasedBilling.module.css"

const STATUS_TYPES={
    'ONGOING' : styles.ongoing,
    "READY_TO_BILL" : styles.readyToBill,
    'NOT_BILLED' : styles.ongoing,
    "BILLED" : styles.readyToBill,
}

const CodeBasedBilling = (props)=>{

    const [displayDrawer, setDisplayDrawer] = useState(false);

    const [targetRef, setTargetRef] = useState(null) ;
    
    const {minutesUtilizedOutOf90="", billingCycle="", billingStatus="-", billingType = "", complianceCheck=[]} = props || {};

    const billingCode = billingType=="PCM" ? "CPT 99426, 99427" : "CPT 99457, 99458"

    const { handleMoveReadyToBill, handleToggleLoaderAction } = props;
    
    const handleToggleDrawer = ()=>{
        setDisplayDrawer(!displayDrawer);
    }

    const handleUpdateTargetRef = (ref)=>{
        setTargetRef(ref);
    }

    const handleGeneratePDF =()=>{
        handleToggleLoaderAction({isLoading : true});
        generatePDF(targetRef,BILLING_PDF_OPTIONS)
        .catch((e)=>{
            console.log('ERROR : ',e);
            handleToggleLoaderAction({isLoading : false});
        })
        .finally(()=>{
            handleToggleLoaderAction({isLoading : false});
        })
    }

    const drawerTabs = getDrawerTabs({...props, billingCode}, BILLING_TAB_LABELS, handleGeneratePDF, handleUpdateTargetRef);

    return (
        <>
            {
                displayDrawer && 
                <BillingDrawer
                    drawerTabs={drawerTabs}
                    toggleDrawer={handleToggleDrawer}
                    billingData={{...props, billingCode}}
                    headerProps={getDrawerHeaderContent(billingType)}
                    handleMoveReadyToBill={handleMoveReadyToBill}
                />
            }
            <div className={styles.listItemContainer} onClick={handleToggleDrawer}>
                <div className={styles.iconContain}>
                    <img src={billingIcon} className={styles.billingIcon} />
                </div>
                <div className={styles.rightItemContainer}>
                    <div className={styles.rightTopContainer}>
                        <div className={styles.mainText}>{BILLING_TYPES_ITEMS[billingType] || "-"}</div>
                        <div className={styles.progressBarContainer}>
                            {map(complianceCheck,({
                                rangeEnd,
                                rangeStart,
                                pendingMins,
                                utilized
                            })=>{
                                return (
                                    <img src={utilized ? greenTick : redCancel} className={styles.isPendingIcon} />
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.rightBottomContainer}>
                        <div className={styles.textOuterContainer}>
                            <div className={styles.nonHighlightedText}>Billing Cycle</div>
                            <div className={styles.listItemBaseContent}>{billingCycle}</div>
                        </div>
                        <div className={styles.textOuterContainer}>
                            <div className={styles.nonHighlightedText}>Utilized Time</div>
                            <div className={styles.listItemBaseContent}>{minutesUtilizedOutOf90}</div>
                        </div>
                        <div className={styles.textOuterContainer}>
                            <div className={styles.nonHighlightedText}>Status</div>
                            <div className={`${styles.statusType} ${STATUS_TYPES[billingStatus]}`}>{BILLING_STATUS_TYPES[billingStatus]}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CodeBasedBilling