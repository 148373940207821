import { CLINICAL_NOTES_SECTIONS_NAMES } from "../ClinicalNotes/clinicalNotes.constants";

const getTotalTime = (data)=>{
    let totalTime = 0;
    Object.keys(CLINICAL_NOTES_SECTIONS_NAMES).forEach((key)=>{
        totalTime +=(data?.[key]?.isActive ? data?.[key]?.timeSpent : 0);
    })
    return totalTime
}

export {
    getTotalTime,
}