import {map} from "lodash";

import { getCodeGptColumnCoponent } from "./complianceDetailsTable.components";

import { TABLE_COLUMNS } from "./complianceDetailsTable.constants";

import styles from "./complianceDetailsTable.module.css"

const getCorrespondingTableColumns = (name, label, ...content) =>{
    switch(name){
        case "CPT_CODE" : 
            return {
                label : getCodeGptColumnCoponent(label, ...content)
            }
    }
    return {
        label : label || ""
    }
}

const getTableColumns = (handleToggleDisplayData, displayData)=> {
    return map(Object.keys(TABLE_COLUMNS),(key, index)=>{
        return {
            id : index,
            name : key,
            innerClassName : styles.columnTypoInnerClass,
            className : styles.topHeaderBlock,
            itemClassName : styles.columnTypoInnerClass,
            ...getCorrespondingTableColumns(key, TABLE_COLUMNS[key],handleToggleDisplayData, displayData)
        }
    })
}

export {
    getTableColumns
}