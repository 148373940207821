import React from "react";

import {map} from "lodash";

import styles from "./ItemBlock.module.css"

const ItemBlock = ({
        listItems=[]
    }) =>{
    return (
        <div className={styles.container}>
            {map(listItems,({
                label,
                value,
                valueClass="",
                labelClass=""
            })=>{
                return (
                    <div className={styles.rowItem}>
                        <div className={`${styles.itemBlock} ${styles.itemBlockLabel} ${labelClass}`}>
                            {label}
                        </div>
                        <div className={`${styles.itemBlock} ${valueClass}`}>
                            {value}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ItemBlock