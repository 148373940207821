import React, { useEffect, useMemo } from "react";
import cx from "classnames";

import CancelComponent from "./components/CancelComponent/CancelComponent";

import styles from "./sideDrawer.module.css";

const SideDrawer = ({
        containerClassName="",
        sliderWindowClassName="",
        HeaderComponent = ()=><></>,
        BodyComponent = ()=><></>,
        headerProps={},
        bodyProps={},
        toggleDrawer= ()=>{},
        icon
    }) => {

    useEffect(()=>{
        document.body.style['overflowY'] = "hidden";
          
        return () => {
            document.body.style['overflowY'] = "scroll";
        };
    },[])

    const CloseComponent = useMemo(()=>{
        return ()=> <CancelComponent icon={icon} toggleDrawer={toggleDrawer} />
    },[icon,toggleDrawer]);

    return (
        <div className={cx(styles.container,containerClassName)}>
            <div className={cx(styles.sliderWindowContainer,sliderWindowClassName)}>
                <HeaderComponent 
                    {...headerProps}
                    CancelComponent={CloseComponent}
                />
                <BodyComponent 
                    {...bodyProps} 
                />
            </div>
        </div>
    )
}

export default SideDrawer;