import moment from "moment"
import { BILLING_TYPES } from "../../../../patientProfile.constants";

const getBillingCodeProgess = (notesBasedBilling=[], billingCode, dateOfReview)=>{
    const currentMonth = moment(dateOfReview).format("MM");
    if(!Array.isArray(notesBasedBilling)){
        return [];
    }
    let codeBasedComplianceType;
    notesBasedBilling?.forEach(({
        billingCycleStartDate="",
        billingType="",
        complianceCheck
    })=>{
        const [ _ ,month,__] = billingCycleStartDate.split("-");
        if(currentMonth==month && BILLING_TYPES[billingCode]==billingType){
            codeBasedComplianceType=complianceCheck;
        }
    });
    return codeBasedComplianceType;
}

export {
    getBillingCodeProgess,
}