import React from "react"

import greenCircleTick from "./../../../../../../../../../assets/images/tick/greenCircle.svg";
import redCross from "./../../../../../../../../../assets/images/tick/redCross.svg"

import styles from "./billingList.module.css"

const getDropdownLabel = (label, checked=true,labelSuffix,icon)=>{
    return (
        <div className={styles.dropdownLabelContainer}>
            <img src={icon} className={styles.tickIcon} />
            <span>
                <span className={styles.dropDownLabel}>
                    {label+" "}
                </span>
                <span className={styles.suffixContentTypo}>
                    {labelSuffix}
                </span>
            </span>
        </div>
    )
}

const getMarkUpIconContent = (content="",className="")=>{
    return (
        <div className={`${styles.markUpContainer} ${className}`}>
            {content}
        </div>
    )
}

const getBilledOnStatus = (value)=>{
    return (
        <div className={`${styles.billedTypo} ${value=="BILLED" ? styles.billedBill : ""} `}>
            {value=="BILLED" ? "Billed" : "Unbilled"}
        </div>
    )
}

const getDropdownContent = (data, suffix) =>{
    return (
        <div className={styles.dropDownContent}>
            <span>{data}</span>
            <span className={styles.suffixContentTypo}>{suffix}</span>
        </div>
    )
}

export {
    getDropdownContent,
    getDropdownLabel,
    getMarkUpIconContent,
    getBilledOnStatus
}