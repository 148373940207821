import React, { useMemo } from "react";
import cx from "classnames";

import { getRespectiveCoachRoleState } from "./patientNameBlock.helpers";

import styles from "./patientNameBlock.module.css";

const PatientNameBlock = ({label = "",data = {}, className = "",activeRow}) =>{
    const { user, needsApproval , anyNotesInCurrentMonth  } = data || {};

    const userRole = useMemo(()=>{
        return window.localStorage.getItem("UserRole")
    },[]);

    const { displayTag = false, displayApprovalTag=false, isBiller = false, isCoach=false } = getRespectiveCoachRoleState(userRole, needsApproval, anyNotesInCurrentMonth);

    return (
    <div className={cx(styles.container,{[styles.activeRow]: activeRow})}>
        <div>
            {label || ""} {user || ""}
        </div>
        
        <div className={styles.tagsContainer}>
            {displayApprovalTag &&  <div className={cx(styles.mainOuterLabel,{[styles.needApproval]: isCoach, [styles.readyToBill]: isBiller})}>
                    { isCoach && needsApproval && "Needs Approval"}
                </div>}
            { displayTag &&
                <div className={cx(styles.mainOuterLabel,{[styles.needApproval]: isCoach, [styles.readyToBill]: isBiller})}>
                    { isBiller && anyNotesInCurrentMonth && "Ready To Bill"}
                </div>
                }
        </div>
    </div>
    )
}

export default PatientNameBlock;