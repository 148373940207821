import { map, forEach } from "lodash"
import moment from "moment";

import { TABLE_COLUMNS } from "./complianceDetailsTable.constants";
import { getApprovalStatus, getGptCodeWrapper, getStatusFieldWrapper } from "./complianceDetailsTable.components";

const getComplianceTableData = ({
        complianceCheck=[],
        billingType
    }) => {
    
    return map(complianceCheck,({
        utilized,
        date,
        pendingMins,
        rangeEnd,
        rangeStart,
        billType
    }, index)=>{
        return {
            CPT_CODE : {
                code: index==0 ? ((billingType=="PCM" || billType=="PCM") ? 99426 : 99457) : ( (billingType=="PCM" || billType=="PCM") ? 99427 : 99458),
                utilized
            },
            STATUS : {
                utilized,
                pendingMins,
                totalMins : (rangeEnd-rangeStart+1)
            },
            CRITERIA_MET_ON : utilized ? moment(date,"YYYY-MM-DD").format("MMM DD, YYYY") : "-",
            ENCOUNTER_ID : utilized ? 59711 : "-",
            APPROVED : utilized
        }
    })
    
}

const getWrapperAssigner = (key, wrapperFields=[])=>{
    switch(key){
        case "CPT_CODE" : 
            wrapperFields.push({
                label : key,
                field : getGptCodeWrapper
            })
            break;
        case "STATUS" :
            wrapperFields.push({
                label : key,
                field : getStatusFieldWrapper
            })
            break;
        case "APPROVED" :
            wrapperFields.push({
                label : key,
                field : getApprovalStatus
            })
            break;
    }
}

const getRespectiveRowWrapper = () => {
    const wrapperFields=[];
    forEach(Object.keys(TABLE_COLUMNS),(key)=>{
        getWrapperAssigner(key, wrapperFields);
    });
    return wrapperFields
}

export {
    getComplianceTableData,
    getRespectiveRowWrapper
}