const TIME_FIELDS = [{
        name : "billingStartTime",
        label : "Start Time"
    },
    {
        name : "billingEndTime",
        label : "End Time"
    }
];

export {
    TIME_FIELDS
}