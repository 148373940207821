import React from "react";

import styles from "./outerBorderedButton.module.css";

const OuterBorderedButton = ({
        content,
        ...rest
    }) =>{
    return (
        <div className={styles.container} {...rest}>
            <div className={`${styles.innerContainer} ${styles.contentTypo}`}>
                {content}
            </div>
        </div>
    )
}

export default OuterBorderedButton;