import React, { useEffect, useRef } from "react";
import {usePdf} from "react-to-pdf";

import UserDetails from "./components/UserDetails";
import ActiveBillingBanner from "../../../BillingDrawer/components/activeBillingBanner";

import GptCodeSummary from "./components/GptCodeSummary";
import DetailSections from "./components/DetailSections";

import styles from "./autoGeneratedBill.module.css"

const AutoGeneratedBill = ({
        handleUpdateTargetRef,
        ...rest
    })=>{

    const pdfRef= useRef();

    useEffect(()=>{
        handleUpdateTargetRef(pdfRef)
    },[]);

    return (
        <div className={styles.outerContainer}>
            {rest?.billingData?.currentMonth && <ActiveBillingBanner />}
            <div className={styles.container} ref={pdfRef}>
                <UserDetails userName={rest?.billingData?.billingWorkSheet?.patientName || ""} />
                <GptCodeSummary {...rest}/>
                <DetailSections {...rest} />
            </div>
        </div>
    )
}

export default AutoGeneratedBill