import React from "react"

import greenTick from "./../../../../../../../../../../../assets/images/tick/greenBackgroundBased.svg";
import redCross from "./../../../../../../../../../../../assets/images/cancel/circledRedCancel.svg";

import upwardIcon from "./../../../../../../../../../../../assets/images/arrow/active/basicUpwardArrow.svg";
import downwardIcon from "./../../../../../../../../../../../assets/images/arrow/active/basicDownArrow.svg"

import styles from "./complianceDetailsTable.module.css";

const getCodeGptColumnCoponent = (
        label,
        handleToggleDisplayData,
        displayData
    ) =>{
    return (
        <div className={styles.toggleColumnContainer}>
            <img src={!displayData ? downwardIcon :upwardIcon} className={styles.toggleIcon} onClick={handleToggleDisplayData}/>
            <span className={styles.columnTypoInnerClass}>{label}</span>
        </div>
    )
}

const getGptCodeWrapper = ({
        data
    }) => {
    return (
        <div className={styles.toggleColumnContainer}>
            <img src={data?.utilized ? greenTick : redCross} className={styles.toggleIcon}/>
            <span className={styles.columnInnerTypo}>{data?.code}</span>
        </div>
    )    
}

const getStatusFieldWrapper = ({
        data
    })=>{
    return (
        <div className={styles.statusOuterContainer}>
            <div className={`${styles.statusContainer} ${data?.utilized ? styles.utilized: styles.nonUtilized}`}>
                {data?.utilized ? "Utilized" : "Not Utilized"}
            </div>
            {!data?.utilized && <div className={styles.statusRange}>{(data?.pendingMins || "0")+"/"+(data?.totalMins || "30")}</div>}
        </div>
    )
}

const getApprovalStatus = ({
        data
    }) => {
    if(!data){
        return ""
    }
    return (
        <div className={`${styles.statusContainer} ${data ? styles.utilized: styles.nonUtilized}`}>
            {data ? "Approved" : "Not Approved"}
        </div>
    )
}

export {
    getCodeGptColumnCoponent,
    getGptCodeWrapper,
    getStatusFieldWrapper,
    getApprovalStatus
}