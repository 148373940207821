import React from "react";

import styles from "./rrxNativeButton.module.css";

const RrxNativeButton = ({
        className="",
        contentClass="",
        content="",
        ...rest
    })=>{
    return (
        <div className={`${styles.container} ${className}`} {...rest}>
            <span className={`${styles.contentTypo} ${contentClass}`}>{content}</span>
        </div>
    )
}

export default RrxNativeButton