const DETAIL_SECTION_FIELDS = {
    DATE : "billingDate",
    TIME : "billingStartTime",
    STAFF : 'staffName',
    SERVICE_PERFORMED : "servicePerformed",
    PROVIDER_NOTIFIED : "providerNotified"
}

const DETAIL_SECTION_FIELDS_LABELS = {
    DATE : "Date",
    TIME : "Time",
    STAFF : 'Staff',
    SERVICE_PERFORMED : "Service Performed",
    PROVIDER_NOTIFIED : "Provider Notified"
}

const DETAILS_SUB_SECTIONS = {
    REFERENCE_DATA : "referenceData",
    ACTIONS_PLAN : "actionPlan",
    CLIENT_UPDATE : "clientUpdate"
}

const DETAIL_SECTION_BASE_NAMES = {
    DATE : "DATE",
    TIME : "TIME",
    STAFF : 'STAFF',
    SERVICE_PERFORMED : "SERVICE_PERFORMED",
    PROVIDER_NOTIFIED : "PROVIDER_NOTIFIED"
}

export {
    DETAIL_SECTION_FIELDS,
    DETAIL_SECTION_FIELDS_LABELS,
    DETAIL_SECTION_BASE_NAMES,
    DETAILS_SUB_SECTIONS
}
