import React from "react";
import moment from "moment";

import plusIcon from "./../../../../../../../../../../../../../../../../assets/images/sumSign/sum.svg"

import styles from "./activityLogsBody.module.css";

const ActivityLogsBody = ({
        data
    }) =>{
    
    const { createdAt ="", careProUserFullName=""} = data;

    return (
        <div className={styles.container}>
            <img src={plusIcon} className={styles.icon} />
            <div className={styles.primaryTextContainer}>
                <div className={styles.primaryText}>
                    <span className={styles.semiPrimaryText}>
                        {careProUserFullName}
                    </span>
                    {" created a Smart Note"}
                </div>
                <div className={styles.secondaryText}>
                    {moment(createdAt).format("ddd, MMM DD, YYYY")}
                </div>
            </div>
        </div>
    )

}

export default ActivityLogsBody