import React, { useEffect, useState, useRef } from 'react';
import {map} from "lodash";
import moment from 'moment';

import TableColumn from './components/TableColumn';
import TableContent from './components/TableContent/TableContent';
import NoData from './components/NoData/NoData';
import WeeklyFilter from './components/WeeklyFilter/WeeklyFilter';
import { useRecoilValue } from 'recoil';
import { startDateRecoil, endDateRecoil } from '../../../../../../../../../store/progressMonitoringAtoms/weeklyRange';
import { BASE_URL } from '../../../../../../../../constants/api';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { getBalancedPlateCards } from './balancedPlate.fields';
import LargeCards from '../../../../../LargeCards';
import sortIcon from "./../../../../../../../../../assets/images/sort/sort.svg"

import actions from "./balancedPlate.action";
import actionTypes from "./balancedPlate.actionTypes";

import withActionState from '../../../../../../../../hooks/withActionState';
import SecondaryHeader from './components/MealLogs/SecondaryHeader';
import Header from './components/Header/Header';
import CardHeader from './components/CardHeader/CardHeader';
import DailyMealLogs from './components/DailyMealLogs/DailyMealLogs';

import styles from "./balancedPlate.module.css";
import { logToCloudWatch } from '../../../../../../../../configs/awsLogs';
import { LOG_STREAM_TYPE } from '../../../../../../../../constants/awsLogs.constants';

function BalancedPlate({
    handleToggleLoaderAction=()=>{},
    dispatch,
    weeklyFilter,
    foodLogs,
    metricsData,
    ...restProps
  }) {
  const [loggedMeal, setLoggedMeal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [oneHourPP,setOneHourPP] = useState([]);
  const [balancedPlateData, setBalancedPlateData] = useState({});
  const [previous,setPrevious] = useState(0);

  const end = moment().subtract(previous,'d').format("YYYY-MM-DD");
  const start=end;

  const { id } = useParams();
  const debounceTimeoutRef = useRef(null);

  useEffect(()=>{
    dispatch({actionType: actionTypes.INIT_ACTION});
  },[])

  const handleFilterUpdate = (prev)=>{
    setPrevious(prev);
  }

  const getLoggedMeal = async () => {
    try {
      handleToggleLoaderAction({isLoading : true});
      setLoggedMeal([]);
      const response = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/${id}/${start}/${end}`, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + window.localStorage.getItem("Authorization")
        }
      });

      const oneHourPPResponse = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
        method:"POST",
        headers:{
            "content-type":"application/json",
            'authorization' : 'Bearer '+ window?.localStorage?.getItem("Authorization"),
        },
        body: JSON.stringify({
            userId: id,
            metricRequests:[
                {
                  metricType: "ONE_HOUR_POST_PRANDIAL",
                  mealType: 'BREAKFAST',
                  date : start
                },
                {
                    metricType: "ONE_HOUR_POST_PRANDIAL",
                    mealType: 'LUNCH',
                    date : start
                },
                {
                  metricType: "ONE_HOUR_POST_PRANDIAL",
                  mealType: 'DINNER',
                  date : start
                }
          ]
        })
    })

      const oneHourPPResponseMetrics = await oneHourPPResponse.json();
    
      const data = await response.json();

      
      const balancedPlateResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${id}/${start}/${end}`,{
        method:"GET",
        headers:{
            'content-type':"application/json",
            'authorization' : 'Bearer '+ window?.localStorage?.getItem("Authorization"),
        }
      });

      const balancedPlateData = await balancedPlateResponse.json();

      setBalancedPlateData(balancedPlateData)
      setOneHourPP(oneHourPPResponseMetrics?.metricsResponses)
      setLoggedMeal(data);

    } catch (err) {
      setLoggedMeal([]);
      logToCloudWatch({
        userId: window.localStorage.getItem("id"),
        componentStack: e.stack,
      }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    } finally {
      handleToggleLoaderAction({isLoading : false});
    }
  };

  const debounceGetLoggedMeal = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      getLoggedMeal();
    }, 500);
  };

  useEffect(() => {
    debounceGetLoggedMeal();
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [start, end]);

  const balancedPlateCards = getBalancedPlateCards();

  const getFilterChangeAction = (payload) =>{
    dispatch({actionType : actionTypes.FILTER_UPDATE_ACTION, payload});
  }

  return (
    <>
      <Header 
        getFilterChangeAction={getFilterChangeAction} 
        filterDuration={weeklyFilter?.current}
      />
      <div className={styles.outerContainer}>
        <div className={styles.mainContainer}>
            <DailyMealLogs
              metricsData={metricsData}
              mealLogs={foodLogs}
              startDate={weeklyFilter?.current}
            />
            {map(balancedPlateCards,(val)=>{
              return <LargeCards 
                        weeklyFilter={weeklyFilter} 
                        {...val} 
                        {...restProps} 
                        getFilterChangeAction={getFilterChangeAction} 
                      />          
            })}
        </div>
      </div>
    </>
  );
}

export default withActionState({Component : BalancedPlate, action: actions})