import { logToCloudWatch } from "../../../../configs/awsLogs";
import { API_STATUS_CODES, BASE_URL } from "../../../../constants/api";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";

const handleClick = async (navigate, password, handleIsLoading) => {
try{

    const urlParams = new URLSearchParams(window.location.search);
    handleIsLoading(true);

    let email = urlParams.get('email');
    
    if (email) {
        email = email.replace(/\s/g, '+');
    }

    const token = urlParams.get('token');

    window.localStorage.setItem("role",urlParams.get("role"));

    const request = await fetch(`${BASE_URL}/api/dashboard/users/create`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            password: password,
            token: token
        })
    });

    handleIsLoading(false);

    if (request.status === API_STATUS_CODES.SUCCES) {
        navigate("/login");
    }
}
catch(e){
    logToCloudWatch({
        userId: window.localStorage.getItem("id"),
        componentStack: e.stack,
      }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
}

}

export default handleClick;
