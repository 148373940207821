import moment from "moment";
import { BASE_URL } from "../../../../../../../../../../constants/api"
import { LOCAL_STORAGE_KEYS } from "../../../../../../../../../../constants/keyConstants";
import { logToCloudWatch } from "../../../../../../../../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../../../../../../../../constants/awsLogs.constants";

const getModalData = async({state, setState, payload}) =>{
    try{
        const {data={}}= state;

        const {meal="", date=""} = data;

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const respone = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                metricRequests:[
                    {
                        metricType: "ONE_HOUR_POST_PRANDIAL",
                        mealType: meal,
                        date,
                        // start_date: date,
                        // end_date: date
                    },
                    {
                        metricType: "TWO_HOUR_POST_PRANDIAL",
                        mealType: meal,
                        date
                        // start_date: date,
                        // end_date: date
                    },
                    {
                        metricType:'POST_PRANDIAL_HIGH_RATE',
                        mealType: meal,
                        date
                        // start_date: date,
                        // end_date: date
                    }
                ]
            })
        })

        const responseMetrics = await respone.json();

        const nourishmentNotebookResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/${pathname?.[2]}/${date}/${date}`, {
            method: "GET",
            headers: {
              "Authorization": "Bearer " + window.localStorage.getItem("Authorization")
            }
          });

        const nourishmentNotebookData = await nourishmentNotebookResponse.json();

        setState((prev)=>{
            return {
                ...prev,
                nourishmentNotebookData: nourishmentNotebookData?.filter((val)=> val?.meal_type_name?.toUpperCase() == meal),
                responseMetrics: responseMetrics?.metricsResponses,
            }
        })
    
    }
    catch(e){
        setState((prev)=>({...prev,error:e}));
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export default {
    LOAD_MODAL_DATA : getModalData,
}