import React from "react";
import {map} from "lodash";

import Label from "../../../../../../../../../../../../atoms/label/Label";
import { getRespectiveFieldSections } from "./detailSection.helpers";

import styles from "./detailSection.module.css";
import ItemBlockContainer from "./components/ItemBlockContainer/ItemBlockContainer";

const DetailSection = ({
        billingData={}
    })=>{

    const detailSections = getRespectiveFieldSections(billingData);

    return (
        <div className={styles.container}>
            {map(detailSections,(sectionDetails)=>{
                const {week, subSections} = sectionDetails;
                return (
                    <>
                        <Label 
                            label={week} 
                            className={`${styles.contentTableTypo} ${styles.weekLabel}`}
                        />
                        {map(subSections,(sectionItem)=>{
                            return <ItemBlockContainer {...sectionItem} />
                        })}
                    </>
                )
            })}
        </div>
    )
}

export default DetailSection;