const DATE_FORMATS={
    MONTH_DAY_YEAR : "MM-DD-YYYY",
    DAY_MONTH_FULLYEAR : "DD-MM-YYYY",
    YEAR_MONTH_DAY : "YYYY-MM-DD",
    SEMIMONTH_DAY_FULLYEAR : "MMM DD,YYYY",
    MONTH_DAY_FULLYEAR_SLASH_FORMAT : "MM/DD/YYYY",
    DATE_MONTH_FULLYEAR : "DD-MM-YYYY",
    MONTH_DATE_FULLYEAR_SLASH_FORMAT : "MM/DD/YYYY"
}

export {
    DATE_FORMATS
}