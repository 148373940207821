import AWS from "aws-sdk";
import { REGION, LOG_GROUP_NAME, LOG_STREAM_TYPE, API_LOG_STREAM_NAME, API_LOG_STREAM_NAME_SANDBOX, EXCEPTION_LOG_STREAM_NAME, EXCEPTION_LOG_STREAM_NAME_SANDBOX } from "../constants/awsLogs.constants";

// AWS Credentials (Use environment variables or IAM roles in production)
AWS.config.update({
  accessKeyId: 'AKIAUBQHMOFLLAL7AXUB', 
    secretAccessKey: 'oATtvPdigGzloVcjyuGHTaCb6GQ8jbPG8dC+Tg3P', // Replace with your AWS Secret Key
  region: REGION,
});

const cloudwatchlogs = new AWS.CloudWatchLogs();

async function ensureLogStream(logStreamName) {
  try {
    // Check if log stream exists
    await cloudwatchlogs.createLogStream({
      logGroupName: LOG_GROUP_NAME,
      logStreamName: logStreamName,
    }).promise();
  } catch (error) {
    if (error.code !== "ResourceAlreadyExistsException") {
      console.error("Error creating log stream:", error);
    }
  }
}

function getLogStreamName(logStreamType, baseUrl) {

  if (logStreamType === LOG_STREAM_TYPE.API_LOG_STREAM) {

    if (baseUrl === "https://api.renewrx.health") {
      return API_LOG_STREAM_NAME;
    } 
    else if (baseUrl === "https://api.sandbox.renewrx.health") {
      return API_LOG_STREAM_NAME_SANDBOX;
    }

  } 
  else if (logStreamType === LOG_STREAM_TYPE.EXCEPTION_LOG_STREAM) {
    
    if (baseUrl === "https://api.renewrx.health") {
      return EXCEPTION_LOG_STREAM_NAME;
    } 
    else if (baseUrl === "https://api.sandbox.renewrx.health") {
      return EXCEPTION_LOG_STREAM_NAME_SANDBOX;
    }

  }
}

export async function logToCloudWatch(message, logStreamType, baseUrl) {
  try {
    const logStreamName = getLogStreamName(logStreamType, baseUrl);

    console.log(logStreamName);

    await ensureLogStream();

    const logEvent = {
      logGroupName: LOG_GROUP_NAME,
      logStreamName: logStreamName,
      logEvents: [
        {
          message: JSON.stringify(message),
          timestamp: Date.now(),
        },
      ],
    };

    await cloudwatchlogs.putLogEvents(logEvent).promise();
    console.log("Logged to CloudWatch:", message);
  } catch (error) {
    console.error("Failed to log to CloudWatch:", error);
  }
}

