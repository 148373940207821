import React, { useCallback, useState } from "react";

import BaseTable from "../../../../../../../../../tables/BaseTable/BaseTable";
import { getTableColumns } from "./complianceDetailsTable.columns";
import { getComplianceTableData, getRespectiveRowWrapper } from "./complianceDetailsTable.helpers";

import styles from "./complianceDetailsTable.module.css"

const ComplianceDetailsTable = ({
        data,
        ...rest
    }) => {
    const [displayData, setDisplayData] = useState(true);

    const handleToggleDisplayData = useCallback(()=>{
        setDisplayData(!displayData);
    },[displayData, setDisplayData])

    const columnsData = getTableColumns(handleToggleDisplayData, displayData);

    const tableData = getComplianceTableData(data);

    const wrapperFields = getRespectiveRowWrapper();

    return (
        <div className={styles.container}>
            <BaseTable
                columnsData={columnsData}
                columnProps={{
                    className : `${styles.tableRow} ${styles.topHeaderColumn}`
                }}
                tableDataProps={{
                    className : styles.tableRow
                }}
                tableData={ displayData ? tableData : []}
                wrapperFields={wrapperFields}
            />
        </div>
    )
}

export default ComplianceDetailsTable