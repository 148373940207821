import React from "react";

import { TABLE_COLUMNS } from "./summaryTable.columns";
import BaseTable from "../../../../../../../../../../../../../tables/BaseTable/BaseTable";
import { DATA_TYPES } from "../../../../../../../../../../../../../../constants/dataTypes";
import { getSummaryTableData } from "./summaryTable.helpers";

import styles from "./summaryTable.module.css";

const SummaryTable = ({
        billingData=DATA_TYPES.EMPTY_OBJECT
    }) =>{

    const tableData = getSummaryTableData(billingData);

    return (
        <BaseTable 
            columnsData={TABLE_COLUMNS}
            tableData={tableData}
        />
    )

}

export default SummaryTable;