import { logToCloudWatch } from "../../../../../../configs/awsLogs";
import { API_METHOD_TYPES, BASE_URL } from "../../../../../../constants/api";
import { LOG_STREAM_TYPE } from "../../../../../../constants/awsLogs.constants";
import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/keyConstants";

const getPatientsList = async()=>{
    try{
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const practiceId = pathname?.[2] || "";

        const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getAddPatientsList/${practiceId}`,{
            method : API_METHOD_TYPES.GET,
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },            
        });

        const patientResponseList = await request.json();

        return patientResponseList?.addPatientsList;
    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        console.log('ERROR AS : ',e);
    }
}

const getAddPatientsAction = async(patientsList)=>{
    try{
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const practiceId = pathname?.[2] || "";

        const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/addPatients`,{
            method : API_METHOD_TYPES.POST,
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                practiceId,
                patientIdList : patientsList
            })
        });

        return request.status;
    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        console.log('ERROR IS : ',e);
    }
}

export {
    getAddPatientsAction,
    getPatientsList
}