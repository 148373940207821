import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";

import Body from "./components/Body";
import Footer from "./components/Footer";

import styles from "./dateCalender.module.css";

const DateCalender = ({
        startDate,
        handleDateUpdateAction,
        handleToggleCalenderDisplay,
        numberOfMonths,
        numberOfDays,
        bodyProps={},
        className=""
    })=>{
    
    const [currentStartDate , setCurrentStartDate] = useState(startDate);
    const [currentBack, setCurrentBack] = useState(0);

    useEffect(()=>{
        if(currentStartDate!=startDate){
            setCurrentStartDate(startDate)
        }
    },[startDate])

    const handleCurrentDateUpdate = useCallback((updatedDate)=>{
        setCurrentStartDate(updatedDate);
    },[])

    const handleUpdateDateToCurrentDateAction = useCallback(()=>{
        handleDateUpdateAction(currentStartDate);
        handleToggleCalenderDisplay();
    },[handleDateUpdateAction,currentStartDate, handleToggleCalenderDisplay]);

    return (
        <div className={cx(styles.container,className)}>
            <Body 
                startDate={currentStartDate}
                currentBack={currentBack}
                setCurrentBack={setCurrentBack}
                handleCurrentDateUpdate={handleCurrentDateUpdate}
                numberOfMonths={numberOfMonths}
                numberOfDays={numberOfDays}
                {...bodyProps}
            />
            <Footer 
                handleToggleCalenderDisplay={handleToggleCalenderDisplay} 
                handleDateUpdateAction={handleUpdateDateToCurrentDateAction}
            />
        </div>
    )
}

DateCalender.defaultProps={

}

export default DateCalender;