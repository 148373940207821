import React from "react";
import { USER_INFO } from "./UserDetails.constants";

import styles from "./userDetails.module.css"

const UserDetails = ({
        userName=""
    })=>{
        return(
            <div className={styles.container}>
                <div className={styles.textContainer}>
                    <div className={styles.primaryText}>
                        {userName}
                    </div>
                    <div className={styles.secondaryText}>
                        {USER_INFO}
                    </div>
                </div>
            </div>
        )
}

export default UserDetails;