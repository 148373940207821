import React, { useEffect, useRef } from "react";

import { ProgressBar } from "react-bootstrap";

import styles from "./progressBar.module.css"

const CustomProgressBar = ({
        color = '#ffffff',
        className,
        ...rest
    })=>{
    
    const ref= useRef();

    useEffect(()=>{
        if (ref.current) {
            const inner = ref.current.querySelector(".progress-bar");
            if ( inner ) {
               inner.style.backgroundColor = color || "none";
            }
          }  
    },[ref,color])

    return <ProgressBar ref={ref} className={`${styles.progressBar} ${className}`} style={{height:"4px"}} {...rest}/>
}

export default CustomProgressBar;