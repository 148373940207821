import React from "react";
import { TimePicker } from "antd";

import styles from "./timeSelector.module.css";

const TimeSelector = ({
        label,
        name,
        value,
        handleValueChange =()=>{},
    })=>{

    const handleTimeChange = (updatedTime,...rest)=>{
        handleValueChange({label : name})(updatedTime);
    }

    return (
        <div className={styles.container}>
            <span className={styles.labelContainer}>
                {label}
            </span>
            <TimePicker
                className={styles.timePicker}
                value={value}
                onChange={handleTimeChange}
                format={"h:mm A"}
                use12Hours={true}
            />
        </div>
    )
}

export default TimeSelector