const BILLING_TAB_NAMES = {
    OVERVIEW : "overView",
    COMPLIANCE_CHECHLIST : "complianceChecklist",
    AUTO_GENERATED_BILL : "autoGeneratedBill"
}

const BILLING_TAB_LABELS = {
    OVERVIEW : "Overview",
    COMPLIANCE_CHECHLIST : "Compliance CheckList",
    AUTO_GENERATED_BILL : "Auto Generated Bill"
}

const BILLING_TIME_MANAGEMENT = ["Initial 30 mins for PCM Care Management","Additional 30 Min of PCM Care Managment"];

const BILLING_TAB_CONTENT = {
    OVERVIEW : [{
            name : "billingType",
            label : "CPT Code"
        },
        {
            name : "billingCycle",
            label : "Billing Cycle"
        },
        {
            name : "careProUserNames",
            label : "Service By"
        },
        {
            name : "minutesUtilizedOutOf90",
            name : "Billing Duration"
        }
    ],
    COMPLIANCE_CHECHLIST : [{
        name : "timeDuration"
    }]
}

export {
    BILLING_TAB_NAMES,
    BILLING_TAB_LABELS,
    BILLING_TAB_CONTENT,
    BILLING_TIME_MANAGEMENT
}