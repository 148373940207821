import {map} from "lodash";

import { USER_DETAILS } from "../../constants/UserDetails";
import { USER_SETTINGS_MAPPING } from "./userSettings.mappings";
import { CHANGE_PASSWORD_FIELDS, getModalField } from "./userSetting.fields";
import { TIME_ZONE_MAPPING } from "../../fieldMapping/date";

import styles from "./userSetting.module.css";
import { getUSFormattedPhoneNumber } from "../../helpers/TextManipulations.helpers";


const getPatientDetailsFormattedData = (mapperDetail,data={})=>{
    return map(mapperDetail,(detail)=>{
        return {
            label : detail.primary,
            className : styles.profileTabOption,
            textClassName : styles.profileTabOptionText,
            innerContainerClass : styles.innerContainerClass,
            options : map(detail.options,(option)=>{
                return {
                    label : USER_DETAILS[option],
                    name : option,
                    className : styles.infoTextContainer,
                    value : data?.[option] || "No Data",
                    CustomComponent : USER_SETTINGS_MAPPING[option]
                }
            })
        }
    })
}

const getFormHeaderProps =({modalContentType})=>{
    switch(modalContentType)
    {
        case "password":
            return {
                content : "Change Password"
            };
         default: return {
            content : "Profile",
        }
    }
}

const getFormBodyProps = ({modalContentType, formProps, userDetails}) =>{
    switch(modalContentType)
    {
        case "password":
            return {
                content : "You may be signed out of your account on some devices.",
                formProps,
                initialState : userDetails
            };
         default: return {
            formProps,
            initialState : userDetails
        }
    }
}

const getModalFormProps= ({modalContentType,getFormSubmitAction})=>{
    switch(modalContentType){
        case "password":
            return {
            formContent : CHANGE_PASSWORD_FIELDS,
            formFooterProps:{
                butttonText : "Update Password",
                enabledClassname: styles.enabledClassname
            },
            formContainerProps:{
                containerClassname : styles.formContainer
            },
            formSubmitAction : getFormSubmitAction
        }
        default:
            return {
                formContent : getModalField({name: modalContentType}),
                formFooterProps:{
                    butttonText : "Update",
                    enabledClassname: styles.enabledClassname
                },
                formContainerProps:{
                    containerClassname : styles.formContainer
                },
                formSubmitAction : getFormSubmitAction,
            }
        }
}

const getFormattedUserDetails = (data)=>{
    const [phoneCode, phone] = (data?.phone || "").split(",");
    return {
        ...data,
        phoneCode,
        phone
    }
}

const getUserDetailsFormatting = (userDetails={})=>{
   return {
        ...userDetails,
        timezoneOffsetHours : TIME_ZONE_MAPPING?.[userDetails?.timezoneOffsetHours*60] ? [TIME_ZONE_MAPPING?.[userDetails?.timezoneOffsetHours*60]] : [TIME_ZONE_MAPPING[-360]]
    }
}

const getRespectivePhoneNumber = (data) =>{
    let updatedPhoneNumber= "";
    const {phone="", phoneCode=""} = data;
    for(let value of phone){
        updatedPhoneNumber+= value.charCodeAt(0)>=48 && value.charCodeAt(0)<=57 ? value : "";
    }
    return phoneCode+","+updatedPhoneNumber;
}

const getUpdateToLocalStorage = (data)=>{
    Object.keys(data).forEach((key)=>{
        window.localStorage.setItem(key, data[key]);
    })
}

export {
    getPatientDetailsFormattedData,
    getModalFormProps,
    getFormHeaderProps,
    getFormBodyProps,
    getUserDetailsFormatting,
    getFormattedUserDetails,
    getRespectivePhoneNumber,
    getUpdateToLocalStorage
}