import React from "react";

import { USER_ROLES } from "../../../../../../../../../../../../../../../constants/UserDetails";
import { DATA_TYPES } from "../../../../../../../../../../../../../../../constants/dataTypes";
import { NOTES_APPROVAL_STATUS } from "../../../../../../../../../../../../../../../constants/notes";

import styles from "./approvalNotification.module.css";

const ApprovalNotification = ({
        notesData={},
        handleApprovalFlow
    }) => {

    const userRole = window?.localStorage.getItem("UserRole");
    const { noteApprovalStatus="" } = notesData || DATA_TYPES.EMPTY_OBJECT;
    
    if(!userRole.includes("NON") || noteApprovalStatus!=NOTES_APPROVAL_STATUS.WAITING_FOR_APPROVAL){
        return <></>
    }
    
    return (
        <div className={styles.container}>
            <span className={styles.primaryContent}>Note is not approved yet</span>
            <div
                className={styles.approvalButton}
                onClick={handleApprovalFlow}
            >
                Approval Note
            </div>
        </div>
    )
}

export default ApprovalNotification;