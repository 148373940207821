import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";

import calendorIcon from './../../../../../../../assets/images/calender/basicCalender.svg';
import SingleDateFilter from "../../../../../../molecules/SingleDateFilter";

import "./singleSelect.module.css";
import styles from "./singleSelect.module.css";

const SingleSelect = ({
        value="",
        handleFieldChange = ()=>()=>{}, 
        name = "",
        checkErrorState = ()=>{},
        errorTypes = [],
    }) =>{

    const [inputValue, setInputValue] = useState(value); 
    const [displayPopup, setDisplayPopup ] = useState(false);

    useEffect(()=>{
        setInputValue(value || moment().add(1,'day'));
    },[value]);

    const handleOpenDisplayPopupAction = ()=>{
        setDisplayPopup(true);
    }

    const handleDisplayPopupToggle = ()=>{
        setDisplayPopup(false)
    };

    const handleDateChange = (value)=>{
        setInputValue(value);
        handleFieldChange({label : name })(value);
        checkErrorState(value, name, errorTypes);
    }

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <img src={calendorIcon} className={styles.icon} />
                <div className={styles.inputFieldContainer} onClick={handleOpenDisplayPopupAction}>
                    {moment(inputValue).format("ddd- MMM DD, YYYY")}
                </div>
                {
                        displayPopup &&
                        <SingleDateFilter
                            handleToggleCalenderDisplay={handleDisplayPopupToggle}
                            className={styles.calendarClass}
                            startDate={moment().format("MM-DD-YYYY")}
                            numberOfDays={0}
                            handleDateUpdateAction={handleDateChange}
                            minDate={moment().add(1,'day').format("MM-DD-YYYY")}
                            forwardAllowed={true}
                        />
                    }
            </div>
        </div>
    )
}

export default SingleSelect;