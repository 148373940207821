import moment from "moment";
import { forEach } from "lodash";
import { COPY_COLOR_FORMATS, METRICS_LABEL, METRIC_SUFFIX } from "./body.constants";

const getPrimaryCardContent = (primaryCard=[]) =>{
    let copyStr="";
    primaryCard?.forEach((val)=>{
        const { glucoseDetails ={} , metricRequest = {}} = val || {};
        const { metricType ="" }= metricRequest;
        const { glucose }= glucoseDetails;
        if(!METRICS_LABEL?.[metricType]){
            return;
        }

        copyStr+=COPY_COLOR_FORMATS?.[glucoseDetails?.['glucose-color']?.split("_")?.[0]] ? COPY_COLOR_FORMATS?.[glucoseDetails?.['glucose-color']?.split("_")?.[0]]+" " : "";
        copyStr+=METRICS_LABEL?.[metricType]+": ";
        copyStr+= (glucose? glucose+" "+(METRIC_SUFFIX?.[metricType] || "") : "???")+"\n";
    })
    return copyStr;
}

const getTimeInRangeCopyFormat = (timeInRange={})=>{
    let copyStr=""

    let inRange = timeInRange?.["OVERALL_TIME_IN_RANGE_BETWEEN_63_TO_140"];

    let aboveRange = timeInRange?.["OVERALL_TIME_IN_RANGE_MORE_THAN_140"];

    let lowRange = timeInRange?.["OVERALL_TIME_IN_RANGE_LESS_THAN_63"];

    let check = inRange?.['glucose'] || aboveRange?.['glucose'] || lowRange?.['glucose'];

    copyStr+= COPY_COLOR_FORMATS?.[aboveRange?.['glucose-color']] ? COPY_COLOR_FORMATS?.[aboveRange?.['glucose-color']]+" " : "";
    copyStr+="Time above Range: "+( check ? (aboveRange?.["glucose"] || 0)+"%" : "???")+"\n";

    copyStr+= COPY_COLOR_FORMATS?.[aboveRange?.['glucose-color']] ? COPY_COLOR_FORMATS?.[inRange?.['glucose-color']]+" " : "";
    copyStr+="Time in Range: "+ ( check ? (inRange?.["glucose"] || 0)+"%" : "???")+"\n";

    copyStr+=COPY_COLOR_FORMATS?.[aboveRange?.['glucose-color']] ? COPY_COLOR_FORMATS?.[lowRange?.['glucose-color']]+" " : "";
    copyStr+="Time below Range: "+ (check? (lowRange?.["glucose"] || 0)+"%" : "???")+"\n";

    return copyStr;
}

const getTrendsFormattedData = (recentTrends=[])=>{
    let copyStr="";
    recentTrends?.forEach((val)=>{
        const {label="", value="", trendAgeDays=""}= val || {};
        copyStr+=label;
        copyStr+="\t\t";
        let content="";
        for(let minIndex=0;minIndex<45;minIndex++){
            content+=value[minIndex] || " ";
        }
        copyStr+=content;
        copyStr+="\t\t";
        copyStr+=`Trend Age: ${trendAgeDays} day${trendAgeDays>1 ? "s" : ""}`;
        copyStr+="\n"
    })
    return copyStr;
}

const getDeviceDetails = (deviceDetails=[], startDate) =>{
    let copyStr = "";

    if(deviceDetails.length ==0 || !deviceDetails){
        return copyStr;
    }

    let OrderedDeviceDetailsList = [];

    deviceDetails.forEach((device, index)=>{
        OrderedDeviceDetailsList.unshift(device);
    })

    const currentStartDate = moment(startDate, "MM-DD-YYYY").valueOf();
    const currentEndDate = moment(startDate, "MM-DD-YYYY").add(6,'days').valueOf();
    let currentCounter=0;

    forEach(OrderedDeviceDetailsList,({
        deviceManufacturer,
        deviceName,
        serialNumber,
        activationTimestamp,
        lastActive

    })=>{
        const currentTime = moment(activationTimestamp).valueOf();
        const lastActiveTime = moment(lastActive).valueOf();
        
        if(lastActiveTime<currentStartDate || currentTime>currentEndDate){
            return;
        }
        let currCopyStr = "";

        const respectiveStartDate = currentTime<currentStartDate ? startDate : activationTimestamp;
        const respectiveEndDate = currentEndDate<lastActiveTime ? moment(startDate, "MM-DD-YYYY").add(6,'days').format("MM-DD-YYYY") : lastActive;

        currCopyStr+=`Device #${currentCounter+1}`;
        currCopyStr+="\n";
        currCopyStr+=`Device Make & Model: ${deviceManufacturer} ${deviceName}`;
        currCopyStr+="\n";
        currCopyStr+=`Device Serial Number: ${serialNumber}`;
        currCopyStr+="\n";
        currCopyStr+=`Device Activity: ${moment(respectiveStartDate).format("MM/DD/YYYY")} - ${moment(respectiveEndDate).format("MM/DD/YYYY")}`;
        currCopyStr+="\n\n";
        copyStr+=currCopyStr;
        currentCounter++;
    });

    return copyStr;
}

const getSummaryCopyFormat = (summaryData,prev,recentTrends, deviceDetails)=>{
    let copyString = "";
    copyString+="Date Range: ";
    copyString+=moment(prev,"MM-DD-YYYY").format("MMM DD, YYYY")+" to " + moment(prev,"MM-DD-YYYY").add(6,'d').format("MMM DD, YYYY");
    copyString+="\n\n";
    copyString+=getDeviceDetails(deviceDetails, prev);
    copyString+=getPrimaryCardContent(summaryData?.["primaryCardFullData"]);
    copyString+="\n"
    copyString+=getTimeInRangeCopyFormat(summaryData?.["overallTimeInRange"]?.["metricsNameGlucoseDetailsMap"])
    copyString+="\n"

    copyString+='Trends'+"\n";
    if(!recentTrends.length && recentTrends?.length ==0){
        copyString+="???\n";
    }
    else{
        copyString+=getTrendsFormattedData(recentTrends)
    }

    copyString+="\n";
    copyString+="#: Green          * : Yellow          !: Red         ???: No Data"
    copyString+="\n\n"


    return copyString
}

const getSummaryCopyAction = ({
        summaryData,
        handleToastUpdateAction=()=>{},
        prev,
        recentTrends,
        deviceDetails = []
    })=>{
    try{
        let copyData = getSummaryCopyFormat(summaryData,prev,recentTrends,deviceDetails);

        navigator.clipboard.writeText(copyData)
        .then(()=>{handleToastUpdateAction({
            enableToast: true,
            toastProps :{
                message : "Glucose Log Sheet successfully copied to Clipboard",
                type : "success"
            }
        })})
    }
    catch(e){
        console.log('COPY FAILED : ',e)
    }
}

export {
    getSummaryCopyAction,
    getSummaryCopyFormat
}