import React, { useState } from "react";

import BaseHeader from "./components/BaseHeader/BaseHeader";
import BaseBody from "./components/BaseBody/BaseBody";

import styles from "./toggableTab.module.css";
import { getCopyToClipBoardTab } from "./ToggableTab.components";

const ToggableTab = ({
        label,
        isOpen=false,
        ...rest
    })=>{

    const [ activeContent, setActiveContent ] = useState(isOpen);

    const handleToggleActiveContent = () =>{
        setActiveContent(!activeContent);
    }

    const headerChild = getCopyToClipBoardTab(rest.content, rest.name)

    return (
        <div className={styles.container}>
            <BaseHeader
                label={label}
                handleToggleActiveContent={handleToggleActiveContent}
                activeContent={activeContent}
            >
                {headerChild}
            </BaseHeader>
            {activeContent &&
                <BaseBody {...rest} />
            }
        </div>
    )
}

export default ToggableTab