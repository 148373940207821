import { logToCloudWatch } from "../../configs/awsLogs";
import { BASE_URL } from "../../constants/api";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";

const getResendInviteAction = async({baseId})=>{
    try{
        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/resendAddNewPracticeInviteEmail/${baseId}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            },
        })

        return response.status;
    }
    catch(e){
        console.log('Error is : ',e);
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        return e;
    }
}

export {
    getResendInviteAction
}
