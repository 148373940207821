import React from "react";
import { Tooltip } from "antd";

import basicExclamation from "./../../../../../../../assets/images/exclamation/basicExclamation.svg";

import styles from "./subFormContentLabel.module.css";

const SubFormContentLabel = ({
        label,
        tooltipProps
    })=>{
        const getTooltip= ({
                content = ""
            })=>{
                return (
                    <span className={styles.tooltipTitle}>
                        {content}
                    </span>
                )
        }
        return (
        <span className={styles.innerLabelContainer}>
            <span className={styles.label}>
                {label}
            </span>
            <Tooltip title={getTooltip(tooltipProps)}>
                <img src={basicExclamation} className={styles.exclamationIcon} />
            </Tooltip>
        </span>
    )
}

export default SubFormContentLabel;