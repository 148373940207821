

const USER_DETAILS ={
    firstName : "First Name",
    lastName : "Last Name",
    middleName : "Middle Name",
    gender : "Gender",
    workEmail : "Email",
    speciality : "Speciality",
    credentials : "Credentials",
    phone : "Phone Number",
    settings : "Settings",
    password : "Password",
    practiceName : "Practice Name",
    practiceWebsite : "Practice Website",
    timezoneOffsetHours : "Time Zone"
}

const PATIENT_DETAILS_ENUMS = {
    firstName : "First Name",
    lastName : "Last Name",
    middleName : "Middle Name",
    gender : "Gender",
    email : "Email",
    phoneNumber : "Phone Number",
    settings : "Settings",
    password : "Password",
    sex : "Sex",
    patientId : 'Patient ID/MRN',
    dataSharingConsent : "Data Sharing Consent",
    HippaConsent : "Hippa Consent",
    renewrxTandC : "Renewrx Terms And Conditions",
    emergencyContactName : "Name",
    relationship : "Relationship With Patient",
    contactNumber : "Contact Number",
    alternateNumber : "Alternate Number"
}

const USER_ROLES = {
    NON_RENEWRX_USER : "NON_RENEWRX_USER",
    RENEWRX_CARE_USER : "RENEWRX_CARE_USER"
}

export {
    USER_DETAILS,
    PATIENT_DETAILS_ENUMS,
    USER_ROLES
}