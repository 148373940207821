import { logToCloudWatch } from "../../../../configs/awsLogs";
import { BASE_URL } from "../../../../constants/api";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants";
import { GLUCOSE_METRICS_TYPES } from "../../../../constants/metricTypes";
import { getRespectiveFormattedData } from "./progressChart.helpers";

const getPopupDisplayToggleAction = ({ setState, payload}) =>{
    try{
        setState((prev)=>{
            const { displayPopup } = prev;
            return {
                ...prev,
                displayPopup: !displayPopup
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getPopupModalData = async({state, setState, payload})=>{
    try{
        const {date} = payload;
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                metricRequests: [
                    {
                        metricType:"OVERALL_TIME_IN_RANGE",
                        start_date:date,
                        end_date:date,
                    },
                    {
                        metricType: "AVERAGE_GLUCOSE",
                        start_date:date,
                        end_date:date,
                    },
                    {
                        metricType: "AVG_FASTING_GLUCOSE",
                        start_date:date,
                        end_date:date,
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        start_date:date,
                        end_date:date,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        start_date:date,
                        end_date:date,
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        start_date:date,
                        end_date:date,
                        mealType: "DINNER"
                    },
                ]
            })
        });

        const data = await response.json();

        setState((prev)=>{
            return {
                ...prev,
                modalMetrics: getRespectiveFormattedData(data?.metricsResponses),
            }
        })

    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            api: '/dashboard/glucoseMetrics',
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        });
    }
}

export default {
    HANDLE_POPUP_TOGGLE : getPopupDisplayToggleAction,
    GET_MODAL_DATA : getPopupModalData,
}