import React from "react";

import styles from "./baseBody.module.css"

const BaseBody = ({
        BodyComponent=()=><></>,
        content="Hello World",
        className="",
        contentClassName="",
        ...rest
    }) =>{
    return (
        <div className={`${styles.container} ${className}`}>
            <div className={`${styles.contentTypo} ${contentClassName}`}>{content}</div>
            <BodyComponent {...rest} />
        </div>
    )
}

export default BaseBody