import React from "react";
import {map} from "lodash";

import BillingItem from "./component/BillingItem";
import CodeBasedBilling from "./component/CodeBasedBilling";

import styles from "./billingList.module.css";

const BillingList = ({
        listItems,
        codeBasedBilling,
        handleMoveReadyToBill,
        handleToggleLoaderAction
    })=>{
    return (
        <div className={styles.container}>
            {map(codeBasedBilling,(billingItem)=>{
                return (<CodeBasedBilling handleToggleLoaderAction={handleToggleLoaderAction} handleMoveReadyToBill={handleMoveReadyToBill} {...billingItem} />)
            })}
            {map(listItems,(listItem)=>{
                return (<BillingItem {...listItem} />)
            })}
        </div>
    )
}

export default BillingList