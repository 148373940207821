import moment from "moment";

const getRespectiveFollowUpDate = (notes, notesData)=>{
    let date = {
        careProvider : moment().format("YYYY-MM-DD"),
        careCoach : moment().format("YYYY-MM-DD"),
    };

    for(let index=0;index<notesData.length;index++){
        if(notesData[index]?.followUpDateByRenewRxUser){
            let prev = notesData[index]?.followUpDateByRenewRxUser;
            let curr = date.careCoach;
            if(prev>curr){
                date.careCoach=notesData[index]?.followUpDateByRenewRxUser
            }
        }

        if(notesData[index]?.followUpDateByNonRenewRxUser){
            let prev = notesData[index]?.followUpDateByNonRenewRxUser;
            let curr = date.careProvider;
            if(prev>curr){
                date.careProvider=notesData[index]?.followUpDateByNonRenewRxUser
            }
        }
    }

    if(date.careCoach == moment().format("YYYY-MM-DD")){
        date.careCoach="-"
    }
    if(date.careProvider == moment().format("YYYY-MM-DD")){
        date.careProvider="-"
    }

    date.careCoach = date.careCoach!='-' ?  moment(date.careCoach).format("DD MMM, YYYY") : '-';

    date.careProvider = date.careProvider!='-' ?  moment(date.careProvider).format("DD MMM, YYYY") : '-';

    return date;
}

export {
    getRespectiveFollowUpDate
}