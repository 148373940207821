import React from "react";

import Header from "./components/Header";
import Body from "./components/Body";

import styles from "./dailyMonitoringLog.module.css";
import DataStreams from "./components/Body/components/dataStreams/DataStreams";
import { ROW_DATA_STREAMS } from "./components/Body/body.constants";
import foodLog from "./../../../../../../../assets/images/food_log.svg";
import medications from "./../../../../../../../assets/images/purple_colored_medication.svg";
import comingSoon from "./../../../../../../../assets/images/Coming Soon.svg";

const DailyMonitoringLog = ({tableData,wrapperFields,handleOverDurationChange, overViewDurationTypes }) =>{

    return (
        <div className={styles.container}>
            <Header handleOverAllDurationChange={handleOverDurationChange} overViewDurationTypes={overViewDurationTypes} />
            <Body handleOverAllDurationChange={handleOverDurationChange} overViewDurationTypes={overViewDurationTypes} tableData={tableData} wrapperFields={wrapperFields}/>

            <div className={styles.balancedPlateAndMedicationAdherenceContainer}>
                <DataStreams icon={foodLog} primaryText={ROW_DATA_STREAMS[1].PRIMARY} secondaryText={ROW_DATA_STREAMS[1].SECONDARY} tertiaryText={ROW_DATA_STREAMS[1].Tertiary} />
                <div><img src={comingSoon} alt="coming soon" /></div>
            </div>
            <div className={styles.balancedPlateAndMedicationAdherenceContainer}>
                <DataStreams icon={medications} primaryText={ROW_DATA_STREAMS[2].PRIMARY} secondaryText={ROW_DATA_STREAMS[2].SECONDARY} tertiaryText={ROW_DATA_STREAMS[2].Tertiary} />
                <div><img src={comingSoon} alt="coming soon" /></div>
            </div>
            
        </div>
    )
}

export default DailyMonitoringLog;