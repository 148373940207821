import React from "react";

import styles from "./header.module.css";

const Header = ({
        CancelComponent = ()=><></>,
        secondaryContent
    }) =>{
    return (
        <div className={styles.container}>
            <div className={styles.mainContainer}>
                <div className={styles.primaryText}>
                    Billing Outbox
                </div>
                <div className={styles.secondaryText}>
                    {secondaryContent}
                </div>
            </div>
            <CancelComponent />
        </div>
    )
}

export default Header;