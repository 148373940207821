import { BILLING_TYPES } from "../../../../billing.constants"

const getDrawerHeaderContent = (billingType) =>{
    return {
        secondaryContent : `${billingType} Service - ${BILLING_TYPES[billingType]}`
    }
}

export {
    getDrawerHeaderContent
}