import moment from "moment";
import { LOCAL_STORAGE_KEYS } from "../../../../../../../../../../constants/keyConstants";
import { BASE_URL } from "../../../../../../../../../../constants/api";
import { logToCloudWatch } from "../../../../../../../../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../../../../../../../../constants/awsLogs.constants";

const getModalInitAction = async({state, setState}) =>{
    try{
        const {meal={}} = state;
        const {logged_time="", meal_type_name = ""} = meal;
        const date = moment(logged_time).format('YYYY-MM-DD');

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const respone = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                metricRequests:[
                    {
                        metricType: "ONE_HOUR_POST_PRANDIAL",
                        mealType: meal_type_name?.toUpperCase(),
                        date
                    },
                    {
                        metricType: "TWO_HOUR_POST_PRANDIAL",
                        mealType: meal_type_name?.toUpperCase(),
                        date
                    },
                    {
                        metricType:'POST_PRANDIAL_HIGH_RATE',
                        mealType: meal_type_name?.toUpperCase(),
                        date
                    }
                ]
            })
        })

        const responseMetrics = await respone.json();

        setState((prev)=>{
            return {
                ...prev,
                responseMetrics: responseMetrics?.metricsResponses,
            }
        })

    }
    catch(e){
        setState((prev)=>({...prev, error:e}));
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}

export default {
    MODAL_INIT_ACTION : getModalInitAction
}