import React from "react";
import {map} from "lodash";

import VerticalSingleLineChart from "../../../../../../../../charts/VerticalSingleLineChart/VerticalSingleLineChart";
import { getBasicMapDataFormatter } from "../../../../../../../../dataFormatter/glucoseMetricsFormatter";
import { getDataInTableFormat, getDataValueFields } from "./tirCard.helper";

import styles from "./tirCard.module.css";
import TirSingleBlock from "./components/TirSingleBlock/TirSingleBlock";
import CardNoDataFallback from "../../../../../../../../molecules/cardNoDataFallback/CardNoDataFallback";
import ErrorBoundary from "../../../../../../../../helpers/ErrorBoundary";

const TirCard = ({data, ...rest})=>{

    const {overallTimeInRange } = data ||{};

    const rangeFormattedData = getBasicMapDataFormatter([overallTimeInRange]);

    const {formattedData, glucoseSum=0} = getDataInTableFormat(rangeFormattedData?.["OVERALL_TIME_IN_RANGE DINNER"]);

    const normalFormatData = getDataValueFields(rangeFormattedData?.["OVERALL_TIME_IN_RANGE DINNER"],['above','inRange','below']);

    if(glucoseSum==0){
        return <CardNoDataFallback />
    }

    return (
        <div className={styles.container}>
            <ErrorBoundary>
                <VerticalSingleLineChart data={formattedData}/>
            </ErrorBoundary>
            <div className={styles.rightContainer}>
                {map(normalFormatData,(formatValue)=>{
                    return <TirSingleBlock {...formatValue}/>
                })}
            </div>
        </div>
    )
}

export default TirCard;