import purpleBraces from "./../../../../../../../../../../../assets/images/brackets/currlyPurpleBrackets.svg"
import purpleTimer from "./../../../../../../../../../../../assets/images/timer/purpleTimer.svg"
import userIcon from "./../../../../../../../../../../../assets/images/user/maleUser.svg"
import billIcon from "./../../../../../../../../../../../assets/images/bill/purpleBill.svg"
import purpleCircledTick from "./../../../../../../../../../../../assets/images/tick/purpleCircleTick.svg"


const BILLING_TAB_NAMES = {
    OVERVIEW : "overView",
    COMPLIANCE_CHECHLIST : "complianceChecklist",
    AUTO_GENERATED_BILL : "autoGeneratedBill"
}

const BILLING_TAB_LABELS = {
    OVERVIEW : "Overview",
    COMPLIANCE_CHECHLIST : "Compliance CheckList",
    AUTO_GENERATED_BILL : "Auto Generated Bill"
}

const BILLING_TYPES_ITEMS = {
    PCM : "PCM Service - CPT 99426, 99427",
    RPM : "RPM Service - CPT 99457, 99458"
}

const BILLING_TIME_MANAGEMENT = ["Initial 30 mins for PCM Care Management","Additional 30 Min of PCM Care Managment"];

const BILLING_TAB_NAME_CONTENT = {
    minutesUtilizedOutOf90 : {
        PCM : " out of 90 mins",
        RPM : " out of 60 mins"
    }
}

const BILLING_TAB_CONTENT = {
    OVERVIEW : [{
            name : "billingCode",
            label : "CPT Code",
            icon : purpleBraces
        },
        {
            name : "billingCycle",
            label : "Billing Cycle",
            icon : purpleTimer
        },
        {
            name : "careProUserNames",
            label : "Service By",
            icon : userIcon
        },
        {
            name : "minutesUtilizedOutOf90",
            label : "Billing Duration",
            icon : billIcon
        },
        {
            name : "billingStatus",
            label : "Billed",
            icon : purpleCircledTick
        }
    ],
    COMPLIANCE_CHECHLIST : [{
        name : "timeDuration"
    }]
}

const BILLING_STATUS_TYPES = {
    ONGOING : "Ongoing",
    READY_TO_BILL : "Ready To Bill",
    NOT_BILLED : "Not Billed",
    BILLED : "Billed"
}

const NUMBER_OF_PROGRESS_SETS = 3

export {
    BILLING_TAB_NAMES,
    BILLING_TAB_LABELS,
    BILLING_TAB_CONTENT,
    BILLING_TIME_MANAGEMENT,
    BILLING_TYPES_ITEMS,
    NUMBER_OF_PROGRESS_SETS,
    BILLING_STATUS_TYPES,
    BILLING_TAB_NAME_CONTENT
}