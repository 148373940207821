import { logToCloudWatch } from "../../../../configs/awsLogs";
import { API_METHOD_TYPES, BASE_URL } from "../../../../constants/api";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants";

const getPracticePatientDetails = async() =>{
    try{
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const coachId = pathname?.[4] || "";

        const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/patientsByStatus/${coachId}?status=ACTIVE`,{
            method: API_METHOD_TYPES.GET,
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
        })

        const practicePatientList = await request.json();

        return practicePatientList;

    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        console.log('FAILED TO FETCH PATIENTS : ',e);
    }
}

export {
    getPracticePatientDetails
}