import React from "react";

import styles from "./bodyContent.module.css";

const BodyContent = ({
        content,
        className
    })=>{
    return (
        <div className={`${styles.container} ${className}`}>
            {content}
        </div>
    )
}

export default BodyContent;