import React from "react"
import { NOTES_FIELDS_NAMES } from "../../body.constants";
import { getCopyToClipBoardAction } from "./toggableTab.helpers";

import styles from "./toggableTab.module.css";

const getCopyToClipBoardTab = (content,name)=>{
    switch(name){
        case NOTES_FIELDS_NAMES.ACTIVITY_LOGS:
            return <></>
    }
    const handleCopyAction = ()=>{
        getCopyToClipBoardAction(content);
    }
    return (
        <div className={styles.copyToClipboard} onClick={handleCopyAction}>
            Copy To Clipboard
        </div>
    )
}

export {
    getCopyToClipBoardTab
}