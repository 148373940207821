import React, { useState } from "react";
import moment from "moment";

import billingIcon from "./../../../../../../../../../../../assets/images/tick/circledTick.svg";

import { getDrawerTabs } from "./billingItem.tabs";
import BillingDrawer from "../../../BillingDrawer/BillingDrawer";

import styles from "./billingItem.module.css"

const BillingItem = (props) =>{

    const [displayDrawer, setDisplayDrawer] = useState(false)
    
    const {billingDate="", careProUserName="", nextBillingDate="-", type="_"} = props || {};
    
    let label = type?.split("_")?.join(" ");

    const handleToggleDrawer = ()=>{
        setDisplayDrawer(!displayDrawer);
    }

    const drawerTabs = getDrawerTabs(props);

    return (
        <>
            <div className={styles.listItemContainer} onClick={handleToggleDrawer}>
                <div className={styles.iconContain}>
                    <img src={billingIcon} className={styles.billingIcon} />
                </div>
                <div className={styles.rightItemContainer}>
                    <div className={styles.rightTopContainer}>
                        <div className={styles.mainText}>{label || "-"}</div>
                    </div>
                    <div className={styles.rightBottomContainer}>
                        <div className={styles.textOuterContainer}>
                            <div className={styles.nonHighlightedText}>Created By</div>
                            <div className={styles.listItemBaseContent}>{careProUserName}</div>
                        </div>
                        <div className={styles.textOuterContainer}>
                            <div className={styles.nonHighlightedText}>Submitted on</div>
                            <div className={styles.listItemBaseContent}>{moment(billingDate).format("ddd, MMM DD")}</div>
                        </div>
                        <div className={styles.textOuterContainer}>
                            <div className={styles.nonHighlightedText}>Next Billing Cycle</div>
                            <div className={styles.listItemBaseContent}>{moment(nextBillingDate).format("ddd, MMM DD")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default BillingItem