import { logToCloudWatch } from "../../../../configs/awsLogs";
import { BASE_URL } from "../../../../constants/api";
import { LOG_STREAM_TYPE } from "../../../../constants/awsLogs.constants";

const getMoveToArchieveAction = async()=>{
    try{

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/archiveMember/${pathname?.[4]}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        })

        return response;
    }
    catch(e){
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        console.log('ERROR IS : ',e);
    }
}

export{
    getMoveToArchieveAction
}