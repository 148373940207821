import React from 'react';

import styles from "./notes.module.css";
import Header from './components/Header/Header';
import Body from './components/Body/Body';

const Notes = ({
        notes,
        headerProps,
        bodyProps,
        notesData={},
        handleToggleLoaderAction,
        handleGetNotesListAction
    }) =>{
    return (
        <div className={styles.container}>
            <Header 
                {...headerProps}
            />
            <Body 
                {...bodyProps}
                notes={notes}
                notesData={notesData}
                handleToggleLoaderAction={handleToggleLoaderAction}
                handleGetNotesListAction={handleGetNotesListAction}
            />
        </div>
    )
}

export default Notes;