import React from "react";

import SideDrawer from "../../../../../../../../molecules/sideDrawer";

import Header from "./components/Header/Header.js";
import Body from "./components/Body/Body.js";

import styles from "./billingDrawer.module.css";

const BillingDrawer = ({
        handleMoveReadyToBill,
        ...rest
    })=>{
    return (
        <SideDrawer
            sliderWindowClassName={styles.sliderWindowClassName}
            BodyComponent={Body}
            HeaderComponent={Header}
            bodyProps={{
                drawerTabs : rest.drawerTabs,
                billingData : rest?.billingData || {},
                handleMoveReadyToBill
            }}
            {...rest}
        />
    )
}

export default BillingDrawer