import React, { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

import MultiSelect from "../../../../../../../../../../molecules/Multiselect/MultiSelect";
import calenderIcon from "./../../../../../../../../../../../assets/images/calender/basicCalender.svg";
import { DATE_FORMATS } from "../../../../../../../../../../constants/dateFormats";

import { BILLING_BODY_POPUP, BILLING_VALUE_GROUP } from "../../billingPopup.content";

import SelectedItemList from "./component/SelectedItemList";
import DropdownOption from "./component/DropdownOption/DropdownOption";

import "react-datepicker/dist/react-datepicker.css";

import "./body.module.css";
import styles from "./body.module.css";



const Body = ({handleModalToggle: handleModalSubmit}) =>{
    // handleFieldChange({label:name})([value])

    const [currentBillingOptions , setCurrentBillingOptions] = useState([BILLING_BODY_POPUP.at(0).value]);
    const [selectedActiveList, setSelectedActiveList] = useState([BILLING_VALUE_GROUP["95251 - CGM Interpretation"]]);
    const [billingDate , setBillingDate] = useState(moment().format(DATE_FORMATS.MONTH_DATE_FULLYEAR_SLASH_FORMAT));


    const handleBillingUpdate = ({label})=>(value)=>{
        setCurrentBillingOptions((prev)=>{
            return [...value]
        })
    }

    const handleBillingDateUpdate = (date)=>{
        setBillingDate(date);
    }

    const handleModalSubmitAction = ()=>{
        handleModalSubmit({items: currentBillingOptions, date:billingDate});
    }

    const handleRemoveSelectedActiveList = (value)=>{
        let arr = [...selectedActiveList];
        arr= selectedActiveList?.filter((val)=> val !=value);
        setSelectedActiveList(arr);
    }

    const handleSetSelectedActiveList = (value) =>{
        let arr = [...selectedActiveList];
        arr.push(value);
        setSelectedActiveList(arr);
    }

    return (
        <div className={styles.container}>
            <div className={styles.dateLabel}>Billing For</div>
            <MultiSelect inputContainerClass={styles.inputContainerClass} handleFieldChange={handleBillingUpdate} placeholder={"Choose what you're billing for"} options={BILLING_BODY_POPUP} CustomDropDownComponent={DropdownOption} value={[BILLING_BODY_POPUP.at(0).value]} />
            <div className={styles.dateContainer}>
                <div className={styles.dateLabel}>
                    Interpretation Date
                </div>
                <div className={styles.datePickerInputContainer}>
                    <DatePicker
                        selected={billingDate}
                        onChange={handleBillingDateUpdate}
                        customInput={
                            <input 
                                type="text" 
                                value={billingDate} 
                                className={styles.inputDate} 
                                placeHolder={"Enter the date when the data was interpretated"}
                            />
                        }
                        maxDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        popperPlacement="bottom"
                        showPopperArrow={false}
                    />
                    <div className={styles.datePickerIconContainer}>
                        <img src={calenderIcon} className={styles.calenderIcon} />
                    </div>
                </div>
            </div>
            <SelectedItemList handleSetSelectedActiveList={handleSetSelectedActiveList} handleRemoveSelectedActiveList={handleRemoveSelectedActiveList} listItems={currentBillingOptions} />
            <div onClick={handleModalSubmitAction} className={`${styles.submitButton} ${(selectedActiveList?.length == currentBillingOptions?.length && currentBillingOptions?.length >0) ? styles.activeStyles : ""}`}>
                Submit
            </div>
        </div>
    )
}

export default Body;