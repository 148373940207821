import React from "react";
import Label from "../../../../../../../../../../../../atoms/label/Label";

import { GPT_LABEL } from "./gptCodeSummary.constants";
import SummaryTable from "./components/SummaryTable";
import { DATA_TYPES } from "../../../../../../../../../../../../constants/dataTypes";

import styles from "./gptCodeSummary.module.css";

const GptCodeSummary = ({
        ...rest
    })=>{
    return (
        <div className={styles.container}>
            <Label label={GPT_LABEL} className={styles.label}/>
            <SummaryTable billingData={rest?.billingData || DATA_TYPES.EMPTY_OBJECT}/>
        </div>
    )
}

export default GptCodeSummary;