import React, { useState } from "react";
import generatePDF from 'react-to-pdf';

import { CONSOLIDATED_SECTIONS } from "./body.constants";
import { getDrawerTabs } from "../../BillingList/component/CodeBasedBilling/codeBasedBilling.tabs";
import BillBody from "./../../BillingDrawer/components/Body/Body.js"
import { getFormattedConsolidatedBillingData } from "./body.helpers";
import { BILLING_PDF_OPTIONS } from "../../../../../../../../../configs/pdfConfigs.js";

import styles from "./body.module.css";

const Body = ({
        billingList,
        handleMoveReadyToBill
    }) => {

    const [targetRef, setTargetRef] = useState(null) ;

    const handleUpdateTargetRef = (ref)=>{
        setTargetRef(ref);
    }

    const handleGeneratePDF =()=>{
        generatePDF(targetRef,BILLING_PDF_OPTIONS)
    }

    const consolidatedList = getFormattedConsolidatedBillingData(billingList);

    const drawerTabs = getDrawerTabs(consolidatedList, CONSOLIDATED_SECTIONS, handleGeneratePDF, handleUpdateTargetRef)

    return (
        <BillBody
            handleMoveReadyToBill={handleMoveReadyToBill}
            drawerTabs={drawerTabs}
            billingData={consolidatedList}
        />
    )
}

export default Body;