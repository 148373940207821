import React from "react";

import styles from "./header.module.css";

const Header = ({
        startDate="",
        endDate="",
        CancelComponent = ()=><></>
    }) => {
    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                <span className={styles.mainContentTypo}>Billing Outbox</span>
                <span className={styles.secondaryTypo}>Consolidate Bill for {startDate} - {endDate}</span>
            </div>
            <CancelComponent />
        </div>
    )
}

export default Header