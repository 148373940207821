const NOTES_TYPES={
    EXPRESS : "Express Note",
    DETAILED : "Detailed Note",
    PRIVATE : "Private Note"
}

const NOTES_HEADER_NOTIFICATION_TYPES = [{
        name : "careProvider",
        content : "Next Provider Follow-up"
    },
    {
        name : "careCoach",
        content : "Next Care Coach Follow-up"
    }
]

export {
    NOTES_TYPES,
    NOTES_HEADER_NOTIFICATION_TYPES
}