import moment from "moment";
import { forEach } from "lodash";

import { DATA_TYPES } from "../../../../../../../../../../../../../../constants/dataTypes";
import { DATE_FORMATS } from "../../../../../../../../../../../../../../constants/dateFormats";

const getSummaryTableData = ({
        complianceCheck=DATA_TYPES.EMPTY_ARRAY,
        billingType
    }) =>{
    const complianceTableData = [];
    forEach(complianceCheck,({
        date,
        pendingMins,
        billType,
    },index)=>{
        if(date && date?.length>0){
            complianceTableData.push({
                criteriaMetOn : `Met on ${moment(date,DATE_FORMATS.YEAR_MONTH_DAY).format(DATE_FORMATS.SEMIMONTH_DAY_FULLYEAR)}`,
                utilization : `${30-pendingMins}/30 Min`,
                encounterId : "59711",
                cptCode: index==0 ? ((billingType=="PCM" || billType=="PCM") ? 99426 : 99457) : ( (billingType=="PCM" || billType=="PCM") ? 99427 : 99458)
            })    
        }
    });

    return complianceTableData
}

export {
    getSummaryTableData
}