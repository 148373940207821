import { map } from "lodash";
import FormContent from "../../../../../../reusables/formManager/components/formContent"
import { BASE_FIELDS_NAMES, BASE_FORM, CLINICAL_NOTES_SECTIONS, CLINICAL_NOTES_SECTIONS_NAMES, MONITORING_AND_REVIEWING_FORM, PLACEHOLDER_TEXTS, TOOLTIP_PROPS } from "./clinicalNotes.constants"

import Multiselect from "../../../../../../molecules/Multiselect";
import RangeSelector from "./components/RangeSelector";
import RichTextEditor from "../../../../../../molecules/RichTextEditor/RichTextEditor";
import { BILLING_TYPES } from "../../notesFormPopup.constants";

import styles from "./clinicalNotes.module.css";

const getCustomFieldProps = (name, billingCode)=>{
    switch(name){
        case BASE_FIELDS_NAMES.TIME_SPENT :
            return {
                CustomInput : RangeSelector,
                dropdownContainer : styles.dropdownContainer,
                maxRangeLimit : "CPT 99426, 99427 - Principal Care Management"== billingCode ? 30 : 20,
            };
            break;
        case BASE_FIELDS_NAMES.DETAILS :
            return {
                CustomInput : RichTextEditor,
            }
        default :
            return {
                CustomInput : RichTextEditor,
            }
    }
}

const getRespectivePlaceHolder = (name="", subTab="") =>{
    if(!PLACEHOLDER_TEXTS?.[name]?.[subTab]){
        return {};
    }
    return {
        placeHolder : PLACEHOLDER_TEXTS[name][subTab]
    }
}

const getNotesPopupFields = (notesFormFields={}, subTab, billingCode) =>{
    return map(notesFormFields,({name, label, type})=>{

        return {
            name,
            label,
            className : styles.formFieldContainer,
            onFocus:()=>{},
            onBlur:()=>{},
            ...getCustomFieldProps(type, billingCode),
            ...getRespectivePlaceHolder(type, subTab),
        }
    })
}

const getCorrespondingActiveStateProps = (tabs, subTab, handleNotesFormUpdate=()=>{},billingCode) =>{
    switch(tabs){
        case CLINICAL_NOTES_SECTIONS.MONITORING_AND_REVIEWING:
            return {
                Component : FormContent,
                name : CLINICAL_NOTES_SECTIONS_NAMES[subTab],
                bodyProps:{
                    formContent : getNotesPopupFields(MONITORING_AND_REVIEWING_FORM, subTab,billingCode),
                    handleFieldChange : handleNotesFormUpdate,
                    formBodyClassname : styles.formBodyClassname
                }
            }
        default:
            return {
                Component : FormContent,
                name : CLINICAL_NOTES_SECTIONS_NAMES[subTab],
                bodyProps:{
                    formContent : getNotesPopupFields(BASE_FORM, subTab,billingCode),
                    handleFieldChange : handleNotesFormUpdate,
                    formBodyClassname : styles.formBodyClassname
                }    
            }
    }
}

const getCorrespondingRequiredProps = (billingCode,value)=>{
    switch(billingCode){
        case BILLING_TYPES["CPT 99426, 99427 - Principal Care Management"]:
            return value == CLINICAL_NOTES_SECTIONS.CARE_PLAN_IMPLEMENTATION ? {isRequired : true, suffixText:"Mandatory"} : {}
            break;
        case BILLING_TYPES["CPT 99457, 99458 - Remote Patient Monitoring"]:
            return value == CLINICAL_NOTES_SECTIONS.MONITORING_AND_REVIEWING ? {isRequired : true, suffixText:"Mandatory"} : {}
            break;
        default:
            return {}
    }
}

const getClinicalNotesSections = (billingCode, handleFormChange)=>{
    return Object.keys(CLINICAL_NOTES_SECTIONS).map((key)=>{
        return {
            label : CLINICAL_NOTES_SECTIONS[key],
            tooltipProps : TOOLTIP_PROPS[key],
            activeStateProps : getCorrespondingActiveStateProps(CLINICAL_NOTES_SECTIONS[key],key, handleFormChange,billingCode),
            ...getCorrespondingRequiredProps(billingCode,CLINICAL_NOTES_SECTIONS[key])
        }
    })
}

export {
    getClinicalNotesSections
}