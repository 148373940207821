import { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants/api";
import { logToCloudWatch } from "../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../constants/awsLogs.constants";


const useCoachManagement = ()=>{
    const [coachDetails, setCoachDetails] = useState();

    useEffect(()=>{
        getCoachDetails();
    },[]);

    async function getCoachDetails(){
        try{
            const url = new URL(window.location.href)
            const pathname = url.pathname.split("/");

            const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getMembers/${pathname?.[3]}/ACTIVE`,{
                method : "GET",
                headers:{
                    'content-type':'application/json',
                    "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
                }
            });

            const coachList = await request.json();

            let coachDetails = coachList?.dashboardUserDataList?.filter(({id})=> id==pathname?.[4]);

            setCoachDetails(coachDetails[0] || {});
        }
        catch(e){
            logToCloudWatch({
                userId: window.localStorage.getItem("id"),
                componentStack: e.stack,
              }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
            console.log('ERROR IS : ',e);
        }
    }

    return {
        coachDetails,
        getCoachDetails
    }
}

export default useCoachManagement;