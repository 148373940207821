import React, { useState } from "react";
import { BASE_URL } from "../../../constants/api";
import { RRX_PRACTICE_ID } from "../rrxCoach.constants";
import { logToCloudWatch } from "../../../configs/awsLogs";
import { LOG_STREAM_TYPE } from "../../../constants/awsLogs.constants";

const useCoachForm = (updateActiveTab,updateCoachList)=>{
    const [formValues,setFormValues] = useState({
        practiceId : RRX_PRACTICE_ID,
        role: "RENEWRX_CARE_PRO_ADMIN"
    });

    const submitFormValues = async()=>{
        try{
            const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/addNewMember`,{
                method : "POST",
                headers:{
                    'content-type':'application/json',
                    "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
                },
                body : JSON.stringify(formValues)
            })

            setFormValues({
                practiceId : RRX_PRACTICE_ID
            });

            updateActiveTab("INVITED");
            updateCoachList();

            return request.status == '201' || request.status == '200' || request.status == '204'    
        }
        catch(e){
            console.log("ERROR IS : ",e);
            updateActiveTab("INVITED");
            logToCloudWatch({
                userId: window.localStorage.getItem("id"),
                componentStack: e.stack,
              }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
        }
    }

    const updateFormValues = (type,value)=>{
        setFormValues((prev)=>{
            return {
                ...prev,
                [type]:value
            }
        });
    }

    return {
        formValues,
        updateFormValues,
        submitFormValues
    }
}

export default useCoachForm;