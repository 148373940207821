import React from "react";

import styles from "./billingCode.module.css"

const getToolTipContent = ({
        max=0,
        utilized=0
    })=>{
    return (
        <div className={styles.tooltipContainer}>
            <span className={styles.primaryCotent}>
                {`${utilized} / ${max} Min`}
            </span>
            <span className={styles.secondaryCotent}>
                Utilized
            </span>
        </div>
    )
}

export {
    getToolTipContent
}