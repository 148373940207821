import React from "react";
import {map} from "lodash";
import moment from "moment";

import cgmImage from "./../../../../../../../../../../../assets/images/cgmIcon.jpg";
import greenTick from "./../../../../../../../../../../../assets/images/tick/greenCircle.svg";
import greenExpired from "./../../../../../../../../../../../assets/images/cancel/greenCross.svg";

import { DATE_DATA_FORMAT, LABEL_MAPPING } from "./body.mapping";
import DateInfoCard from "./components/dateInfoCard";

import styles from "./body.module.css";

const Body = ({deviceDetails={},handleViewHistory}) => {
    const { deviceManufacturer = "", deviceName = "", serialNumber = "No Data" , status="Expired"} = deviceDetails;
    return (
        <div className={styles.container}>
            <div className={styles.deviceDetailsContainer}>
                <div className={styles.cgmPhotoContainer}>
                    <img src={cgmImage} className={styles.cgmIcon} />
                </div>
                <div className={styles.deviceContent}>
                    <div className={styles.tertiaryContentTypo}>
                        {deviceManufacturer}
                    </div>
                    <div className={styles.tertiaryContentMainTypo}>
                        {deviceName}
                    </div>
                    <div className={styles.tertiaryContentTypo}>
                        {`SN : ${serialNumber}`}
                    </div>
                </div>
                <div className={styles.tertiaryContentContainer}>
                    <div className={`${styles.rightUpperContainer} ${styles.textAlignRight}`}>
                        <img src={(status?.toLowerCase()=="connected") ?  greenTick : greenExpired} className={styles.statusIcon} />
                        <span className={styles.tertiaryContentMainTypo}>
                            {status}
                        </span>
                    </div>
                    <div className={`${styles.tertiaryContentTypo}  ${styles.textAlignRight}`}>
                        via Terra
                    </div>
                </div>
            </div>
            <div className={styles.dateDetailsContainer}>
                {map(Object.keys(LABEL_MAPPING),(key)=>(
                    <DateInfoCard 
                        label={LABEL_MAPPING?.[key]} 
                        content={deviceDetails?.[key] ? moment(deviceDetails?.[key]).format(DATE_DATA_FORMAT?.[key]) : "No Data"}
                    />
                ))}
            </div>
            <div className={styles.viewHistoryButttonContainer} onClick={handleViewHistory}>
                <div className={styles.viewHistoryButtton}>
                    View Device History
                </div>
            </div>
        </div>
    )
}

export default Body