import moment from "moment";

import { convert } from "html-to-text";

import { CLINICAL_NOTES_SECTIONS } from "../../../../../../../NotesFormPopup/component/ClinicalNotes/clinicalNotes.constants";


const KEY_FIELDS_MAPPINGS = {
    details : "Details",
    clinicalInterpretation : "Clinical Interpretation",
    actionPlans : "Action Plans",
    referenceData : "Reference Data",
}

const getNotesToPopupContentFormat = (data)=>{
    let arr = [];
    arr.push({
        heading: 'Time Spent',
        content : data?.['timeSpent']
    })
    arr.push({
        heading: 'Clinical Interpretation',
        content : data?.['clinicalInterpretation']
    })
    arr.push({
        heading: 'Plan (Treatment Recommendations)',
        content : data?.['plan']
    })
    arr.push({
        heading: 'Patient Communication',
        content : data?.['patientCommunication']
    })
    arr.push({
        heading: 'Follow-up',
        content : data?.['followUp'] && moment(data?.['followUp']).diff(moment(),"weeks")+" weeks "+moment(data?.['followUp']).format('ddd, MMM DD')
    })

    return arr;
}

const getClinicalNotesFormattedData = (
        clinicalNotes,
        billingCode="-",
        modeOfService="-",
        billingEndTime="-",
        billingStartTime="-",
        billingType,
        carePlanDetails
    )=>{

    const { carePlanSummary } = carePlanDetails;

    let billingContent = "";

    let privateSectionData="";

    privateSectionData+="Billing Detail\n";
    privateSectionData+="Billing Code\n";
    privateSectionData+=`${billingCode} - ${billingType} Service `+"\n\n";

    privateSectionData+="Mode Of Service\n";
    privateSectionData+=modeOfService+"\n\n";

    privateSectionData+="Start Time\n";
    privateSectionData+=billingStartTime+"\n\n";

    privateSectionData+="End Time\n";
    privateSectionData+=billingEndTime+"\n\n";
    privateSectionData+="-----------------------------------------------------------------------------------------------------------------------";
    privateSectionData+="\n\n";

    privateSectionData+="Care Plan Detail\n";
    privateSectionData+=carePlanSummary+"\n\n"

    privateSectionData+="-----------------------------------------------------------------------------------------------------------------------";
    privateSectionData+="\n\n";

    billingContent+=privateSectionData;

    Object.keys(clinicalNotes).forEach((key)=>{
        const { type="",...rest } = clinicalNotes[key]?.billingNotes
        billingContent+=CLINICAL_NOTES_SECTIONS[type]+"\n";
        billingContent+="• Time Spent: "+clinicalNotes[key]?.timeSpent+" Min\n";
        Object.keys(rest).forEach((key)=>{
            billingContent+="• "+(KEY_FIELDS_MAPPINGS?.[key] || key)+"\n";
            billingContent+=convert(rest[key]);
            billingContent+="\n";
        });
        billingContent+="\n";
        billingContent+="-----------------------------------------------------------------------------------------------------------------------\n";
        billingContent+='\n';
    })
    return billingContent
}

const getPrivateSmartData = ({
        expressNotes
    })=>{
    let expressData = "Express Note\n";
    expressData+=expressNotes ? convert(expressNotes) : "-";
    return expressData;
}

const getFormattedSmartNotesData = ({
        reviewDate = "",
        billingDetails={},
        followUpDate="-",
        noteType,
        billingType="",
        expressNotes="",
        billingCode="-",
        modeOfService="-",
        billingEndTime="-",
        billingStartTime="-",
        carePlanDetails={},
        followUpDateByNonRenewRxUser="-",
        followUpDateByRenewRxUser="-",
        ...rest
    })=>{

    let privateSectionData = "";
    privateSectionData+='Date Of Review\n';
    privateSectionData+=`${moment(reviewDate).format("ddd, MMM DD, YYYY")}\n\n`;
    privateSectionData+="-----------------------------------------------------------------------------------------------------------------------";
    privateSectionData+="\n\n";

    privateSectionData+=noteType=="DETAILED" ? getClinicalNotesFormattedData(billingDetails,billingCode,modeOfService,billingEndTime,billingStartTime, billingType, carePlanDetails) : getPrivateSmartData({noteType, expressNotes});
    privateSectionData+='\n';
    privateSectionData+=noteType=="DETAILED" ? "Follow-up Schedule \n"+(followUpDateByRenewRxUser!="-" ? (followUpDateByRenewRxUser || "-") : (followUpDateByNonRenewRxUser || "-"))+"\n" : "";

    return privateSectionData;
}


const getFomattedPrivateNotesSection = ({
        followUpNotes={},
    })=>{
    const { toProvider , nonClinicalNote } = followUpNotes;
    let privateSectionData = "";
    privateSectionData+='To Provider:\n';
    privateSectionData+=toProvider ?  convert(toProvider) : "-";
    privateSectionData+="\n";
    privateSectionData+="-----------------------------------------------------------------------------------------------------------------------\n";
    privateSectionData+= "Non-clinical Note:\n";
    privateSectionData+=nonClinicalNote ?  convert(nonClinicalNote) : "-";
    return privateSectionData;
}

export {
    getNotesToPopupContentFormat,
    getFomattedPrivateNotesSection,
    getFormattedSmartNotesData
}