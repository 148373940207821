import { logToCloudWatch } from "../../configs/awsLogs";
import { BASE_URL } from "../../constants/api";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";

const getMemberDetail = async(memberId, setMemberDetail) =>{
    try{
        const response = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/getMember/${memberId}`,{
            method: "GET",
            headers:{
                'content-type': 'application/json',
                "authorization": "Bearer " + window?.localStorage?.getItem("Authorization"),
            },
        });

        const data = await response.json();
        setMemberDetail(data);
    }
    catch(e){        
        console.log(e);
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }
}


export default getMemberDetail;