import ItemBlock from "./components/ItemBlock/ItemBlock"
import ItemBlockContainer from "./components/ItemBlockContainer/ItemBlockContainer"
import { DETAIL_SECTION_BASE_NAMES } from "./detailSection.constants"

const BASIC_DETAILS = {
    headerLabel : {
        name : "modeType"
    },
    fields : [
        DETAIL_SECTION_BASE_NAMES.DATE,
        DETAIL_SECTION_BASE_NAMES.TIME,
        DETAIL_SECTION_BASE_NAMES.STAFF,
        DETAIL_SECTION_BASE_NAMES.SERVICE_PERFORMED,
        DETAIL_SECTION_BASE_NAMES.PROVIDER_NOTIFIED
    ],
    BodyComponent : ItemBlock
}

const TIME_SPENT = {
    fields : [
        DETAIL_SECTION_BASE_NAMES.DATE,
    ],
    BodyComponent : ItemBlock
}

const DETAIL_SECTION_BASE_FIELDS = [BASIC_DETAILS, TIME_SPENT]

export {
    DETAIL_SECTION_BASE_FIELDS,
    BASIC_DETAILS
}