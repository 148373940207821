import { includes, some, filter } from "lodash";

const getTextInitials = (text="")=>{
    return (text?.split(" ")?.map((val)=>{
        return val.substring(0,1).toUpperCase();
    }))?.join("");
}

const getUpperCaseFormatter = (text="") =>{
    return (text.split(" ").map((val)=>{
        return val.substring(0,1).toUpperCase()+val.substring(1,val.length);
    })).join(" ");
} 

const getFilteredData = ({data, text=""})=>{
    if(text.length==0){
        return data;
    }
    
    return filter(data,(dataItem)=>{
        const includesValue = (val, obj) => some(obj, v => typeof v =="string" && includes(v?.toLowerCase(), val?.toLowerCase()))
        return includesValue(text, dataItem);
    })
}

const spaceRemovalKeyDownEvent = (event)=>{
    if(event.key == " "){
        event.preventDefault();
    }
}

const getUSFormattedPhoneNumber = (value="") =>{
    if(value.length <3){
        return value;
    }
    let formattedValue = "";
    formattedValue+="("+value.substring(0,3)+") ";
    if(value.length<7){
        return formattedValue+value.substring(3,6);
    }
    return formattedValue+value.substring(3,6)+"-"+value.substring(6,10);
}

export {
    getTextInitials,
    getUpperCaseFormatter,
    getFilteredData,
    spaceRemovalKeyDownEvent,
    getUSFormattedPhoneNumber
}