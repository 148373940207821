import { logToCloudWatch } from "../../configs/awsLogs";
import { API_STATUS_CODES, BASE_URL } from "../../constants/api";
import { LOG_STREAM_TYPE } from "../../constants/awsLogs.constants";

const getInitialData = async({state,setState,payload}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })
    const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
        method:"POST",
        headers:{
            "content-type":"application/json",
            'authorization' : "Bearer " + window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
        },
        body: JSON.stringify({
            userId: "47ba148a-ed0e-436e-8a45-f357a54667fc",
            metricRequests: [
                    {
                        metricType: "AVG_FASTING_GLUCOSE",
                        numberOfDays : 7,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType:"FASTING_TIME_IN_RANGE",
                        numberOfDays: 7,
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        numberOfDays: 7,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        numberOfDays: 7,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        numberOfDays: 7,
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        numberOfDays: 7,
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        numberOfDays: 7,
                        mealType: "DINNER"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        numberOfDays: 7,
                        mealType: "DINNER"
                    }
                ]
        })
    })

    // if(response.status == API_STATUS_CODES.ANAUTHORIZED || response.status == API_STATUS_CODES.FORBIDDEN)
    // {
    //     navigate("/login")
    // }

    const data = await response.json();

    setState({ recentActivity : data?.metricsResponses, isLoading : false});

    }
    catch(e)
    {
        setState({...state,isError:true, error:e,isLoading : false});
        logToCloudWatch({
            userId: window.localStorage.getItem("id"),
            api: '/dashboard/glucoseMetrics',
            componentStack: e.stack,
          }, LOG_STREAM_TYPE.API_LOG_STREAM, BASE_URL);
    }

}

export default {
    INIT_STATE : getInitialData,
}