import React from "react";
import {map} from "lodash";
import cx from "classnames";

import BaseTableBlock from "./components/BaseTableBlock";

import getBaseTableRowHoc from "./hoc/BaseTableRowHoc";
import { getBaseTableRowProps, getBaseTableWrapperFields, getTableFields } from "./baseTable.helper";

import styles from "./baseTable.module.css";

const BaseTable = (props) =>{

    const { columnProps= {}, className="", wrapperFields = [], columnWrapperFields =[], columnsData, tableData, tableDataProps = {}, withCheckBox = false, dispatch} = props;

    const TableRowComponent = getBaseTableRowHoc(BaseTableBlock, withCheckBox);

    return (
        <div className={cx(styles.container,className)}>
            <TableRowComponent rowData={getBaseTableWrapperFields(columnWrapperFields,getTableFields(columnsData),columnsData, true)} columnsData={columnsData} rowProps={columnProps} dispatch={dispatch} />
            {
                map(tableData,(tableRowData)=>{
                    const dataField = getBaseTableWrapperFields(wrapperFields,tableRowData,columnsData);
                    return <TableRowComponent rowProps={tableDataProps} unfilteredData={tableRowData} columnsData={columnsData} rowData={dataField} dispatch={dispatch}/>
                })
            }
        </div>
    )
}

export default BaseTable;