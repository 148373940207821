import React, { Component } from "react";
import { logToCloudWatch } from "../configs/awsLogs"; // Import the AWS logger
import { LOG_STREAM_TYPE } from "../constants/awsLogs.constants";
import Fallback from "../organisms/Fallback/Fallback";
import { BASE_URL } from "../constants/api";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log("error abd is", error);
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    this.setState({hasError: true});

    await logToCloudWatch({
      userId: window.localStorage.getItem("id"),
      error: error.toString(),
      componentStack: errorInfo.componentStack,
    }, LOG_STREAM_TYPE.EXCEPTION_LOG_STREAM, BASE_URL);
  }

  render() {
    if (this.state.hasError) {
      return <Fallback></Fallback>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
