import React, { useMemo } from "react";
import {map} from "lodash";
import moment from "moment";

import emptyNotification from "./../../../../../../../../../assets/images/empty_notification.svg";
import filledCalender from "./../../../../../../../../../assets/images/calender/filledCalender.svg";

import NotesCard from "./component/NotesCard";
import { getRespectiveFollowUpDate } from "./body.helpers";
import NotesCardOld from "./component/NotesCardOld"

import styles from "./body.module.css"
import { NOTES_HEADER_NOTIFICATION_TYPES } from "../../Notes.constants";

const Body = ({
        notes=[],
        notesData,
        handleToggleLoaderAction,
        handleGetNotesListAction
    }) =>{

    if((notes.length == 0 && notesData.length==0)){
        return (
            <div className={styles.container}>
                <img src={emptyNotification} className={styles.emptyIcon}/>
                <div className={styles.noContent}>
                    No Notes Added Yet!
                </div>
            </div>
        )
    }

    const followUpDate = useMemo(()=> getRespectiveFollowUpDate(notes, notesData),[notes, notesData]);

    return (
        <div className={`${styles.container}`}>
            <div className={styles.followUpContainer}>
                {map(NOTES_HEADER_NOTIFICATION_TYPES,({
                    name="",
                    content
                })=>{
                    return (
                        <div className={styles.followUpItem}>
                            <img src={filledCalender} className={styles.calenderIcon} />
                            <span>{content}</span>
                            <span className={styles.mainData}>{followUpDate?.[name] || "-"}</span>
                        </div>
                    )
                })}
            </div>
            <div className={styles.cardContainer}>
                {map(notesData,(note)=>{
                    return <NotesCard handleGetNotesListAction={handleGetNotesListAction} handleToggleLoaderAction={handleToggleLoaderAction} {...note}/>
                })}
                {map(notes, (note)=>{
                    return <NotesCardOld {...note} />
                })}
            </div>
        </div>
    )
}

export default Body;