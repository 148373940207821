import { getSmartNotesApprovalLabel } from "./body.components"
import { BODY_SECTION_TYPES } from "./body.fields"

const getCorrespondingLabel = (label,noteApprovalStatus) => {
    switch(label){
        case BODY_SECTION_TYPES.DETAILED[0].label : 
            return getSmartNotesApprovalLabel(label,noteApprovalStatus);
        default:
            return label;
    }
}

export {
    getCorrespondingLabel
}